import React, { useState } from "react";
import { unsubscribeNewsletter } from "../../api/UnsubscribeNewsletter";
import classes from "./NewsletterPage.module.css";

function NewsletterPage() {
  const [email, setEmail] = useState("");
  const [unsubscribeError, setUnsubscribeError] = useState("");
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    setUnsubscribeError("");
    setUnsubscribeSuccess("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const info = {
        email: email,
        active: false,
      };
      const data = await unsubscribeNewsletter(info);
      setUnsubscribeSuccess("Unsubscribed successfully.");
      setEmail("");
    } catch (error) {
      setUnsubscribeError(
        "Unsubscription failed. Please check your email and try again."
      );
      console.error("Error during unsubscription:", error);
    }
  };

  return (
    <div className={classes.unsubscribe_page}>
      <form className={classes.unsubscribe_card} onSubmit={handleSubmit}>
        <h2 className="text-orange text-center text-[1.5rem] mb-4">
          Unsubscribe from our Marketing Newsletter
        </h2>
        <div className={classes.form_row}>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="Email Address"
          />
        </div>
        {unsubscribeError && (
          <div className="text-red text-center mb-4">{unsubscribeError}</div>
        )}
        {unsubscribeSuccess && <div className="mb-4">{unsubscribeSuccess}</div>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default NewsletterPage;
