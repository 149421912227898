import React, { useState, useEffect } from "react";
import { signUp } from '../../api/Signup';
import { useUser } from "../../api/UserContext";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import GeneralButton from "../shared/Buttons/GeneralButton";

function SignupPopup({ onClose }) {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    user_type: "regular_user",
    affiliate_key: ""
  });
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const { updateUser } = useUser();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const affiliateId = params.get("affiliate_id");

    if (affiliateId) {
      setFormData(prevState => ({ ...prevState, affiliate_key: affiliateId }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts correcting the input
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setPasswordError("");

    try {
      const signUpData = {
        ...formData,
        username: formData.email, // Set username as email
      };
      delete signUpData.confirmPassword; // Remove confirmPassword before the API call

      const data = await signUp(signUpData);
      if (data && !data.password) {
        updateUser(data); // Update global state with the received data
        onClose(); // Close the popup
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
      console.error("Error during signup:", error);
    }
  };

  return (
    <div className="fixed inset-0 h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 h-2/3 overflow-y-auto rounded-lg shadow-lg w-96">
        <button
          className="relative -top-4 -right-40 text-black"
          onClick={onClose}
        >
          X
        </button>
        <h2 className="text-3xl font-bold text-orange mb-4">Create Your Account</h2>

        <form onSubmit={handleSubmit}>
          <label>
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
            className="w-full p-2 mb-4 border rounded"
          />

          <label>
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            className="w-full p-2 mb-4 border rounded"
          />

          <label>
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full p-2 mb-4 border rounded"
          />

          <label>Phone Number</label>
          <input
            type="number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number"
            className="w-full p-2 mb-4 border rounded"
          />

          <label>
            Password <span className="text-red-500">*</span>
            <br />
            <span className="text-xs mb-2">
              (at least 8 characters long with letters and numbers)
            </span>
          </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            className="w-full p-2 mb-4 border rounded"
          />

          <label>
            Confirm Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
            className="w-full p-2 mb-4 border rounded"
          />

          {passwordError && (
            <div className="text-red-500 mb-4">{passwordError}</div>
          )}
          {generalError && (
            <div className="text-red-500 mb-4">{generalError}</div>
          )}

          <GeneralButton colour="blue" type="submit">
            Sign Up
          </GeneralButton>
        </form>
      </div>
    </div>
  );
}

export default SignupPopup;
