import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getShopProduct } from "../../../api/ShopProduct";
import { addToCart } from "../../../api/Cart";
import { Link, useNavigate } from "react-router-dom";
import "./ShopProduct.css";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import classes from "./ShopProduct.module.css";
import { useSelector } from "react-redux";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoginSignupModal from "../../shared/LoginSignupModal/LoginSignupModal";
import Spinner from "../../shared/LoadingSpinner/Spinner";

export default function ShopProduct() {
  const { product_id } = useParams();
  const [product, setProduct] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [currentColor, setCurrentColor] = useState("");
  const [variantId, setVariantId] = useState(null);
  const [variantPrice, setVariantPrice] = useState(null);
  const [currentSize, setCurrentSize] = useState("");
  const [currentQuantity, setCurrentQuantity] = useState(1);
  const [colorName, setColorName] = useState("");
  const [isSizeChartVisible, setSizeChartVisible] = useState(false);
  const [localUserData, setLocalUserData] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  const [isModalOpen, setModalOpen] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");

  function SizeChartModal({ isVisible, onClose }) {
    if (!isVisible) return null;

    return (
      <Modal
        open={isVisible}
        onClose={onClose}
        aria-labelledby="size-guide-modal"
        aria-describedby="size-guide-table"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: 400,
              sm: 400,
              md: 650,
            },
            fontSize: {
              xs: "0.400rem",
              sm: "0.400rem",
              md: "initial",
            },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <table
            id="size-guide"
            className="size-guide-table"
            style={{ borderSpacing: "10px 5px" }}
          >
            <thead>
              <tr>
                <th style={{ padding: "15px 10px" }}>&nbsp;</th>{" "}
                {/* Adjusted padding */}
                <th className="table-header" style={{ padding: "10px" }}>
                  S
                </th>
                <th className="table-header" style={{ padding: "10px" }}>
                  M
                </th>
                <th className="table-header" style={{ padding: "10px" }}>
                  L
                </th>
                <th className="table-header" style={{ padding: "10px" }}>
                  XL
                </th>
                <th className="table-header" style={{ padding: "10px" }}>
                  2XL
                </th>
                <th className="table-header" style={{ padding: "10px" }}>
                  3XL
                </th>
                <th className="table-header" style={{ padding: "10px" }}>
                  4XL
                </th>
                <th className="table-header" style={{ padding: "10px" }}>
                  5XL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="first-column" style={{ padding: "15px 10px" }}>
                  Width, in
                </td>{" "}
                {/* Adjusted padding */}
                <td className="table-cell" style={{ padding: "10px" }}>
                  20.08
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  22.05
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  24.02
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  25.98
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  27.99
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  29.92
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  31.89
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  33.86
                </td>
              </tr>
              <tr>
                <td className="first-column" style={{ padding: "15px 10px" }}>
                  Length, in
                </td>{" "}
                {/* Adjusted padding */}
                <td className="table-cell" style={{ padding: "10px" }}>
                  27.17
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  27.95
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  29.13
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  29.92
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  31.10
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  31.89
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  33.07
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  33.86
                </td>
              </tr>
              <tr>
                <td className="first-column" style={{ padding: "15px 10px" }}>
                  Sleeve length from center back, in
                </td>{" "}
                {/* Adjusted padding */}
                <td className="table-cell" style={{ padding: "10px" }}>
                  33.50
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  34.50
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  35.50
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  36.50
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  37.50
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  38.50
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  39.50
                </td>
                <td className="table-cell" style={{ padding: "10px" }}>
                  40.50
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Modal>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Load User data
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Load product info
  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const response = await getShopProduct(product_id);
        setProduct(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProductInfo();
  }, [product_id]);

  // Set the first image & color for product
  useEffect(() => {
    // Check if product is loaded and has the colors array
    if (product && product.colors && product.colors.length > 0) {
      // Find the first color with an available image_url
      const firstAvailableColor = product.colors.find(
        (color) => color.image_url
      );

      if (firstAvailableColor) {
        setCurrentImage(firstAvailableColor.image_url);
        setCurrentColor(firstAvailableColor.id);
        setColorName(firstAvailableColor.title);
        if (firstAvailableColor.sizes && firstAvailableColor.sizes.length > 0) {
          setCurrentSize(firstAvailableColor.sizes[0].title);
        }
      }
    } else if (product && product.images && product.images.length > 0) {
      // If there are no colors or colors array is empty, use the first product image if available
      setCurrentImage(product.images[0].src);
      setCurrentColor("");
      setColorName("");
      setCurrentSize(product.sizes[0].title);
    }
  }, [product]);

  // Find current variant
  useEffect(() => {
    findVariantByColorAndSize();
  }, [currentColor, currentSize, colorName]);

  const handleColorChange = (e) => {
    const selectedColorId = Number(e.target.value); // Convert to number to ensure matching types
    setCurrentColor(selectedColorId);
    const selectedColor = product.colors.find(
      (color) => color.id === selectedColorId
    );

    if (selectedColor) {
      setColorName(selectedColor.title);
      setCurrentImage(selectedColor.image_url || "");
      // Check if the selected color has sizes and set the first available size
      if (selectedColor.sizes && selectedColor.sizes.length > 0) {
        setCurrentSize(selectedColor.sizes[0].title); // Automatically select the first size
      } else {
        setCurrentSize(""); // Reset the size if no sizes are available for this color
      }
    } else {
      console.error("Selected color not found"); // Log an error if no matching color is found
    }
  };

  const findVariantByColorAndSize = () => {
    // Check if product and colorName are available
    if (product && colorName && currentSize) {
      for (let i = 0; i < product.variants.length; i++) {
        const variant = product.variants[i];
        // Check if the variant's title contains colorName and size title
        if (
          variant.title.includes(colorName) &&
          variant.title.includes(currentSize)
        ) {
          // Return the price of the matching variant
          setVariantId(variant.variant_id);
          setVariantPrice(variant.price);
          return variant.price;
        }
      }
    } else if (product && currentSize) {
      for (let i = 0; i < product.variants.length; i++) {
        const variant = product.variants[i];
        // Check if the variant's title contains colorName and size title
        if (variant.title.includes(currentSize)) {
          setVariantId(variant.variant_id);
          setVariantPrice(variant.price);
          return variant.price;
        }
      }
    } else {
      // If no matching variant is found, return null or handle as needed
      setVariantId(null);
      setVariantPrice(null);
      return null;
    }
  };

  // Add to Cart
  const handleAddToCart = async () => {
    if (variantId && currentQuantity > 0 && currentImage) {
      const cartData = {
        variant_id: variantId,
        product_id: product_id,
        quantity: currentQuantity,
        image_url: currentImage,
        variant_price: variantPrice,
      };
      try {
        const response = await addToCart(cartData);
        navigate("/cart");
      } catch (error) {
        console.error("Error adding item to cart:", error);
      }
    } else {
      console.error("Invalid product details or quantity");
    }
  };

  const handleViewCart = () => {
    navigate("/cart");
  };

  const openModal = (type) => {
    setLoginOrSignup(type);
    setModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  return (
    <>
      {product ? (
        <div className={`${classes.shop_product} main-div`}>
          <div>
            <Link to={`/shop`}>
              <h2 className="text-blue text-left text-xl font-bold m-0">
                <FontAwesomeIcon icon={faArrowLeft} /> Back to Shop
              </h2>
            </Link>
          </div>
          <div className="flex gap-10 h-auto max-[768px]:flex-col">
            <div className="flex-1 overflow-hidden h-[30rem] max-[768px]:flex-none max-[768px]:h-fit">
              <img
                src={currentImage}
                alt={product.title}
                className="object-contain w-full h-full h-fit"
              />
            </div>
            <div className="flex-1 max-[768px]:flex-col max-[768px]:pt-4">
              <h1 className="mb-4 text-[var(--default-orange)]">
                {product.title}
              </h1>
              <hr />
              <div>
                {/* Check if sizes field does not exist and then render color and size dropdowns for clothing/non-clothing */}
                {!product.sizes ? (
                  <>
                    <h2 className="text-2xl font-bold text-blue my-4">
                      Price: ${(variantPrice / 100).toFixed(2)}
                    </h2>
                    <p className="text-left font-bold">Color:</p>
                    <div className={classes.item_selections}>
                      {/* Color Swatch Display */}
                      {currentColor && (
                        <div
                          style={{
                            marginRight: "10px",
                            width: "30px",
                            height: "30px",
                            backgroundColor: product.colors.find(
                              (color) => color.id === currentColor
                            )?.hexcode,
                            border: "1px solid #ddd",
                          }}
                        ></div>
                      )}
                      {/* Color Selector */}
                      <select
                        value={currentColor}
                        onChange={handleColorChange}
                        className="mt-2 md:mt-0"
                      >
                        {product.colors.map((color) => (
                          <option key={color.id} value={color.id}>
                            {color.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* Size Selector based on selected color */}
                    {currentColor &&
                      product.colors.find((color) => color.id === currentColor)
                        ?.sizes && (
                        <div className={classes.item_selections}>
                          <p className="mr-4 font-bold">Size:</p>
                          <select
                            value={currentSize}
                            onChange={(e) => {
                              setCurrentSize(e.target.value);
                              findVariantByColorAndSize();
                            }}
                          >
                            <option value="">Select Size</option>
                            {product.colors
                              .find((color) => color.id === currentColor)
                              .sizes.map((size) => (
                                <option key={size.id} value={size.title}>
                                  {size.title}
                                </option>
                              ))}
                          </select>
                        </div>
                      )}
                    <button
                      onClick={() => setSizeChartVisible(true)}
                      className="bg-transparent text-black border-none rounded mb-4 h-4 cursor-pointer underline max-[768px]:w-full max-[768px]:m-[1rem_0]"
                    >
                      Size Chart
                    </button>
                    <SizeChartModal
                      isVisible={isSizeChartVisible}
                      onClose={() => setSizeChartVisible(false)}
                    />
                    {/* Quantity Selector */}

                    <div className={classes.item_selections}>
                      <p className="mr-4 font-bold">Quantity:</p>
                      <select
                        value={currentQuantity}
                        onChange={(e) => setCurrentQuantity(e.target.value)}
                      >
                        {[...Array(10).keys()].map((num) => (
                          <option key={num + 1} value={num + 1}>
                            {num + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    {!localUserData ? (
                      <div className="mb-4">
                        <h4 className={classes.links}>
                          {isModalOpen ? (
                            loginOrSignup === "login" ? (
                              <LoginSignupModal
                                loginOrSignup={"login"}
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                onFormSuccess={handleFormSuccess}
                              />
                            ) : (
                              <LoginSignupModal
                                loginOrSignup={"signup"}
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                onFormSuccess={handleFormSuccess}
                              />
                            )
                          ) : (
                            ""
                          )}
                          <button
                            className="font-bold"
                            onClick={() => openModal("signup")}
                          >
                            Sign Up
                          </button>{" "}
                          or{" "}
                          <button
                            className="font-bold"
                            onClick={() => openModal("login")}
                          >
                            login
                          </button>{" "}
                          to start purchasing
                        </h4>
                      </div>
                    ) : (
                      <div className="mb-4">
                        <GeneralButton
                          className="inline-block mt-8 mr-4 py-2.5 px-4 rounded-full text-sm !font-bold border-none shadow-[0_2px_2px_rgba(0,0,0,0.2)] transition-all duration-300 cursor-pointer max-[768px]:w-full max-[768px]:m-[1rem_0]"
                          onClick={handleAddToCart}
                          colour="blue"
                        >
                          Add To Cart
                        </GeneralButton>
                        <GeneralButton
                          className="inline-block mt-8 mr-4 py-2.5 px-4 rounded-full text-sm !font-bold border-none shadow-[0_2px_2px_rgba(0,0,0,0.2)] transition-all duration-300 cursor-pointer max-[768px]:w-full max-[768px]:m-[1rem_0]"
                          onClick={handleViewCart}
                          colour="orange"
                        >
                          View Cart
                        </GeneralButton>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <h2 className="text-2xl font-bold text-blue my-4">
                      Price: ${(variantPrice / 100).toFixed(2)}
                    </h2>
                    <div className={classes.item_selections}>
                      <p className="mr-4 font-bold">Size:</p>
                      <select
                        value={currentSize}
                        onChange={(e) => {
                          setCurrentSize(e.target.value);
                          findVariantByColorAndSize();
                        }}
                      >
                        <option value="">Select Size</option>
                        {product.sizes.map((size) => (
                          <option key={size.id} value={size.title}>
                            {size.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* Quantity Selector */}
                    <div className={classes.item_selections}>
                      <p className="mr-4 font-bold">Quantity:</p>
                      <select
                        value={currentQuantity}
                        onChange={(e) => setCurrentQuantity(e.target.value)}
                      >
                        {[...Array(10).keys()].map((num) => (
                          <option key={num + 1} value={num + 1}>
                            {num + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    {!localUserData ? (
                      <div className="mb-4">
                        <h4 className={classes.links}>
                          {isModalOpen ? (
                            loginOrSignup === "login" ? (
                              <LoginSignupModal
                                loginOrSignup={"login"}
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                onFormSuccess={handleFormSuccess}
                              />
                            ) : (
                              <LoginSignupModal
                                loginOrSignup={"signup"}
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                onFormSuccess={handleFormSuccess}
                              />
                            )
                          ) : (
                            ""
                          )}
                          <button
                            className="font-bold"
                            onClick={() => openModal("signup")}
                          >
                            Sign Up
                          </button>{" "}
                          or{" "}
                          <button
                            className="font-bold"
                            onClick={() => openModal("login")}
                          >
                            login
                          </button>{" "}
                          to start purchasing
                        </h4>
                      </div>
                    ) : (
                      <>
                        <GeneralButton
                          className="inline-block mt-8 mr-4 py-2.5 px-4 rounded-full text-sm !font-bold border-none shadow-[0_2px_2px_rgba(0,0,0,0.2)] transition-all duration-300 cursor-pointer max-[768px]:w-full max-[768px]:m-[1rem_0]"
                          onClick={handleAddToCart}
                          colour="blue"
                        >
                          Add To Cart
                        </GeneralButton>
                        <GeneralButton
                          className="inline-block mt-8 mr-4 py-2.5 px-4 rounded-full text-sm !font-bold border-none shadow-[0_2px_2px_rgba(0,0,0,0.2)] transition-all duration-300 cursor-pointer max-[768px]:w-full max-[768px]:m-[1rem_0]"
                          onClick={handleViewCart}
                          colour="orange"
                        >
                          View Cart
                        </GeneralButton>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex -ml-14 md:ml-0 gap-8 w-full md:w-auto">
            <div className="w-1/4 md:w-1/2"></div>
            <div className="w-3/4 md:w-1/2">
              <h2 className="text-2xl text-orange font-bold text-center mb-4">
                About the Product
              </h2>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{ __html: product.description }}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row ml-auto mt-[35vh] w-full h-full justify-center">
          <Spinner />
        </div>
      )}
    </>
  );
}
