import GeneralButton from "../shared/Buttons/GeneralButton";
import classes from "./CustomAmountModal.module.css";
import React, { useState } from "react";

  const CustomAmountModal = ({ onClose, onSubmit }) => {
    const [ amount, setAmount ] = useState("");

    return (
    <div className={classes.modal_backdrop}>
      <div className={classes.modal_content}>
        <div className={classes.modal_header}>
          <span className={classes.close_btn} onClick={onClose}>
            X
          </span>
        </div>
        <h2 className="font-semibold">Enter Custom Amount</h2>
        <input
          type="number"
          placeholder="Amount"
          id="customAmount"
          value={amount}
          className={`${classes.modal_input} border border-gray rounded-md`}
          onChange={(e)=>{
            setAmount(e.target.value);
          }}
        />
        <GeneralButton
          className=""
          colour="blue"
          onClick={() => {
            onSubmit(amount);
          }}
        >
          Submit
        </GeneralButton>
      </div>
    </div>
)};

export default CustomAmountModal;
