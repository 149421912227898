import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPaw, faStar } from "@fortawesome/free-solid-svg-icons";
import {
  petstarSubscribe,
  petstarCustomSubscribe,
} from "../../api/PetstarSubscribe";
import CustomAmountModal from "./CustomAmountModal";
import SignupPopup from "./SignupPopup";
import img2 from "../../assets/about-us-1.png";
import img3 from "../../assets/about-us-2.png";
import img4 from "../../assets/petstar.jpg";
import howItWorks1 from "../../assets/petstarIcons/moneyHand.png";
import howItWorks2 from "../../assets/petstarIcons/mailPaw.png";
import howItWorks3 from "../../assets/petstarIcons/starHand.png";
import classes from "./PetStar.module.css";
import LoginSignupModal from "../shared/LoginSignupModal/LoginSignupModal";

const BULLET = <FontAwesomeIcon icon={faPaw} />;

export default function PetStar() {
  const [localUserData, setLocalUserData] = useState(null);
  const [isCustomAmountModalVisible, setIsCustomAmountModalVisible] = useState(false);
  const [showAffiliateSignup, setShowAffiliateSignup] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const location = useLocation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const params = new URLSearchParams(location.search);
    if (params.has('affiliate_id')) {
      setShowAffiliateSignup(true);
    }
  }, [location]);

  const handleSubClick = async (priceId) => {
    try {
      if (priceId) {
        if (localUserData) {
          const response = await petstarSubscribe({ price_id: priceId });
          if (response.redirect_url) {
            window.location.href = response.redirect_url;
          }
        } else {
          alert("Please sign up or log in to make a donation.");
        }
      }
    } catch (error) {
      console.error("There was a problem with the subscribe operation:", error);
    }
  };

  const handleCustomAmt = () => {
    if (localUserData) {
      setIsCustomAmountModalVisible(true);
    } else {
      alert("Please sign up or log in to make a custom donation.");
    }
  };

  const handleSubmitCustomAmount = async (amountStr) => {
    try {
      const amount = parseFloat(amountStr, 10);
      if (!isNaN(amount) && amount > 0) {
        const response = await petstarCustomSubscribe({ amount: amount });
        if (response.redirect_url) {
          window.location.href = response.redirect_url;
        }
      } else {
        alert("Please enter a valid, positive amount.");
      }
    } catch (error) {
      console.error(
        "There was a problem with the custom subscribe operation:",
        error
      );
    }
    setIsCustomAmountModalVisible(false);
  };

  const openModal = (type) => {
    setLoginOrSignup(type);
    setModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
      setModalOpen(false);
      document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  }

  return (
    <div className={`${classes.petstar} main-div -z-1`}>
      <div className={classes.petstar_header}>
        <div>
          <h1 className="!mb-[0.2rem] mt-2">
            <FontAwesomeIcon icon={faStar} /> Welcome to
            <FontAwesomeIcon icon={faStar} />
          </h1>
          <h1 className="!mt-[0.2rem]">The PetStar Club</h1>
          <p className="my-4 mx-10 text-2xl font-semibold text-white md:text-black">
            Subscribe to help all of our nonprofit partners while earning
            awesome rewards!
          </p>
          {!localUserData && (
            <div className="mb-4 text-white md:text-black bg-orange-400/60 rounded-xl w-3/4 mx-auto md:bg-transparent md:rounded-none md:w-full md:mx-0">
              <h4>
              {isModalOpen ? (
                  loginOrSignup === "login" ? (
                    <LoginSignupModal loginOrSignup={"login"} isOpen={isModalOpen} onClose={closeModal} onFormSuccess={handleFormSuccess}/>
                  ) : (
                    <LoginSignupModal loginOrSignup={"signup"} isOpen={isModalOpen} onClose={closeModal} onFormSuccess={handleFormSuccess}/>
                  )
              ) : ""}
                <button className="underline text-white md:text-orange" onClick={() => openModal('signup')}>Sign Up</button> or{" "}
                <button className="underline text-white md:text-orange" onClick={() => openModal('login')}>login</button> to donate
              </h4>
            </div>
          )}
          <div className={`${classes.petstar_donation_amounts_desktop}`}>
            {["10", "25", "35", "50", "100"].map((amount, index) => (
              <button
                key={index}
                className={`${classes.subscription_button} ${
                  amount === "35" ? "text-orange" : ""
                }`}
                onClick={() => handleSubClick(`priceId_for_${amount}`)}
              >
                ${amount}/month
              </button>
            ))}
            <button
              className={classes.subscription_button}
              onClick={() => handleCustomAmt()}
            >
              Custom
            </button>
          </div>
          {isCustomAmountModalVisible && (
            <CustomAmountModal
              onClose={() => setIsCustomAmountModalVisible(false)}
              onSubmit={handleSubmitCustomAmount}
            />
          )}
        </div>
        <img src={img4} alt="" />
      </div>
      <div className={`${classes.petstar_donation_amounts_mobile}`}>
        {["10", "25", "35", "50", "100"].map((amount, index) => (
          <button
            key={index}
            className={`${classes.subscription_button} ${
              amount === "35" ? "text-orange" : ""
            }`}
            onClick={() => handleSubClick(`priceId_for_${amount}`)}
          >
            ${amount}/month
          </button>
        ))}
        <button
          className={classes.subscription_button}
          onClick={() => handleCustomAmt()}
        >
          Custom
        </button>
      </div>
      {isCustomAmountModalVisible && (
        <CustomAmountModal
          onClose={() => setIsCustomAmountModalVisible(false)}
          onSubmit={handleSubmitCustomAmount}
        />
      )}

      <h1 className="-mt-8 md:mt-0">
        Be the star in the lives of all the animals!
      </h1>
      <h2 className="mt-4 md:mt-0 mb-8 md:mb-4 font-semibold">How it works</h2>
      <div className={classes.petstar_column_points}>
        <div>
          <img className="h-32 mb-2 mx-auto" src={howItWorks1} alt="" />
          <h2 className="font-bold text-center">Place Donation</h2>
          <p>
            Select donation amount and we’ll direct it to where it's needed
            most.
          </p>
        </div>
        <FontAwesomeIcon icon={faArrowRight} className={classes.icons} />
        <div>
          <img className="h-32 mb-2 mx-auto" src={howItWorks2} alt="" />
          <h2 className="font-bold text-center">Personalized Updates</h2>
          <p>See the impact you made and hear from our volunteers</p>
        </div>
        <FontAwesomeIcon icon={faArrowRight} className={classes.icons} />
        <div>
          <img className="h-32 mb-2 mx-auto" src={howItWorks3} alt="" />
          <h2 className="font-bold text-center">Rewards Program</h2>
          <p>
            A special thank you from us with unique discounts and free
            merchandise!
          </p>
        </div>
      </div>

      <div
        className={`${classes.petstar_updates} ${classes.petstar_updates_reverse}`}
      >
        <div>
          <h4 className="text-xl font-bold -mt-8 md:mt-0">
            What You Earn As A PetStar
          </h4>
          <h2 className="text-xl my-4 font-semibold sm:md-8">
            Monthly Updates
          </h2>
          <ul>
            <li>
              {BULLET}
              <p>Statistic of how many animals/shelters you've impacted</p>
            </li>
            <li>
              {BULLET}
              <p>Monthly email updates of Sponsor a Pet's Impact</p>
            </li>
            <li>
              {BULLET}
              <p>Notification of your animal's birthday</p>
            </li>
            <li>
              {BULLET}
              <p>Recognition of outstanding donors</p>
            </li>
            <li>
              {BULLET}
              <p>
                Volunteer spotlight - Learn how others are making an impact in
                their community
              </p>
            </li>
          </ul>
        </div>
        <img src={img2} alt="" />
      </div>

      <div className={classes.petstar_updates}>
        <img src={img3} alt="" />
        <div>
          <h4 className="text-xl font-bold -mt-8 md:mt-0">Rewards</h4>
          <h2 className="text-xl my-4 font-semibold">
            Earn Rewards For Your Donation
          </h2>
          <ul>
            <li>
              {BULLET}
              <p>Free Merchandise (based on giving level)</p>
            </li>
            <li>
              {BULLET}
              <p>Discounts from our Corporate Sponsorships</p>
            </li>
            <li>
              {BULLET}
              <p>
                Contests for whoever refers the most amount of new PetStar
                members
              </p>
            </li>
          </ul>
        </div>
      </div>

      {showAffiliateSignup && (
        <SignupPopup onClose={() => setShowAffiliateSignup(false)} />
      )}
    </div>
  );
}
