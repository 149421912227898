import React, { useState, useEffect } from "react";
import { Button, useMediaQuery } from "@mui/material";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import Slider from "react-slick";
import { signUp } from "../../api/Signup";
import { useUser } from "../../api/UserContext";
import { createStripeSubscription } from "../../api/Affiliate";
import classes from "./AffiliateSignup.module.css";
import { useNavigate } from "react-router-dom";
import { freeAffiliateSignup } from "../../api/Affiliate";

const title = [
  "Create an Affiliate Account",
  "Choose your Commission Plan",
  "Confirm Your Plan",
  "Add Payment Method",
  "You’re All Set!",
];

const tiers = [
  {
    price: "$0",
    description:
      "Earn 5% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_5%",
    commission: "5",
  },
  {
    price: "$10",
    description:
      "Earn 8% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_8%",
    commission: "8",
  },
  {
    price: "$25",
    description:
      "Earn 12% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_12%",
    commission: "12",
  },
  {
    price: "$50",
    description:
      "Earn 15% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_15%",
    commission: "15",
  },
  {
    price: "$100",
    description:
      "Earn 20% monthly from donations to PetStar made by those you refer.",
    priceId: "priceId_for_20%",
    commission: "20",
  },
];

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;

  const combinedClasses = `${className} ml-12 ${classes.buttons}`;
  return (
    <div
      className={combinedClasses}
      style={{
        ...style,
        backgroundColor: "#FE994F",
        borderRadius: "50%",
        left: "0%",
        width: "49px",
        height: "47px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
      id="custom-prev-arrow"
    >
      <svg
        width="12"
        height="16"
        viewBox="0 0 32 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.70702 30.1994L24.0999 52.4891C25.8594 54.2404 28.7034 54.2404 30.4628 52.4891C32.2338 50.7263 32.2338 47.8595 30.4628 46.0967L15.5678 31.2703C13.2116 28.9249 13.2116 25.1108 15.5678 22.7655L30.4628 7.93908C32.2338 6.17626 32.2338 3.30951 30.4628 1.54669C28.7034 -0.204652 25.8594 -0.204651 24.0999 1.54669L1.70702 23.8364C0.859493 24.6803 0.383381 25.8246 0.383381 27.0179C0.383381 28.2111 0.859493 29.3555 1.70702 30.1994Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;

  const combinedClasses = `${className} mr-12 ${classes.buttons}`;
  return (
    <div
      className={combinedClasses}
      style={{
        ...style,
        backgroundColor: "#FE994F",
        borderRadius: "50%",
        right: "0%",
        width: "49px",
        height: "47px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      id="custom-next-arrow"
      onClick={onClick}
    >
      <svg
        width="12px"
        height="16px"
        viewBox="0 0 33 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.0078 30.1994L8.61494 52.4891C6.85549 54.2404 4.01146 54.2404 2.25201 52.4891C0.481029 50.7263 0.48103 47.8595 2.25201 46.0967L17.147 31.2703C19.5032 28.9249 19.5033 25.1108 17.147 22.7655L2.25201 7.93908C0.481033 6.17626 0.481029 3.30951 2.25201 1.54669C4.01146 -0.204652 6.85549 -0.204651 8.61494 1.54669L31.0078 23.8364C31.8554 24.6803 32.3315 25.8246 32.3315 27.0179C32.3315 28.2111 31.8554 29.3555 31.0078 30.1994Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

let slideshowSettings = {
  className: "",
  dots: true,
  infinite: true,
  adaptiveHeight: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  centerMode: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 853,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
};

/* This component only signs up users as 'affiliate' and is NOT for referrals */
function SignUpForm(openSignupForm) {
  const [page, setPage] = useState(1); // Remember to change the page back to 1 after testing
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    user_type: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [indexToGo, setIndexToGo] = useState(0);
  const { updateUser, user } = useUser();
  const [tierSelection, setTierSelection] = useState(tiers[0]);
  const [tierSelectionId, setTierSelectionId] = useState(0);
  const [successfulSignup, setSuccessfulSignup] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts correcting the input
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleAccountSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      setPage(1);
      return;
    }
    setPasswordError("");

    // Error handling for empty values in formData
    for (var key in formData) {
      if (key !== "user_type") {
        if (!formData[key]) {
          setGeneralError(`${key} is required`);
          return;
        }
      }
    }

    try {
      let signUpData = {
        ...formData,
        username: formData.email, // Set username as email
        user_type: "affiliate",
      };
      delete signUpData.confirmPassword; // Remove confirmPassword before the API call

      const data = await signUp(signUpData);
      if (data && !data.password) {
        updateUser(data); // Update global state with the received data
      }

      setPage(page + 1);
      setSuccessfulSignup(true);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
      setPage(1);
      console.error("Error during signup:", error);
    }
  };

  const handleNextClick = (e) => {
    if (page === 1) {
      if (!successfulSignup) {
        handleAccountSubmit(e);
      } else {
        setPage(page + 1);
      }
    } else {
      if (Object.keys(tierSelection).length === 0) {
        setGeneralError("Please make a selection.");
        return;
      } else if (page === 3) {
        handleDonationSubmit(e, tierSelection.priceId, tierSelection.price);
      } else {
        setPage(page + 1);
        setGeneralError("");
      }
    }
  };

  const handleBackClick = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  const handleSlideClick = (index) => {
    setIndexToGo(index);
    setGeneralError("");
  };

  // Sets state for tier so payment can receive price
  const handleTierSelection = (index) => {
    setTierSelection(tiers[index]);
    setTierSelectionId(index);
  };

  // Scrolls to the top of the page everytime page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  // Handles submitting subscription by redirecting to Stripe's URL
  // Redirects to affiliate-signup/success/ if successful
  const handleDonationSubmit = async (e, priceId, amount) => {
    e.preventDefault();

    if (priceId === "priceId_for_5%") {
      try {
        await freeAffiliateSignup(user.user.id);
        navigate("/profile");
      } catch (error) {
        setGeneralError(error);
        console.error("Error: ", error);
      }
    }
    
    let amountToDonate = parseInt(amount.substr(1, amount.length + 1));

    const initializePayment = async () => {
      const data = {
        user: user.user.id,
        price_Id: priceId,
        amount: amountToDonate,
      };
      try {
        const response = await createStripeSubscription(user.user.id, data);
        if (response.redirect_url) {
          window.location.href = response.redirect_url;
        }
      } catch (error) {
        setGeneralError(error);
        console.error("Error: ", error);
      }
    };

    initializePayment();
  };

  const matches500px = useMediaQuery('(min-width: 500px)');
  const matchesmax500px = useMediaQuery('(max-width: 500px)');
  const matches300px = useMediaQuery('(min-width: 300px)');
  const matches360px = useMediaQuery('(max-width: 360px)');

  const buttonWidths = (index) => {
    if (matches500px) {
      return matches500px && tierSelectionId === index ? "220px" : "180px";
    } else if (matches300px) {
      return matches300px && tierSelectionId === index ? "25px" : "25px";
    } else {
      return tierSelectionId === index ? "280px" : "254px";
    }
  }

  const buttonPadding = (index) => {
    if (matches500px) {
      return matches500px && tierSelectionId === index ? "12px 25px" : "6px 25px";
    } else if (matches300px) {
      return matches300px && tierSelectionId === index ? "12px 25px" : "6px 25px";
    } else {
      return tierSelectionId === index ? "12px 60px" : "6px 11px";
    }
  }

  return (
    <div className={`flex flex-col justify-center items-center overflow-clip`}>
      <div className={`${classes.signup_banner} flex flex-col w-full`}>
        <h1 className={`text-white`}>
          {title.filter((text) => title.indexOf(text) === page - 1)}
        </h1>
      </div>
      <div className={`${classes.signup_form} flex flex-col w-full`}>
        <div
          className={`${classes.page} flex flex-row justify-between items-center w-full`}
        >
          <div className={`${classes.step_count}`}>
            <p>Step&nbsp;{page}/4</p>
          </div>
          <progress
            className={`${classes.progress_bar}`}
            value={page}
            max={4}
          />
        </div>

        {page === 1 && (
          <div className={`${classes.form} flex flex-col`}>
            <div className={`${classes.name} flex flex-row`}>
              <div className={`flex flex-col w-full gap-6`}>
                <label htmlFor="first_name">
                  First Name: <span className="text-red-500">*</span>
                </label>
                <input
                  required
                  name="first_name"
                  type="text"
                  onChange={handleChange}
                  value={formData.first_name}
                ></input>
              </div>
              <div className={`flex flex-col w-full gap-6`}>
                <label htmlFor="last_name">
                  Last Name: <span className="text-red-500">*</span>
                </label>
                <input
                  required
                  name="last_name"
                  type="text"
                  onChange={handleChange}
                  value={formData.last_name}
                ></input>
              </div>
            </div>
            <label htmlFor="phone">
              Phone Number: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="phone"
              type="number"
              onChange={handleChange}
              value={formData.phone}
            ></input>
            <label htmlFor="email">
              Email: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="email"
              type="email"
              onChange={handleChange}
              value={formData.email}
            ></input>

            <label htmlFor="password">
              Password: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="password"
              type="password"
              onChange={handleChange}
              value={formData.password}
            ></input>
            <label htmlFor="confirm-password">
              Confirm Password: <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="confirmPassword"
              type="password"
              onChange={handleChange}
              value={formData.confirmPassword}
            ></input>
          </div>
        )}

        {page === 2 && (
          <>
            <div
              className={`${classes.slider_container} hidden sm:hidden md:block w-full z-0`}
              id="slider-container"
            >
              <Slider
                ref={(slider) => {
                  if (slider) {
                    slider.slickGoTo(indexToGo);
                  }
                }}
                {...slideshowSettings}
              >
                {tiers.map((tier, index) => (
                  <div
                    onClick={() => handleSlideClick(index)}
                    className={`${classes.slide} flex justify-center items-center`}
                    id="slide-width"
                    key={index}
                  >
                    <h2>{tier.title}</h2>
                    <h3>
                      {tier.price}
                      <span>/month</span>
                    </h3>
                    <Button
                      variant="contained"
                      sx={{
                        width: `${buttonWidths(index)}`,
                        height: "61px",
                        padding:
                          `${buttonPadding(index)}`,
                        borderRadius: "20px",
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontWeight: "400",
                        lineHeight: "1.2",
                        color: "#6A6A6A",
                        cursor: "pointer",
                        gap: "23px",
                        margin: "15px 0",
                        textTransform: "none",
                        backgroundColor:
                          tierSelectionId === index ? "#FFE0FB" : "#B1CBDA",
                        border:
                          tierSelectionId === index
                            ? "3px solid #D087C8"
                            : "3px solid #7A7A7A",
                        "&:hover": {
                          border: "3px solid #D087C8",
                          backgroundColor: "#FFE0FB",
                        },
                        "&:active, &:focus": {
                          border: "3px solid #D087C8",
                          backgroundColor: "#FFE0FB",
                          outline: "none",
                        },
                      }}
                      onClick={() => handleTierSelection(index)}
                    >
                      Select
                    </Button>
                    <p>{tier.description}</p>
                  </div>
                ))}
              </Slider>
              
            </div>
            <div className={`${classes.containers} sm:block md:hidden flex flex-row w-full flex-wrap mt-16`}>
              {tiers.map((tier, index) => (
                <div
                  className={`${classes.container} ${classes.flex_items} ${classes.boxes} flex flex-col`}
                  id="slide-width"
                  key={index}
                >
                    <h2>{tier.title}</h2>
                    <h3>
                      {tier.price}
                      <span>/month</span>
                    </h3>
                    <Button
                      variant="contained"
                      sx={{
                        width: matches360px ? "150px" : "220px",
                        height: "61px",
                        padding:
                          "12px 25px",
                        borderRadius: "20px",
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontWeight: "400",
                        lineHeight: "1.2",
                        color: "#6A6A6A",
                        cursor: "pointer",
                        gap: "23px",
                        margin: "15px 0",
                        textTransform: "none",
                        backgroundColor:
                          tierSelectionId === index ? "#FFE0FB" : "#B1CBDA",
                        border:
                          tierSelectionId === index
                            ? "3px solid #D087C8"
                            : "3px solid #7A7A7A",
                        "&:hover": {
                          border: "3px solid #D087C8",
                          backgroundColor: "#FFE0FB",
                        },
                        "&:active, &:focus": {
                          border: "3px solid #D087C8",
                          backgroundColor: "#FFE0FB",
                          outline: "none",
                        },
                      }}
                      onClick={() => handleTierSelection(index)}
                    >
                      Select
                    </Button>
                    <p>{tier.description}</p>
                </div>
              ))}
          </div>
        </>
        )}

        {page === 3 && (
          <div className={`${classes.selection_container} flex flex-col`}>
            <h2>{tierSelection.title}</h2>
            <h3>
              {tierSelection.price}
              <span>/month</span>
            </h3>
            <Button
              disabled
              variant="contained"
              sx={{
                width: matchesmax500px ? "150px" : "310px",
                height: "61px",
                padding: "6px 11px",
                borderRadius: "20px",
                fontFamily: "Inter",
                fontSize: "1.5rem",
                fontWeight: "400",
                lineHeight: "1",
                letterSpacing: "0.14000000059604645px",
                textTransform: "none",
                color: "#FFFFFF",
                cursor: "pointer",
                gap: "10px",
                border: "3px solid #D087C8",
                "&:hover": {
                  borderColor: "#D087C8",
                  backgroundColor: "#D087C8",
                },
                "&:disabled": {
                  borderColor: "#D087C8",
                  backgroundColor: "#D087C8",
                  color: "#FFFFFF",
                },
                backgroundColor: "#D087C8",
              }}
            >
              Selected
            </Button>
            <p>{tierSelection.description}</p>
          </div>
        )}

        <div className={`${classes.buttons} flex flex-row w-full`}>
          <Button
            disabled={page === 1}
            variant="contained"
            sx={{
              width: matchesmax500px ? "120px" : "155px",
              height: "48px",
              padding: "12px 60px",
              borderRadius: "31px",
              fontFamily: "Inter",
              fontSize: "1rem",
              fontWeight: "400",
              lineHeight: "1.2",
              color: "#6A6A6A",
              cursor: "pointer",
              gap: "23px",
              textTransform: "none",
              display: page === 5 ? "none" : "",
              "&:hover": {
                borderColor: "#E1E1E1",
                backgroundColor: "#E1E1E1",
              },
              "&:focused": {
                borderColor: "#E1E1E1",
                backgroundColor: "#E1E1E1",
              },
              backgroundColor: "#E1E1E1",
            }}
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              width: matchesmax500px ? "120px" : "155px",
              height: "48px",
              padding: "12px 60px",
              borderRadius: "31px",
              fontFamily: "Inter",
              fontSize: "1rem",
              fontWeight: "400",
              lineHeight: "1.2",
              color: "#FFFFFF",
              cursor: "pointer",
              gap: "23px",
              textTransform: "none",
              display: page === 5 ? "none" : "",
              "&:hover": {
                borderColor: "var(--default-orange)",
                backgroundColor: "var(--default-orange)",
              },
              "&:focused": {
                borderColor: "var(--default-orange)",
                backgroundColor: "var(--default-orange)",
              },
              backgroundColor: "var(--default-orange)",
            }}
            onClick={handleNextClick}
          >
            Next
          </Button>
        </div>

        {page === 1 && passwordError && (
          <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
            {passwordError}
          </div>
        )}

        {generalError && (
          <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
            {generalError}
          </div>
        )}
      </div>
    </div>
  );
}

function AffiliateSignup({ id = null }) {
  const [openSignupForm, setOpenSignupForm] = useState(false);

  const handleSignupClick = (e) => {
    e.preventDefault();
    setOpenSignupForm(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const matches500px = useMediaQuery('(max-width: 500px)');

  return (
    <div className="main-div">
      {openSignupForm ? (
        <SignUpForm openSignupForm={openSignupForm} />
      ) : (
        <>
          <div
            className={`${classes.banner} flex flex-col w-full`}
          >
            <h1 className={`text-white`}>
              A Commission Plan that Benefits You
            </h1>
            <Button
              variant="contained"
              sx={{
                width: matches500px ? "65%" : "439px",
                height: "83px",
                padding: "10px 74px",
                borderRadius: "100px",
                fontFamily: "Inter",
                fontSize: "1.5rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#FFFFFF",
                borderColor: "white",
                cursor: "pointer",
                zIndex: "50",
                textTransform: "none",
                "&:hover": {
                  borderColor: "var(--default-orange)",
                  backgroundColor: "var(--default-orange)",
                },
                "&:active": {
                  borderColor: "var(--default-orange)",
                  backgroundColor: "var(--default-orange)",
                },
                backgroundColor: "var(--default-orange)",
              }}
              onClick={handleSignupClick}
            >
              Join the Program
            </Button>
          </div>

          <div
            className={`${classes.slider_container} hidden sm:hidden md:block w-full z-0 ${matches500px ? "px-16" : "px-2"}`}
            id="slider-container"
          >
            <Slider
              {...slideshowSettings}
            >
              {tiers.map((tier, index) => (
                <div
                  className={`${classes.slide} ${classes.landing_slide}`}
                  id="slide-width"
                  key={index}
                >
                  <h2>{tier.title}</h2>
                  <h3>
                    {tier.price}
                    <span>/month</span>
                  </h3>
                  <p>{tier.description}</p>
                </div>
              ))}
            </Slider>
          </div>

          <div
            className={`${classes.containers} sm:block md:hidden flex flex-row w-full flex-wrap`}
          >
            {tiers.map((tier, index) => (
                <div
                  className={`${classes.container} ${classes.flex_items} flex flex-col`}
                  id="slide-width"
                  key={index}
                >
                  <h2 className="text-left">
                    {tier.price}
                    <span>/month</span>
                  </h2>
                  <p className="mt-8 text-left">
                    Earn <span>{tier.commission}% monthly</span> from donations to PetStar made by those
                    you refer.
                  </p>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AffiliateSignup;
