import axios from "axios";
import { urlifyName } from "../components/shared/Utils/PageUrl";

const API_URL = process.env.REACT_APP_API_URL;

export const getNonprofits = async () => {
  try {
    const response = await axios.get(`${API_URL}/nonprofits/`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Login error:", error.response);
      throw error;
    }
  }
};

export const getNonprofit = async (id, urlName) => {
  const response = await getNonprofits();
  if (!response) return null;
  for (let i in response) {
    if (urlifyName(response[i].org_name) === urlName && response[i].id === parseInt(id))
      return response[i];
  }
  return null;
};
