import stockImage1 from "../../../assets/announcement1.jpg";
import { useEffect } from "react";
import LinkButton from "../../shared/Buttons/LinkButton";
import classes from "./OurMission.module.css";

export default function OurMission() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${classes.our_mission} main-div`}>
      <div className={classes.mission_content}>
        <div className={classes.mission_image}>
          <img src={stockImage1} alt="Our Mission" />
        </div>
        <div className={classes.mission_text}>
          <h1>Our Mission</h1>
          <p>
            Sponsor a Pet is a fundraising platform, dedicated to providing
            Animal Non-Profits a platform to share their stories and help raise
            the necessary resources to save the lives of animals. We do this by
            working together with passionate local organizations to amplify our
            impact in communities as we come together as one. The mission of
            Sponsor a Pet is to provide every animal a loving environment that
            they can call home. Every animal deserves the opportunity to have
            their basic needs met; food, medicine, a safe and loving family. Our
            overarching goals are to eliminate Kill Shelters, Animal Abuse, and
            the destruction of their habitats. We strive to share stories of
            positivity, laughter, uplifting stories, animals who need our help,
            and to give thanks to the volunteers who work tirelessly to help
            find these animals find their forever home. If this resonates with
            you, join our team and lets grow our community!
          </p>
        </div>
      </div>
      <div className={classes.additional_info}>
        <h1>Who We Are</h1>
        <p>
          We are passionate individuals who came to together under a common
          vision to help all animals. We understand working together, moving in
          the same direction will have a greater impact. Sponsor a Pet provides
          an audience across the country that local non-profits do not have
          access to. As we come together we can create a lasting impact in all
          of our communities.
        </p>
      </div>
      <div className={classes.additional_info}>
        <h1>Get Involved</h1>
        <p>
          Signing up as one of our members as a PetStar is a great start. On top
          of that, there’s much more you can do to get involved. The easiest way
          is to share your story with us. We would love to hear from you and
          share your story. What drives your passion for animals and a little
          about your background. Another way would be to share local
          organizations that need our help, or local communities who would want
          to get involved. Our goal is to provide a platform for animals,
          non-profits, Individuals, and companies alike.
        </p>
        <LinkButton
          colour="blue"
          to="/petstar"
          className={classes.mission_donate_button}
        >
          Join the PetStar Club
        </LinkButton>
      </div>
    </div>
  );
}
