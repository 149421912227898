import React, { useEffect, useState } from "react";
import dashboardClasses from "../../Dashboard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "react-tooltip";
import GeneralButton from "../../../shared/Buttons/GeneralButton";
import { useSelector } from "react-redux";
import formClasses from "../../DashboardForm.module.css";
import { Pagination, PaginationItem } from "@mui/material";
import { getReferrals } from "../../../../api/Referrals";
import { affiliateInformation} from "../../../../api/Affiliate";


const commissionMap = {
  // Commission Rate: Price per month
  5: 0,
  8: 10,
  12: 25,
  15: 50,
  20: 100,
}

export default function AffiliateDashboard({ setTab }) {
  const [referralLink, setReferralLink] = useState("");
  const [commission, setCommission] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [copiedText, setCopiedText] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [monthlyValue, setMonthlyValue] = useState(0);
  const [ifReferrals, setIfReferrals] = useState(false);
  const [userAffiliateEmail, setAffiliateUserEmail] = useState("");
  const [userCommissionRate, setUserCommissionRate] = useState(0);
  const [userId, setUserId] = useState();

  useEffect(() => {
    const getAffiliateInformation = async () => {
      const parsedUserData = JSON.parse(userData);

      setReferralLink(`https://www.sponsorapet.org/petstar?affiliate_id=${parsedUserData.affiliate_id}`);
      setMonthlyValue(commissionMap[Math.trunc(parsedUserData.commission_rate)]);
      setCommission(parsedUserData.commission_rate);
      setAffiliateUserEmail(parsedUserData.email);
      setUserCommissionRate(parsedUserData.commission_rate)
      setUserId(parsedUserData.id);
    };

    if (userData.length > 0 ) {
      getAffiliateInformation();
    }
      
  }, [userData]);

  // Change date formatting
  const changeDates = (referrals) => {
    referrals.forEach((referral)=> {
      const year = referral["timestamp"].substr(0, 4);
      const month = referral["timestamp"].substr(5, 2);
      const day = referral["timestamp"].substr(8, 2);
      referral["timestamp"] = `${month}/${day}/${year}`
    })
    return referrals
  }

  useEffect(()=> {
    const getAllReferrals = async () => {
      try {
        // Gets all Referrals
        const response = await getReferrals();

        if (response && response.length > 0) {
          // Filters out referrals by user
          const affiliateInfo = response.filter((res) => res["affiliate"] === userAffiliateEmail);

          // Filtering out user's own donations
          const referralsExceptSelf = affiliateInfo.filter((affiliate) => {
            return affiliate.affiliate !== affiliate.referred_user;
          })

          // Handles if there are no referrals (renders no referrals msg)
          // If there are referrals (renders table)
          if (referralsExceptSelf.length === 0) { 
            setIfReferrals(false);
          } else {
            setIfReferrals(true);
            let ids = [];

            // Gets ids of all the user's referrals
            ids = referralsExceptSelf.map((affiliate) => affiliate.id);

            const referralsInfo = await affiliateInformation(ids);
            const updatedReferrals = changeDates(referralsInfo);

            setReferrals(updatedReferrals);
          }
        }
      } catch (error) {
        console.error("Could not get referrals", error);
      }
    }

    if(userAffiliateEmail) {
      getAllReferrals();
    }
  }, [userAffiliateEmail])

  // Page Calculations

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(referrals.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;

  const totalPages = Math.ceil(referrals.length / itemsPerPage);

  const renderItem = (item) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem {...item} page={page} onChange={handleChangePage} />
      );
    }
    return <PaginationItem {...item} />;
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleModifySubscription = () => {
    setTab("Your Monthly Donation");
  };

  const handleCopyClick = async () => {
    try {
      await window.navigator.clipboard.writeText(referralLink);
      setCopiedText(true);

      // After 2 seconds reset the success message
      setTimeout(() => {
        setCopiedText(false);
      }, 2000);
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
    }
  };

  return (
    <div className={`${dashboardClasses.dashboard_content} h-full ml-14 mt-10 p-4`}>
      <div className={`${dashboardClasses.dashboard_block} border p-4 mb-8`}>
        <h1 className="text-orange">Referral Link</h1>
        {referralLink && (
          <div className="flex flex-row items-center gap-3 justify-center mt-4">
            <label className="text-lg mr-8 text-blue">
              Share this link to refer users
            </label>
            <input
              name="referral_link"
              value={referralLink}
              placeholder="Referral Link"
              className="text-black border-[var(--unselected-grey)] border-solid border-1 rounded-[4px] w-[80%]"
              disabled
            />
            <div>
              <button
                data-tooltip-id="copy"
                data-tooltip-content={copiedText ? "Copied!" : "Copy Text"}
              >
                <FontAwesomeIcon
                  className="hover:cursor-pointer text-xl"
                  icon={faClipboard}
                  onClick={handleCopyClick}
                />{" "}
              </button>
              <Tooltip id="copy" />
            </div>
            <Tooltip />
          </div>
        )}
      </div>
      <div className={`${dashboardClasses.dashboard_block} border p-4 mb-8`}>
        <h1 className="text-orange">Commission Rate</h1>
        <div className="flex flex-col items-center">
          <p className="!text-2xl font-bold mb-2"> {commission} %</p>
          <p>Based on your Monthly PetStar Subscription of ${monthlyValue}</p>
          <GeneralButton
            colour="blue"
            className="w-40"
            onClick={handleModifySubscription}
          >
            Modify subscription
          </GeneralButton>
        </div>
      </div>

      <div className={`${dashboardClasses.dashboard_block} border p-4 mb-8`}>
        <h1 className="text-orange">Referral Activity</h1>
        {ifReferrals ? (
          <table className={`${formClasses.table} w-full border-collapse`}>
          <thead className={``}>
            <tr>
              <th>User</th>
              <th>Donation amount</th>
              <th>Your commission</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {referrals.map((referral, index) => (
              <tr key={index} className="w-full">
                <td>
                  <input
                    className="bg-white border border-gray-300 rounded-md text-black disabled:bg-white disabled:border-none text-center"
                    type="text"
                    name="username"
                    value={referral["reference_id"]}
                    disabled
                    required
                  />
                </td>
                <td>
                  <input
                    className="bg-white border border-gray-300 rounded-md text-black disabled:bg-white disabled:border-none text-center"
                    type="text"
                    name="donation"
                    value={referral["amount"] !== "0.00" ? `$${referral["amount"]}` : "No donations yet"}
                    disabled
                    required
                  />
                </td>
                <td>
                  <input
                    className="bg-white border border-gray-300 rounded-md text-black disabled:bg-white disabled:border-none text-center"
                    type="text"
                    name="commission"
                    value={referral["amount"] !== "0.00" ? `$${referral["amount"] * userCommissionRate/100}` : "N/A"}
                    disabled
                    required
                  />
                </td>
                <td>
                  <input
                    className="bg-white border border-gray-300 rounded-md text-black disabled:bg-white disabled:border-none text-center"
                    type="test"
                    name="date"
                    value={referral["timestamp"]}
                    disabled
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        ) : (
          <div>No Referrals yet!</div>
        )}

        <nav className="overflow-x-auto max-w-full">
          <Pagination
            count={totalPages}
            page={currentPage}
            className="flex p-5 list-none justify-center"
            color="primary"
            renderItem={renderItem}
            onChange={handleChangePage}
          />
        </nav>
      </div>
    </div>
  );
}
