import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { getNpInfo } from "../../../api/NonprofitInfo";
import CampaignModal from "../CampaignModal/CampaignModal";
import DonationModal from "./DonationModal/DonationModal";
import { deleteCampaign, getCampaignByNonProfit } from "../../../api/Campaigns";
import EmailTemplateModal from "../EmailTemplateModal/EmailTemplateModal";
import classes from "./DashboardContent.module.css";
import dashboardClasses from "../Dashboard.module.css";
import {
  Button,
  useMediaQuery,
  Pagination,
  PaginationItem,
  MenuItem,
} from "@mui/material";
import Select from "@mui/material/Select";
import EditCampaignModal from "../EditCampaignModal/EditCampaignModal";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import DashboardBanner from "./DashboardBanner";
import { nonprofitActivateSubscriptions } from "../../../api/NonprofitSubscribe";

function isWithinLastWeek(date) {
  let currentDate = new Date();
  let differenceMilliseconds = currentDate - date;
  let millisecondsInDay = 1000 * 60 * 60 * 24;
  let daysDifference = Math.floor(differenceMilliseconds / millisecondsInDay);
  return daysDifference <= 7;
}

function isSameMonthAsToday(date) {
  let currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth()
  );
}

// Arrow for drop down buttons
const CustomArrowIcon = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.5L6 6.5L11 1.5"
      stroke="black"
      strokeOpacity="0.56"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function DashboardContent({ setTab }) {
  const [localUserData, setLocalUserData] = useState(null);
  const [npData, setNpData] = useState(null);
  const [campaignModal, setCampaignModal] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatingCampaignId, setUpdatingCampaignId] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [QRModal, setQRModal] = useState(false);
  const [campaignUrl, setCampaignUrl] = useState("");
  const [donationModal, setDonationModal] = useState("");
  const navigate = useNavigate();
  const [donationCalcs, setDonationCalcs] = useState({
    weekly: 0,
    monthly: 0,
    total: 0,
  });
  const [weeklyDonations, setWeeklyDonations] = useState([]);
  const [monthlyDonations, setMonthlyDonations] = useState([]);
  const [ifFilterDonations, setIfFilterDonations] = useState(true);
  const [ifSearchDonations, setIfSearchDonations] = useState(false);
  const [donationFilterBy, setDonationFilterBy] = useState("Filter by");
  const [donations, setDonations] = useState([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [campaignSearchInput, setCampaignSearchInput] = useState("");
  const [donationSearchInput, setDonationSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDonations, setCurrentDonations] = useState(donations);
  const [searchResults, setSearchResults] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({});
  const [expandedDonationIndex, setExpandedDonationIndex] = useState(null);
  const [isActivating, setIsActivating] = useState(false);

  const itemsPerPage = 4;

  // Functions to handle mobile screens
  const matchesMax430px = useMediaQuery("(max-width: 430px)");
  const matchesMax344px = useMediaQuery("(max-width: 344px)");

  const getDonations = (campaigns) => {
    let donationList = [];
    if (!campaigns) {
      return;
    }
    const donationsArr = campaigns.map((campaign) => {
      return campaign.donations_details.map((donation) => ({
        ...donation,
        campaign_name: campaign.animal_name,
      }));
    });
    for (const donations of donationsArr) {
      for (const donation of donations) {
        donationList.push(donation);
        if (isWithinLastWeek(new Date(donation.donation_date))) {
          setWeeklyDonations((prev) => [...prev, donation]);
        }
        if (isSameMonthAsToday(new Date(donation.donation_date))) {
          setMonthlyDonations((prev) => [...prev, donation]);
        }
      }
    }
    setDonations(donationList);
    if (currentDonations.length === 0) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setCurrentDonations(donationList.slice(startIndex, endIndex));
    }
  };

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        setLocalUserData(userData);
      } catch (error) {
        console.error("Failed to parse user data: ", error);
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (localUserData && localUserData.nonprofit) {
      const fetchNpInfo = async () => {
        try {
          const response = await getNpInfo(localUserData.nonprofit);
          return response;
        } catch (error) {
          console.error("Error: ", error);
        }
      };

      const fetchCampaigns = async () => {
        try {
          const response = await getCampaignByNonProfit(
            localUserData.nonprofit
          );
          return response;
        } catch (error) {
          console.error("Error: ", error);
        }
      };

      const fetchDonationDetails = async (tempCampaigns) => {
        let tempDonationCalcs = {
          weekly: 0,
          monthly: 0,
          total: 0,
        };
        tempCampaigns.forEach((campaign) =>
          campaign.donations_details.forEach((donation) => {
            if (isWithinLastWeek(new Date(donation.donation_date)))
              tempDonationCalcs.weekly += Number(donation.amount);
            if (isSameMonthAsToday(new Date(donation.donation_date)))
              tempDonationCalcs.monthly += Number(donation.amount);
            tempDonationCalcs.total += Number(donation.amount);
          })
        );
        return tempDonationCalcs;
      };

      const fetchAll = async () => {
        setIsLoading(true);
        const tempNpData = await fetchNpInfo();
        const tempCampaigns = await fetchCampaigns();
        const tempDonationCalcs = await fetchDonationDetails(tempCampaigns);
        setNpData(tempNpData);
        setCampaigns(tempCampaigns);
        setDonationCalcs(tempDonationCalcs);
        getDonations(tempCampaigns);
        setIsLoading(false);
      };

      try {
        fetchAll();
      } catch (error) {
        console.error(error);
      }
    }
  }, [localUserData]);

  // Handle open/close modal for Create Animal Campaign
  const handleOpenModal = () => {
    setCampaignModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModal = () => {
    setCampaignModal(false);
    document.body.style.overflow = "scroll";
  };

  // Handle open/close modal for New Email
  const handleOpenEmailModal = (campaignId) => {
    const campaign = campaigns.find((c) => c.id === campaignId);
    setSelectedCampaign(campaign);
    setEmailModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseEmailModal = () => {
    setEmailModal(false);
    setSelectedCampaign(null);
    document.body.style.overflow = "scroll";
  };

  // Handle open/close modal for Edit/Update Campaign
  const handleOpenModify = (campaignId) => {
    setUpdatingCampaignId(campaignId);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModify = () => {
    setUpdatingCampaignId(null);
    document.body.style.overflow = "scroll";
  };

  const handleButtonClick = (id) => {
    if (confirmDelete[id]) {
      handleDelete(id);
    } else {
      setConfirmDelete((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  // Creates and handles the 'Share' button
  const getCampaignUrlById = (campaignId, animalName) => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/campaigns/${urlifyName(
      npData.org_name
    )}/${urlifyName(animalName)}/${campaignId}`;
  };

  const handleGenerateQRCode = (campaignId, animalName) => {
    const qrUrl = getCampaignUrlById(campaignId, animalName);
    setQRModal(true);
    setCampaignUrl(qrUrl);
  };

  const handleCloseQRCodeModal = () => {
    setQRModal(false);
    setCampaignUrl("");
  };

  const redirectToCampaignPage = (campaignId, animalName) => {
    const campaignUrl = `/campaigns/${urlifyName(npData.org_name)}/${urlifyName(
      animalName
    )}/${campaignId}`;
    navigate(campaignUrl);
  };

  // Handles delete functionality
  const handleDelete = async (campaignId) => {
    try {
      campaignId = parseInt(campaignId);
      await deleteCampaign(campaignId);
      setCampaigns(campaigns.filter((campaign) => campaign.id !== campaignId));
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const openDonationModal = function (type) {
    setDonationModal(type);
    // donationModalRef.current.showModal();
  };

  const closeDonationModal = function () {
    // donationModalRef.current.close();
    setDonationModal("");
  };

  // Searching Campaigns
  const handleCampaignSearchChange = (e) => {
    setCampaignSearchInput(e.target.value);
  };

  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.animal_name
      .toLowerCase()
      .includes(campaignSearchInput.toLowerCase())
  );

  // Searching Donations
  const handleDonationSearchChange = (e) => {
    setDonationSearchInput(e.target.value);
    setIfSearchDonations(true);
    setIfFilterDonations(false);

    if (e.target.value === "") {
      setIfSearchDonations(false);
      setIfFilterDonations(true);
      setCurrentPage(1);
      setCurrentDonations(donations.slice(0, itemsPerPage));
    } else {
      const searchResults = searchDonations();
      setSearchResults(searchResults);
      setCurrentPage(1);
      setCurrentDonations(searchResults.slice(0, itemsPerPage));
    }
  };

  const searchDonations = () => {
    return donations.filter((donation) =>
      donationSearchInput
        ? donation.donor_email
            .toLowerCase()
            .includes(donationSearchInput.toLowerCase())
        : true
    );
  };

  // Filtering Donations
  const getFilteredDonations = () => {
    if (ifSearchDonations) {
      return searchResults;
    }
    if (ifFilterDonations) {
      switch (donationFilterBy) {
        case "This Week":
          return weeklyDonations;
        case "This Month":
          return monthlyDonations;
        default:
          return donations;
      }
    }
    return donations;
  };

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setDonationFilterBy(filter);
    setIfFilterDonations(true);
    setIfSearchDonations(false);
    setCurrentPage(1); // Reset to the first page when the filter changes

    let filteredDonations;
    if (filter === "This Week") {
      filteredDonations = weeklyDonations;
    } else if (filter === "This Month") {
      filteredDonations = monthlyDonations;
    } else {
      filteredDonations = donations;
    }

    setCurrentDonations(filteredDonations.slice(0, itemsPerPage));
  };

  // Calculating page numbers and changing pages for Donations
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(donations.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const skipPages = 2;
  const maxVisiblePages = 6;

  const calculateTotalPages = () => {
    const filteredDonations = getFilteredDonations();
    return Math.ceil(filteredDonations.length / itemsPerPage);
  };

  // Function to render pagination items
  const renderItem = (item) => {
    const totalPages = calculateTotalPages();

    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem {...item} page={page} onChange={handleChangePage} />
      );
    }
    if (matchesMax430px) {
      if (
        item.page > currentPage - Math.floor(maxVisiblePages / 2) &&
        item.page < currentPage + Math.floor(maxVisiblePages / 2)
      ) {
        return <PaginationItem {...item} />;
      }
    } else {
      return <PaginationItem {...item} />;
    }
  };

  // Function to handle page change
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
    const filteredDonations = getFilteredDonations();

    const startIndex = (value - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentDonations(filteredDonations.slice(startIndex, endIndex));
  };

  const expandDonationInfo = (index) => {
    setExpandedDonationIndex(expandedDonationIndex === index ? null : index);
  };

  const handleSubClick = async () => {
    try {
      setIsActivating(true);
      if (npData.id) {
        if (localUserData) {
          const response = await nonprofitActivateSubscriptions({
            nonprofit_id: npData.id,
          });
          setIsActivating(false);
          window.location.reload();
        } else {
          alert("There was an error activating subscriptions");
        }
      }
    } catch (error) {
      console.error("There was a problem activating subscriptions", error);
    }
  };
  
  return (
    <>
      {/* {donationModal ? (
        <DonationModal
          onClose={closeDonationModal}
          type={donationModal}
          donations={
            donationModal === "Weekly" ? weeklyDonations : monthlyDonations
          }
        />
      ) : null} */}

      <div
        className={`${dashboardClasses.dashboard_content} ${classes.dashboard_content} flex flex-col gap-3.5 pt-12 ml-14`}
      >
        <div
          className={`${dashboardClasses.dashboard_block} ${classes.dashboard_block} flex flex-col mb-16`}
        >
          <h1 className={`${classes.header} text-orange !text-3xl`}>
            Dashboard
          </h1>
        </div>
        {npData?.stripe_account?.charges_enabled === true && (
          <>
            {npData?.price_ids && npData?.price_ids?.length > 0 ? (
              <p className="text-lg font-semibold text-green-600 bg-green-100 p-2 rounded">
                Monthly Donations Activated
              </p>
            ) : (
              <>
                <button
                  className="mx-auto w-1/4 bg-blue text-white py-2 px-4 rounded"
                  onClick={handleSubClick}
                  disabled={isActivating}
                >
                  Activate Monthly Donations!
                </button>
                <p className="!text-orange !text-sm font-semibold">This enables users to donate monthly to your organization!</p>
              </>
            )}
          </>
        )}
        <DashboardBanner donations={donations} campaignsCreated={campaigns} />
        <div
          className={`${dashboardClasses.dashboard_block} flex flex-col p-1 gap-1`}
        >
          <h2 className="!text-black !text-2xl font-bold text-left mb-6">
            Your Campaigns
          </h2>
          {npData?.stripe_account?.charges_enabled ? (
            <>
              <div className={`flex flex-col justify-center w-full`}>
                <div className={`flex items-center relative`}>
                  <svg
                    className={`absolute top-2 left-2`}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3496 13.3559C13.2563 13.4478 13.1306 13.4994 12.9996 13.4996C12.8668 13.499 12.7393 13.4476 12.6434 13.3559L9.94336 10.6496C8.80622 11.6047 7.34425 12.084 5.86236 11.9874C4.38046 11.8908 2.99306 11.2259 1.98951 10.1313C0.985965 9.03661 0.443754 7.59681 0.475967 6.11212C0.508179 4.62743 1.11233 3.21249 2.16241 2.16241C3.21249 1.11233 4.62743 0.508179 6.11212 0.475967C7.59681 0.443754 9.03661 0.985965 10.1313 1.98951C11.2259 2.99306 11.8908 4.38046 11.9874 5.86236C12.084 7.34426 11.6047 8.80623 10.6496 9.94336L13.3496 12.6434C13.3969 12.6899 13.4344 12.7453 13.46 12.8065C13.4856 12.8677 13.4988 12.9333 13.4988 12.9996C13.4988 13.0659 13.4856 13.1316 13.46 13.1927C13.4344 13.2539 13.3969 13.3093 13.3496 13.3559ZM6.24961 10.9996C7.18907 10.9996 8.10743 10.721 8.88857 10.1991C9.6697 9.67715 10.2785 8.9353 10.638 8.06736C10.9976 7.19941 11.0916 6.24434 10.9083 5.32293C10.7251 4.40152 10.2727 3.55515 9.60837 2.89085C8.94407 2.22655 8.0977 1.77416 7.17629 1.59088C6.25488 1.4076 5.29981 1.50166 4.43186 1.86118C3.56391 2.2207 2.82206 2.82952 2.30013 3.61065C1.77819 4.39178 1.49961 5.31015 1.49961 6.24961C1.50126 7.50888 2.00224 8.7161 2.89268 9.60654C3.78312 10.497 4.99034 10.998 6.24961 10.9996Z"
                      fill="var(--grey-icon)"
                      fillOpacity="0.5"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Search Animal Campaigns"
                    value={campaignSearchInput}
                    onChange={handleCampaignSearchChange}
                    className={`${classes.grid_input_field} p-2 pl-8 border text-black border-gray-300 rounded-lg mb-4 w-full h-7 `}
                  />
                </div>

                {localUserData &&
                npData.is_approved &&
                (localUserData.np_access_level === "admin" ||
                  localUserData.np_access_level === "editor") ? (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "30px",
                      padding: matchesMax344px ? "25px 10px" : "4px 10px",
                      borderRadius: "20px",
                      fontFamily: "Inter",
                      fontSize: "1rem",
                      fontWeight: "400",
                      lineHeight: "1.2",
                      color: "var(--default-white)",
                      cursor: "pointer",
                      backgroundColor: "var(--default-orange)",
                      border: "var(--default-orange)",
                      boxShadow: "0px 3px 1px -2px var(--grey-hover)",
                      "&:hover": {
                        backgroundColor: "var(--default-orange)",
                        boxShadow: "0px 3px 1px -2px var(--grey-hover)",
                      },
                      "&:active, &:focus": {
                        backgroundColor: "var(--default-orange)",
                      },
                    }}
                    onClick={handleOpenModal}
                  >
                    Create a new animal Campaign
                  </Button>
                ) : (
                  <p>Only Editors and Admins can create animal campaigns</p>
                )}
              </div>
              <CampaignModal
                nonprofitName={npData.org_name}
                nonprofitId={npData.id}
                nonprofitAddress={npData.address}
                isOpen={campaignModal}
                onClose={handleCloseModal}
                setCampaigns={setCampaigns}
                setQrModalOpen={(boolean) => setQrModalOpen(boolean)}
              />
              <QRCodeModal
                isOpen={qrModalOpen}
                onClose={() => setQrModalOpen(false)}
                campaignUrl={campaignUrl}
              />
            </>
          ) : (
            <>
              <p>
                Complete the Stripe Onboarding in the 'Nonprofit Profile' tab,
                once you receive an email of your Nonprofit being approved, to
                receive donations directly to your bank account.
              </p>
              <p>Watch this guide on how to set up your Stripe Account!</p>
              <div
                className={`${dashboardClasses.dashboard_block} ${classes.video} ml-16 my-8 p-1 pl-6 gap-1`}
              >
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/WNJb-iSFC3M?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
                  title="How to Connect Your Stripe Account"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              {npData?.is_approved ? (
                <>
                  <div
                    className={`${classes.campaign_btn_row} flex flex-col justify-center w-full`}
                  >
                    {localUserData &&
                    npData.is_approved &&
                    (localUserData.np_access_level === "admin" ||
                      localUserData.np_access_level === "editor") ? (
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "30px",
                          padding: matchesMax344px ? "25px 10px" : "4px 10px",
                          borderRadius: "20px",
                          fontFamily: "Inter",
                          fontSize: "1rem",
                          fontWeight: "400",
                          lineHeight: "1.2",
                          color: "var(--default-white)",
                          cursor: "pointer",
                          backgroundColor: "var(--default-orange)",
                          boxShadow: "0px 3px 1px -2px var(--grey-hover)",
                          "&:hover": {
                            backgroundColor: "var(--default-orange)",
                            boxShadow: "0px 3px 1px -2px var(--grey-hover)",
                          },
                          "&:active, &:focus": {
                            backgroundColor: "var(--default-orange)",
                          },
                        }}
                        onClick={handleOpenModal}
                      >
                        Create a new animal Campaign
                      </Button>
                    ) : (
                      <p>Only Editors and Admins can create animal campaigns</p>
                    )}
                  </div>
                  <CampaignModal
                    nonprofitName={npData.org_name}
                    nonprofitId={npData.id}
                    nonprofitAddress={npData.address}
                    isOpen={campaignModal}
                    onClose={handleCloseModal}
                    setCampaigns={setCampaigns}
                    setQrModalOpen={(boolean) => setQrModalOpen(boolean)}
                  />
                  <QRCodeModal
                    isOpen={qrModalOpen}
                    onClose={() => setQrModalOpen(false)}
                    campaignUrl={campaignUrl}
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}

          {campaigns && localUserData && npData ? (
            <div
              className={`${classes.campaigns_section} flex gap-8 p-8 overflow-x-auto mb-24`}
            >
              {filteredCampaigns.map((item) => {
                if (item.nonprofit !== localUserData.nonprofit) return null;
                return (
                  <div
                    className={`${classes.dashboard_campaign_card} max-w-52 w-full h-full flex flex-col overflow-hidden gap-1 rounded-lg`}
                    key={item.id}
                  >
                    {item.campaign_images && item.campaign_images[0] ? (
                      <div
                        onClick={() =>
                          redirectToCampaignPage(item.id, item.animal_name)
                        }
                        className={`${classes.gridContainer} grid relative z-10 before:content=[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 before:cursor-pointer`}
                      >
                        <img
                          onClick={() =>
                            redirectToCampaignPage(item.id, item.animal_name)
                          }
                          className={`${classes.campaign_image} cursor-pointer w-52 max-h-32 object-cover z-0`}
                          src={item.campaign_images[0].image_url}
                          alt="Campaign"
                        />
                        <div
                          className={`${classes.text_overlay} absolute top-3 left-6 text-white text-left flex flex-col justify-center items-center z-50`}
                        >
                          <h3
                            className={`text-base font-semibold my-2 mx-0 p-0 cursor-pointer pointer-events-auto`}
                            onClick={() =>
                              redirectToCampaignPage(item.id, item.animal_name)
                            }
                          >
                            {item.animal_name}
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <img alt="" className="bg-stone-400" />
                    )}

                    {localUserData.np_access_level !== "viewer" ? (
                      <div
                        className={`buttons-portion max-h-44 rounded-lg rounded-t-lg px-4 `}
                      >
                        <progress
                          className={`${classes.progress_bar} w-full h-2.5 my-2`}
                          value={item.donation_amount}
                          max={item.donation_goal}
                        />
                        <p className={`!text-xs !text-zinc-700 text-left`}>
                          ${item.donation_amount} of ${item.donation_goal}{" "}
                          raised!
                        </p>
                        <button
                          className={`!text-xxs !text-zinc-500 w-full flex flex-row justify-between underline items-center mt-1`}
                          onClick={() =>
                            redirectToCampaignPage(item.id, item.animal_name)
                          }
                        >
                          Click to View Campaign Details
                          <svg
                            width="12"
                            height="9"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.000859261 5.24957V6.74957H9.00086L4.87586 10.8746L5.94086 11.9396L11.8809 5.99957L5.94086 0.0595703L4.87586 1.12457L9.00086 5.24957H0.000859261Z"
                              fill="var(--dark-grey)"
                            />
                          </svg>
                        </button>

                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "22px",
                            padding: "4px 10px",
                            borderRadius: "20px",
                            fontFamily: "Inter",
                            fontSize: "0.75rem",
                            fontWeight: "500",
                            lineHeight: "1.2",
                            color: "var(--default-white)",
                            cursor: "pointer",
                            backgroundColor: "var(--default-blue)",
                            textTransform: "none",
                            margin: "10px 0 5px 0",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "var(--default-blue)",
                            },
                            "&:active, &:focus": {
                              backgroundColor: "var(--default-blue)",
                            },
                          }}
                          onClick={() => handleOpenModify(item.id)}
                        >
                          Edit/Update Campaign
                        </Button>
                        <EditCampaignModal
                          campaignId={item.id}
                          campaignData={item}
                          isOpen={updatingCampaignId === item.id}
                          onClose={handleCloseModify}
                        />

                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "22px",
                            padding: "4px 10px",
                            borderRadius: "20px",
                            fontFamily: "Inter",
                            fontSize: "0.75rem",
                            fontWeight: "500",
                            lineHeight: "1.2",
                            color: "var(--default-white)",
                            cursor: "pointer",
                            backgroundColor: "var(--default-red)",
                            textTransform: "none",
                            margin: "5px 0",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "var(--default-red)",
                            },
                            "&:active, &:focus": {
                              backgroundColor: "var(--default-red)",
                            },
                          }}
                          onClick={() => handleButtonClick(item.id)}
                        >
                          {confirmDelete[item.id]
                            ? "Confirm Delete?"
                            : "Delete"}
                        </Button>
                        <div
                          className={`${classes.side_by_side_buttons} flex justify-center gap-2 mt-1.5`}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              width: "100%",
                              height: "22px",
                              padding: "4px 10px",
                              borderRadius: "20px",
                              fontFamily: "Inter",
                              fontSize: "0.75rem",
                              fontWeight: "500",
                              lineHeight: "1.2",
                              color: "var(--default-white)",
                              cursor: "pointer",
                              backgroundColor: "var(--default-blue)",
                              textTransform: "none",
                              boxShadow: "none",
                              "&:hover": {
                                backgroundColor: "var(--default-blue)",
                              },
                              "&:active, &:focus": {
                                backgroundColor: "var(--default-blue)",
                              },
                            }}
                            onClick={() =>
                              handleGenerateQRCode(item.id, item.animal_name)
                            }
                          >
                            Share
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              width: "100%",
                              height: "22px",
                              padding: "4px 10px",
                              borderRadius: "20px",
                              fontFamily: "Inter",
                              fontSize: "0.75rem",
                              fontWeight: "500",
                              lineHeight: "1.2",
                              color: "var(--default-white)",
                              cursor: "pointer",
                              backgroundColor: "var(--default-blue)",
                              textTransform: "none",
                              boxShadow: "none",
                              "&:hover": {
                                backgroundColor: "var(--default-blue)",
                              },
                              "&:active, &:focus": {
                                backgroundColor: "var(--default-blue)",
                              },
                            }}
                            onClick={() => handleOpenEmailModal(item.id)}
                          >
                            New Email
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <progress
                          className={`${classes.progress_bar}`}
                          value={item.donation_amount}
                          max={item.donation_goal}
                        />
                        <button
                          className={classes.full_width_button}
                          onClick={() =>
                            handleGenerateQRCode(item.id, item.animal_name)
                          }
                        >
                          Share
                        </button>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <EmailTemplateModal
            isOpen={emailModal}
            onClose={handleCloseEmailModal}
            campaignId={selectedCampaign?.id}
            nonprofitName={npData?.org_name}
            animalName={selectedCampaign?.animal_name}
            nonprofitId={npData?.id}
          />
          {QRModal && (
            <QRCodeModal
              isOpen={QRModal}
              onClose={handleCloseQRCodeModal}
              campaignUrl={campaignUrl}
            />
          )}
        </div>
        <div
          className={`${dashboardClasses.dashboard_block} flex flex-col p-1 gap-1`}
        >
          <div className="flex flex-row justify-between w-full">
            <h2 className="!text-black !text-2xl font-bold text-left mb-6">
              Donations
            </h2>
            <Select
              variant="filled"
              sx={{
                width: "135px",
                height: "30px",
                border: "0",
                color: "var(--dark-grey)",
                backgroundColor: "var(--default-white)",
                paddingRight: "20px",
                fontSize: "12px",
              }}
              value={donationFilterBy}
              IconComponent={CustomArrowIcon}
              onChange={handleFilterChange}
              name="weekday"
            >
              <MenuItem value="Filter by">Filter by</MenuItem>
              <MenuItem value="This Week">This Week</MenuItem>
              <MenuItem value="This Month">This Month</MenuItem>
            </Select>
          </div>

          <div className="flex">
            <div className="flex-1">
              <div className="flex text-center flex-wrap">
                <div className={`flex items-center relative w-full`}>
                  <svg
                    className={`absolute top-2 left-2`}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3496 13.3559C13.2563 13.4478 13.1306 13.4994 12.9996 13.4996C12.8668 13.499 12.7393 13.4476 12.6434 13.3559L9.94336 10.6496C8.80622 11.6047 7.34425 12.084 5.86236 11.9874C4.38046 11.8908 2.99306 11.2259 1.98951 10.1313C0.985965 9.03661 0.443754 7.59681 0.475967 6.11212C0.508179 4.62743 1.11233 3.21249 2.16241 2.16241C3.21249 1.11233 4.62743 0.508179 6.11212 0.475967C7.59681 0.443754 9.03661 0.985965 10.1313 1.98951C11.2259 2.99306 11.8908 4.38046 11.9874 5.86236C12.084 7.34426 11.6047 8.80623 10.6496 9.94336L13.3496 12.6434C13.3969 12.6899 13.4344 12.7453 13.46 12.8065C13.4856 12.8677 13.4988 12.9333 13.4988 12.9996C13.4988 13.0659 13.4856 13.1316 13.46 13.1927C13.4344 13.2539 13.3969 13.3093 13.3496 13.3559ZM6.24961 10.9996C7.18907 10.9996 8.10743 10.721 8.88857 10.1991C9.6697 9.67715 10.2785 8.9353 10.638 8.06736C10.9976 7.19941 11.0916 6.24434 10.9083 5.32293C10.7251 4.40152 10.2727 3.55515 9.60837 2.89085C8.94407 2.22655 8.0977 1.77416 7.17629 1.59088C6.25488 1.4076 5.29981 1.50166 4.43186 1.86118C3.56391 2.2207 2.82206 2.82952 2.30013 3.61065C1.77819 4.39178 1.49961 5.31015 1.49961 6.24961C1.50126 7.50888 2.00224 8.7161 2.89268 9.60654C3.78312 10.497 4.99034 10.998 6.24961 10.9996Z"
                      fill="var(--grey-icon)"
                      fillOpacity="0.5"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Search Donation by Donor Email"
                    value={donationSearchInput}
                    onChange={handleDonationSearchChange}
                    className={`${classes.grid_input_field} p-2 pl-8 border text-black border-gray-300 rounded-lg mb-4 w-full h-7 `}
                  />
                </div>

                {/* Conditionally renders two different tables */}
                {/* if matchesMax430px, renders dropdown rows */}
                {matchesMax430px ? (
                  <div className={dashboardClasses.dashboard_block}>
                    <table
                      className={`${classes.table_styles} !w-full table-fixed !border-separate`}
                    >
                      <thead>
                        <tr>
                          <th className="text-sm font-medium text-neutral-500">
                            Donor Name
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Campaign
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Renders when user searches for donations */}
                        {ifSearchDonations ? (
                          currentDonations.length > 0 ? (
                            currentDonations.map((donation, index) => (
                              <>
                                <tr
                                  key={index}
                                  onClick={() => expandDonationInfo(index)}
                                >
                                  <td className="bg-sky-50 mb-14 rounded-l-xl">
                                    <input
                                      className="text-sm font-normal text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="first_name"
                                      value={donation.donor_first_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                                          Email:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold break-all">
                                            {donation.donor_email}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <input
                                      className="text-sm font-normal text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="campaign_name"
                                      value={donation.campaign_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                                          Date:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold">
                                            {donation.donation_date}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <p className="!text-sm text-neutral-600 text-center">
                                      ${donation.amount}
                                    </p>
                                  </td>

                                  {expandedDonationIndex === index ? (
                                    <td
                                      className="bg-sky-50 mb-14 rounded-r-xl"
                                      align="center"
                                    >
                                      <svg
                                        width="16"
                                        height="9"
                                        viewBox="0 0 16 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M0.999528 9L-3.47717e-07 7.95484L7.33176 0.287717C7.51087 0.103191 7.75054 -2.82587e-07 8 -2.74042e-07C8.24946 -2.65496e-07 8.48913 0.103191 8.66824 0.287717L16 7.95485L14.9995 9L8 1.68092L0.999528 9Z"
                                          fill="#7F7F7F"
                                        />
                                      </svg>
                                    </td>
                                  ) : (
                                    <td
                                      className="bg-sky-50 mb-14 rounded-r-xl"
                                      align="center"
                                    >
                                      <svg
                                        width="16"
                                        height="9"
                                        viewBox="0 0 16 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.0005 1.21503e-07L16 1.04515L8.66824 8.71228C8.48913 8.89681 8.24946 9 8 9C7.75054 9 7.51087 8.89681 7.33176 8.71228L1.39656e-07 1.04515L1.00047 8.10373e-09L8 7.31908L15.0005 1.21503e-07Z"
                                          fill="#7F7F7F"
                                        />
                                      </svg>
                                    </td>
                                  )}
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="4"
                                className="bg-sky-50 rounded-xl text-sm text-neutral-600 text-center"
                              >
                                Sorry, no matching results!
                              </td>
                            </tr>
                          )
                        ) : (
                          ""
                        )}

                        {/* Renders when a user is filtering by week/month or when search is not on */}
                        {ifFilterDonations && !ifSearchDonations ? (
                          currentDonations.length > 0 ? (
                            currentDonations.map((donation, index) => (
                              <>
                                <tr
                                  key={index}
                                  onClick={() => expandDonationInfo(index)}
                                >
                                  <td className="bg-sky-50 rounded-l-xl">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="first_name"
                                      value={donation.donor_first_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-center">
                                          Email:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold break-all">
                                            {donation.donor_email}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="campaign_name"
                                      value={donation.campaign_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-center">
                                          Date:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold">
                                            {donation.donation_date}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50" align="center">
                                    ${donation.amount}
                                  </td>

                                  {expandedDonationIndex === index ? (
                                    <td
                                      className="bg-sky-50 rounded-r-xl"
                                      align="center"
                                    >
                                      <svg
                                        width="16"
                                        height="9"
                                        viewBox="0 0 16 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M0.999528 9L-3.47717e-07 7.95484L7.33176 0.287717C7.51087 0.103191 7.75054 -2.82587e-07 8 -2.74042e-07C8.24946 -2.65496e-07 8.48913 0.103191 8.66824 0.287717L16 7.95485L14.9995 9L8 1.68092L0.999528 9Z"
                                          fill="#7F7F7F"
                                        />
                                      </svg>
                                    </td>
                                  ) : (
                                    <td
                                      className="bg-sky-50 rounded-r-xl"
                                      align="center"
                                    >
                                      <svg
                                        width="16"
                                        height="9"
                                        viewBox="0 0 16 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.0005 1.21503e-07L16 1.04515L8.66824 8.71228C8.48913 8.89681 8.24946 9 8 9C7.75054 9 7.51087 8.89681 7.33176 8.71228L1.39656e-07 1.04515L1.00047 8.10373e-09L8 7.31908L15.0005 1.21503e-07Z"
                                          fill="#7F7F7F"
                                        />
                                      </svg>
                                    </td>
                                  )}
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="4"
                                className="bg-sky-50 rounded-xl text-sm text-neutral-600 text-center"
                              >
                                Sorry, no results!
                              </td>
                            </tr>
                          )
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  // Renders a normal table
                  <div className={dashboardClasses.dashboard_block}>
                    <table
                      className={`${classes.table_styles} !w-full table-fixed !border-separate`}
                    >
                      <thead>
                        <tr>
                          <th className="text-sm font-medium text-neutral-500">
                            Donor Name
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Campaign
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Amount
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Date
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Email
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Renders when user searches for donations */}
                        {ifSearchDonations ? (
                          currentDonations.length > 0 ? (
                            currentDonations.map((donation, index) => (
                              <tr key={index}>
                                <td className="bg-sky-50 mb-14 rounded-l-xl">
                                  <input
                                    className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="text"
                                    name="first_name"
                                    value={donation.donor_first_name}
                                    disabled
                                    required
                                  />
                                </td>
                                <td className="bg-sky-50 mb-14">
                                  <input
                                    className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="text"
                                    name="campaign_name"
                                    value={donation.campaign_name}
                                    disabled
                                    required
                                  />
                                </td>
                                <td className="bg-sky-50 mb-14">
                                  <p className="!text-sm text-neutral-600 text-center">
                                    ${donation.amount}
                                  </p>
                                </td>
                                <td className="bg-sky-50 mb-14">
                                  <input
                                    className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="date"
                                    name="date"
                                    value={donation.donation_date}
                                    disabled
                                    required
                                  />
                                </td>
                                <td className="bg-sky-50 mb-14 rounded-r-xl">
                                  <input
                                    className="text-sm text-neutral-800 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="email"
                                    name="email"
                                    value={donation.donor_email}
                                    disabled
                                    required
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="5"
                                className="bg-sky-50 mb-14 rounded-xl text-sm text-neutral-600 text-center"
                              >
                                Sorry, no matching results!
                              </td>
                            </tr>
                          )
                        ) : (
                          "" // No search results available
                        )}

                        {/* Renders when a user is filtering by week/month or when search is not on */}
                        {
                          ifFilterDonations && !ifSearchDonations ? (
                            currentDonations.length > 0 ? (
                              currentDonations.map((donation, index) => (
                                <tr key={index}>
                                  <td className="bg-sky-50 mb-14 rounded-l-xl">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="first_name"
                                      value={donation.donor_first_name}
                                      disabled
                                      required
                                    />
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="campaign_name"
                                      value={donation.campaign_name}
                                      disabled
                                      required
                                    />
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <p className="!text-sm text-neutral-600 text-center">
                                      ${donation.amount}
                                    </p>
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="date"
                                      name="date"
                                      value={donation.donation_date}
                                      disabled
                                      required
                                    />
                                  </td>
                                  <td className="bg-sky-50 mb-14 rounded-r-xl">
                                    <input
                                      className="text-sm text-neutral-800 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="email"
                                      name="email"
                                      value={donation.donor_email}
                                      disabled
                                      required
                                    />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="5"
                                  className="bg-sky-50 rounded-xl text-sm text-neutral-600 text-center"
                                >
                                  Sorry, no results!
                                </td>
                              </tr>
                            )
                          ) : (
                            ""
                          ) // No filtered results available
                        }
                      </tbody>
                    </table>
                  </div>
                )}

                <div
                  className={`${classes.donation_page_button} flex flex-row justify-between w-full p-5`}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "190px",
                      height: "30px",
                      padding: "4px 0px",
                      borderRadius: "20px",
                      fontFamily: "Inter",
                      fontSize: "1rem",
                      fontWeight: "400",
                      lineHeight: "1.2",
                      color: "var(--default-white)",
                      cursor: "pointer",
                      backgroundColor: "var(--default-blue)",
                      border: "var(--default-blue)",
                      boxShadow: "0px 3px 1px -2px var(--grey-hover)",
                      "&:hover": {
                        backgroundColor: "var(--default-blue)",
                        boxShadow: "0px 3px 1px -2px var(--grey-hover)",
                      },
                      "&:active, &:focus": {
                        backgroundColor: "var(--default-blue)",
                      },
                    }}
                    onClick={() => setTab("Donations")}
                  >
                    See all donations
                  </Button>
                  <nav className="overflow-x-auto max-w-full">
                    <Pagination
                      sx={{
                        ".MuiPaginationItem-root": {
                          backgroundColor: "var(--light-grey)",
                          borderRadius: "0",
                        },
                        ".Mui-selected": {
                          backgroundColor: "var(--default-orange) !important",
                          borderRadius: "0",
                        },
                      }}
                      count={calculateTotalPages(
                        donationFilterBy,
                        weeklyDonations,
                        monthlyDonations,
                        donations,
                        itemsPerPage,
                        searchResults
                      )}
                      page={currentPage}
                      className="flex list-none justify-center"
                      color="primary"
                      renderItem={renderItem}
                      onChange={handleChangePage}
                    />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardContent;
