import USAMap from "react-usa-map";
import { useEffect, useState } from "react";
import { getNonprofits } from "../../../api/Nonprofits";
import { useNavigate } from "react-router-dom";
import classes from "./UsaMap.module.css";

export default function UsaMap() {
  const [nonProfits, setNonprofits] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getNonprofits()
      .then((response) => {
        setNonprofits(response);
      })
      .catch((error) => {
        console.error(`Could not retrieve list of partners`, error);
      });
  }, []);

  //Loop through states and reformat into correct object
  const allStates = nonProfits.map((nonProfit) => {
    return nonProfit.address.state;
  });
  const result = {};

  allStates.forEach((state) => {
    if (state in result) {
      result[state].count++;
    } else {
      result[state] = { fill: "#FEB580", count: 1 };
    }
  });

  //Change Colouring of
  allStates.forEach((state) => {
    const count = result[state].count;
    if (count >= 4) {
      result[state].fill = "#FEA667";
    } else if (count >= 9) {
      result[state].fill = "#FE994F";
    } else if (count >= 16) {
      result[state].fill = "#FE8834";
    } else if (count >= 26) {
      result[state].fill = "#FE791B";
    }
  });

  function statesFilling() {
    return result;
  }

  //Test function for clicks
  function mapHandler(event) {
    navigate(`/non-profit/?search=${event.target.dataset.name}`); //Need changed
  }

  return (
    <>
      <div className={classes.map}>
        <USAMap onClick={mapHandler} customize={statesFilling()} />
      </div>
    </>
  );
}
