import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../../assets/sap_logo_new.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../api/UserContext";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import classes from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../../store";

const HEADER_DROPDOWNS = {
  user: "user",
  aboutUs: "aboutUs",
  partners: "partners",
  hamburger: "hamburger",
};

function Header({ sticky }) {
  const [visibleDropdown, setVisibleDropdown] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const { updateUser } = useUser();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const [back, setBack] = useState("/");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [openBanner, setOpenBanner] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const hideDropdown = () => setVisibleDropdown("");
  const toggleDropdown = (dropdown) => {
    if (visibleDropdown) hideDropdown();
    else setVisibleDropdown(dropdown);
  };
  const toggleDropdownMobile = (dropdown) => {
    if (visibleDropdown === dropdown)
      setVisibleDropdown(HEADER_DROPDOWNS.hamburger);
    else setVisibleDropdown(dropdown);
  };

  useEffect(() => {
    // Retrieve user data from localStorage
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        setLocalUserData(userData);
      } catch (error) {
        console.error("Failed to parse user data: ", error);
        // Optional: Clear the localStorage items if they are corrupted
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        // Optional: Redirect to login or perform other cleanup actions
        navigate("/");
      }
    }

    // Function to check if the click is outside the dropdown
    const handleClickOutside = (event) => {
      const headerNavComponents = [
        classes.nav_dropdown,
        classes.header_nav_dropdown,
        classes.header_icons,
      ];
      if (
        visibleDropdown &&
        !headerNavComponents.some((className) =>
          event.target.closest(`.${className}`)
        )
      ) {
        hideDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleDropdown, navigate]);

  useEffect(() => {
    if (!location || !location.pathname) return;
    if (!(location.pathname === "/signup" || location.pathname === "/login"))
      setBack(location.pathname);
  }, [navigate, location]);

  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");

    if (Object.keys(userData).length !== 0) {
      dispatch(userDataActions.removeUserData());
    }

    updateUser(null); // Uncomment if using a context
    navigate("/");
    window.location.reload();
  };

  useEffect(()=>{
      if (window.location.pathname === '/petstar') {
          if (searchParams.get("affiliate_id")) {
            setOpenBanner(false);
          } else {
            setOpenBanner(true);
          }
        } else {
          setOpenBanner(false);
        }
  }, [window.location.pathname])

  return (
    <>
      <div
        className={
          sticky ? `${classes.header} ${classes.header_sticky}` : classes.header
        }
      >
        <div className={classes.header_left}>
          <a href="/" className={classes.header_logo_link}>
            <img
              src={logo}
              className={
                width >= 1200
                  ? `${classes.header_logo} p-0.6`
                  : `${classes.header_logo_mobile}`
              }
              alt="Sponsor a Pet"
            />
          </a>
        </div>
        {width >= 1200 ? (
          <div className={classes.header_right}>
            <nav className={classes.header_navigation}>
              <a href="/petstar" className={classes.nav_link}>
                PetStar
              </a>
              <div className={classes.nav_dropdown}>
                <Link
                  to="/campaigns"
                  className={classes.nav_link}
                  onClick={hideDropdown}
                >
                  Donate
                </Link>
              </div>
              <a href="/non-profit" className={classes.nav_link}>
                Non-Profit
              </a>
              <a href="/blogs" className={classes.nav_link}>
                Blog
              </a>
              <div className={classes.nav_dropdown}>
                <a href="/partners" className={classes.nav_link}>
                  Corporate Partners
                </a>
              </div>
              <div className={classes.nav_dropdown}>
                <p className={classes.nav_link}>
                  <Link
                    to="/about-us"
                    className={classes.nav_link}
                    onClick={hideDropdown}
                  >
                    About Us
                  </Link>
                  <FontAwesomeIcon
                    icon={
                      visibleDropdown === HEADER_DROPDOWNS.aboutUs
                        ? faAngleUp
                        : faAngleDown
                    }
                    onClick={() => {
                      toggleDropdown(HEADER_DROPDOWNS.aboutUs);
                    }}
                  />
                </p>
                {/* Dropdown content here */}
                {visibleDropdown === HEADER_DROPDOWNS.aboutUs && (
                  <div className={classes.header_nav_dropdown}>
                    <Link
                      to="/faq"
                      className={classes.dropdown_item}
                      onClick={hideDropdown}
                    >
                      FAQ
                    </Link>
                    <Link
                      to="/contact-us"
                      className={classes.dropdown_item}
                      onClick={hideDropdown}
                    >
                      Contact Us
                    </Link>
                    <Link
                      to="/our-mission"
                      className={classes.dropdown_item}
                      onClick={hideDropdown}
                    >
                      Our Mission
                    </Link>
                  </div>
                )}
              </div>
              <Link to="/shop" className={classes.nav_link}>
                Shop
              </Link>
              <Link to="/events" className={classes.nav_link}>
                Events
              </Link>
            </nav>
            {localUserData ? (
              <div className={classes.header_auth_buttons_wrapper}>
                {localUserData.user_type === "internal_admin" ? (
                <Link to="/internal/dashboard" className={classes.alt_auth_button}>
                  Profile
                </Link>
                ) : (
                  <Link to="/profile" className={classes.alt_auth_button}>
                  Profile
                </Link>
                )}
                <Link to="#" onClick={logout} className={classes.dropdown_item}>
                  Logout
                </Link>
              </div>
            ) : location ? (
              <div className={classes.header_auth_buttons_wrapper}>
                <Link
                  to="/signup"
                  className={classes.alt_auth_button}
                  onClick={hideDropdown}
                  state={{ back: back }}
                >
                  Signup
                </Link>
                <Link
                  to="/login"
                  className=""
                  onClick={hideDropdown}
                  state={{ back: back }}
                >
                  Login
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className={classes.header_right}>
            {/* Mobile nav */}
            <div className={classes.header_icons}>
              <FontAwesomeIcon
                icon={faBars}
                className={classes.icon}
                onClick={() => {
                  toggleDropdown(HEADER_DROPDOWNS.hamburger);
                }}
              />
              {/* Dropdown content here */}
              {visibleDropdown !== "" && (
                <div
                  className={`${classes.header_nav_dropdown} ${classes.header_nav_dropdown_mobile}`}
                >
                  {localUserData ? (
                    <Link
                      to="#"
                      onClick={logout}
                      className={classes.dropdown_item}
                    >
                      Logout
                    </Link>
                  ) : location ? (
                    <>
                      <Link
                        to="/signup"
                        className={classes.dropdown_item}
                        onClick={hideDropdown}
                        state={{ back: back }}
                      >
                        Signup
                      </Link>
                      <Link
                        to="/login"
                        className={classes.dropdown_item}
                        onClick={hideDropdown}
                        state={{ back: back }}
                      >
                        Login
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                  <Link
                    to="/petstar"
                    className={classes.dropdown_item}
                    onClick={hideDropdown}
                  >
                    PetStar
                  </Link>
                  <Link
                    to="/campaigns"
                    className={classes.dropdown_item}
                    onClick={hideDropdown}
                  >
                    Donate
                  </Link>
                  <Link
                    to="/non-profit"
                    className={classes.dropdown_item}
                    onClick={hideDropdown}
                  >
                    Non-Profit
                  </Link>
                  <Link
                    to="/blogs"
                    className={classes.dropdown_item}
                    onClick={hideDropdown}
                  >
                    Blog
                  </Link>
                  <Link
                    to="/partners"
                    className={classes.dropdown_item}
                    onClick={hideDropdown}
                  >
                    Corporate Partners
                  </Link>
                  <div className={classes.hamburger_link}>
                    <Link
                      to="/about-us"
                      onClick={hideDropdown}
                      style={{ color: "white" }}
                    >
                      About Us
                    </Link>
                    <FontAwesomeIcon
                      className="mt-1.5"
                      icon={
                        visibleDropdown === HEADER_DROPDOWNS.aboutUs
                          ? faAngleUp
                          : faAngleDown
                      }
                      onClick={() => {
                        toggleDropdownMobile(HEADER_DROPDOWNS.aboutUs);
                      }}
                    />
                  </div>
                  {visibleDropdown === HEADER_DROPDOWNS.aboutUs ? (
                    <div className="pl-[32px]">
                      <Link
                        to="/faq"
                        className={classes.dropdown_item}
                        onClick={hideDropdown}
                      >
                        FAQ
                      </Link>
                      <Link
                        to="/contact-us"
                        className={classes.dropdown_item}
                        onClick={hideDropdown}
                      >
                        Contact Us
                      </Link>
                      <Link
                        to="/our-mission"
                        className={classes.dropdown_item}
                        onClick={hideDropdown}
                      >
                        Our Mission
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  <Link
                    to="/shop"
                    className={classes.dropdown_item}
                    onClick={hideDropdown}
                  >
                    Shop
                  </Link>
                  <Link
                    to="/events"
                    className={classes.dropdown_item}
                    onClick={hideDropdown}
                  >
                    Events
                  </Link>
                  <Link
                    className={`${classes.dropdown_item} pointer-events-none`}
                  >
                    Use Desktop Site for Profile
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {openBanner && (
        <div
          className={`${classes.affiliate_banner} w-full h-10 m-0 p-3 flex justify-center items-center fixed`}
        >
          <a href="/affiliates">
            Click here to view our affiliate program and earn money from
            donations!
          </a>
          <p onClick={() => setOpenBanner(false)}>X</p>
        </div>
      )}
    </>
  );
}

export default Header;
