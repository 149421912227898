import React, { useEffect, useState } from "react";
import classes from "./PreviewModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import GeneralButton from "../../../shared/Buttons/GeneralButton";
import { parseDescription } from "../../../shared/Utils/ParseDescription";
import featureClasses from "../../../Campaigns/CampaignFeature/CampaignFeature.module.css";

const PreviewModal = ({
  isOpen,
  onClose,
  campaignData,
  npAddress,
  imagePreviews,
  graphicImagePreviews,
}) => {
  // This modal opens and shows a preview of the campaign

  const [blurredImages, setBlurredImages] = useState({}); // To track which images are blurred
  const [tab, setTab] = useState("updates");
  const [videoUrls, setVideoUrls] = useState([]);

  // saving all images as one
  const allImages = [
    ...imagePreviews.map((image) => ({ image, graphic: false, show: true })),
    ...graphicImagePreviews.map((image) => ({
      image,
      graphic: true,
      show: blurredImages[image] || false,
    })),
  ];

  const slideshowSettings = {
    dots: true,
    infinite:
      (allImages.length > 0 || videoUrls.length > 0) &&
      allImages.length + videoUrls.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:
      (allImages.length > 0 || videoUrls.length > 0) &&
      allImages.length + videoUrls.length > 1,
    autoplay: false,
    speed: 500,
    adaptiveHeight: true,
  };

  // Checking the URL against regex
  const regex = new RegExp(/^(https?:\/\/)/);

  const validateUrls = (urls) => {
    if (urls) {
      let validatedUrls = urls.split(",");
      for (let i = 0; i < validatedUrls.length; i++) {
        validatedUrls[i] = validatedUrls[i].trim();
        if (regex.test(validatedUrls[i])) {
          if (
            validatedUrls[i].includes("youtube") &&
            validatedUrls[i].indexOf("watch?v=") !== -1
          ) {
            validatedUrls[i] = validatedUrls[i].replace("watch?v=", "embed/");
            validatedUrls[i] = validatedUrls[i].slice(
              0,
              validatedUrls[i].indexOf("&")
            );
          }
        } else {
          validatedUrls[i] = "URL not valid";
        }
      }
      setVideoUrls(validatedUrls);
    }
  };

  useEffect(() => {
    if (campaignData.video_urls) {
      validateUrls(campaignData.video_urls);
    }
  }, [campaignData.video_urls]);

  const handleImageClick = (image) => {
    setBlurredImages((prevState) => ({
      ...prevState,
      [image.image]: true,
    }));
  };

  return (
    <>
      <div className={`${classes.preview_modal} relative main-div w-full h-full `}>
        <div
          className={`${classes.preview_modal_overlay} w-full h-full bg-white`}
        >
          <section className={`${classes.top_section} flex flex-row p-0 w-full`}>
            <h1 className={`${classes.back_button} cursor-pointer w-1/5`} onClick={onClose}>
              <FontAwesomeIcon icon={faArrowLeft} /> Go Back
            </h1>
            <div
              className={`${classes.preview_warning} w-full bg-stone-400 text-white`}
            >
              <p>This is a preview of your campaign.</p>
            </div>
          </section>
          <div className={`${classes.preview_info} flex flex-col justify-start w-full`}>
            <h1 className={`${classes.title} font-bold text-left`}>
              {campaignData.campaign_title
                ? campaignData.campaign_title
                : "Title here"}
            </h1>
            <div className={`${featureClasses.campaign_header}`}>
              <div className={`${featureClasses.campaign_media_carousel}`}>
                {(allImages.length > 0 || videoUrls.length > 0) && (
                  <Slider {...slideshowSettings}>
                    {allImages.length > 0 &&
                      allImages.map((img, index) => (
                        <div
                          key={index}
                          className={`${classes.carousel_slide} w-full h-full`}
                        >
                          <img
                            src={img.image}
                            alt={campaignData.animal_name}
                            className={
                              img.graphic && !img.show ? `relative cursor-pointer blur-md` : ""
                            }
                          />
                          {img.graphic && !img.show && (
                            <span
                              className={`${classes.show_overlay} cursor-pointer absolute text-sm text-center text-black`}
                              onClick={() => handleImageClick(img)}
                            >
                              Show
                            </span>
                          )}
                        </div>
                      ))}
                    {videoUrls.length > 0 &&
                      videoUrls.map((url, index) => (
                        <div key={index} className={`${classes.carousel_slide} w-full h-full`}>
                          {url !== "URL not valid" ? (
                            <iframe
                              src={url}
                              title={`Success Video ${index + 1}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                              className={`${classes.video} `}
                            />
                          ) : (
                            <p
                              className={`flex justify-center items-center w-full h-full text-red`}
                            >
                              Invalid URL
                            </p>
                          )}
                        </div>
                      ))}
                  </Slider>
                )}
              </div>
              <div className={classes.campaign_header_content}>
                <p
                  className={`${classes.campaign_header_address} mt-0 text-left`}
                >
                  {npAddress["city"].toUpperCase()},{" "}
                  {npAddress["state"].toUpperCase()}
                </p>
                <h1 className="text-left">
                  {campaignData.donation_box_title
                    ? campaignData.donation_box_title
                    : "Donate"}
                </h1>
                <div className={`mb-4`}>
                  <div
                    className={`${classes.campaign_progress_text} flex text-black`}
                  >
                    <p className="m-0">0% Donation Completed</p>
                    <p>$0 of ${campaignData.donation_goal} received</p>
                  </div>
                  <div
                    className={`${classes.campaign_progress_bar_background} w-full h-2`}
                  >
                    <div
                      className={`${classes.campaign_progress_bar} h-full`}
                      style={{
                        width: `${Math.round(
                          (0 * 100) / campaignData.donation_goal
                        )}%`,
                      }}
                    />
                  </div>
                </div>
                <div className={`${classes.campaign_donor_box} flex flex-col justify-center items-center mb-4 h-fit w-full`}>
                  <div className={`${classes.donor_box} flex flex-col justify-center items-center`}>
                    <h1 className="mt-4">$0 raised</h1>
                    <h4 className="m-0">by 0 donors who have donated</h4>
                    <div className={`flex gap-4 mt-8`}>
                      {[10, 25, 50, 100].map((amount) => (
                        <button
                          key={amount}
                          className={`${classes.options_row_button} flex flex-col justify-center items-center rounded cursor-pointer font-bold text-center border border-zinc-500 bg-white py-2 px-4`}
                          type="button"
                        >
                          ${amount}
                        </button>
                      ))}
                    </div>
                    <form
                      className={`no-underline w-full`}
                      disabled
                    >
                      <div className={`${classes.donation_amount_input_section} flex flex-col justify-center items-center gap-8 mt-8 mb-0`}>
                        <input
                          type="number"
                          id="donation-amount"
                          name="donation-amount"
                          placeholder="Custom donation amount"
                          className={`${classes.donation_amount_input} flex flex-col rounded border border-neutral-400 w-2/5 pl-4`}
                          disabled
                        />
                        <GeneralButton
                          className={`mb-6 py-2 px-4`}
                          id="submit-amount"
                          colour={"blue"}
                          disabled
                        >
                          Donate
                        </GeneralButton>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.campaign_info} flex flex-auto gap-10 w-full`}>
              <div className={`${classes.campaign_about_animal} text-left`}>
                <h1>
                  About{" "}
                  {campaignData.animal_name
                    ? campaignData.animal_name
                    : "Animal Name"}
                </h1>
                {campaignData.description &&
                parseDescription === campaignData.description ? (
                  <p>{campaignData.description}</p>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parseDescription(campaignData.description),
                    }}
                  />
                )}
              </div>
              <div className={`${classes.campaign_tabbed_info} flex flex-col`}>
                <div className={`flex`}>
                  <div
                    className={
                      tab === "updates"
                        ? `${classes.campaign_tabbed_tab} cursor-pointer bg-zinc-100 text-2xl text-orange p-2 text-center !bg-white`
                        : `${classes.campaign_tabbed_tab} cursor-pointer bg-zinc-100 text-2xl text-orange p-2 text-center`
                    }
                    onClick={() => {
                      setTab("updates");
                    }}
                  >
                    Updates
                  </div>
                  <div
                    className={
                      tab === "donors"
                        ? `${classes.campaign_tabbed_tab} cursor-pointer bg-zinc-100 text-2xl text-orange p-2 text-center !bg-white`
                        : `${classes.campaign_tabbed_tab} cursor-pointer bg-zinc-100 text-2xl text-orange p-2 text-center`
                    }
                    onClick={() => {
                      setTab("donors");
                    }}
                  >
                    Donors
                  </div>
                </div>
                <div className={`overflow-y-scroll rounded-md flex flex-col h-64`}>
                  {tab === "updates" ? (
                    <div
                      className={`flex flex-col p-4 text-left text-black m-0 border border-neutral-300`}
                    >
                      <p>No updates</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {tab === "donors" ? (
                    <div
                      className={`flex flex-col p-4 text-left text-black m-0 border border-neutral-300`}
                    >
                      <p>No updates</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewModal;
