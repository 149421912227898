export default function Arrows() {
  return (
    <>
      <p
        style={{ gridArea: "2 / 5", transform: "rotate(35deg)" }}
        className="content-center justify-center text-blue !text-[2rem]"
      >
        ⟶
      </p>
      <p
        style={{ gridArea: "4 / 6", transform: "rotate(90deg)" }}
        className="content-center justify-center text-blue !text-[2rem]"
      >
        ⟶
      </p>
      <p
        style={{ gridArea: "6 / 5", transform: "rotate(145deg)" }}
        className="content-center justify-center text-blue !text-[2rem]"
      >
        ⟶
      </p>
      <p
        style={{ gridArea: "6 / 3", transform: "rotate(215deg)" }}
        className="content-center justify-center text-blue !text-[2rem]"
      >
        ⟶
      </p>
      <p
        style={{ gridArea: "4 / 2", transform: "rotate(270deg)" }}
        className="content-center justify-center text-blue !text-[2rem]"
      >
        ⟶
      </p>
      <p
        style={{ gridArea: "2 / 3", transform: "rotate(325deg)" }}
        className="content-center justify-center text-blue !text-[2rem]"
      >
        ⟶
      </p>
    </>
  );
}
