import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getCampaign } from "../../../api/Campaigns";
import classes from "./CampaignDonorsBlock.module.css";

export default function CampaignDonorsBlock() {
  const { nonprofit_name, animal_name, campaign_id } = useParams(); // Access the id parameter using useParams hook
  const [donationsDetails, setDonationsDetails] = useState([]);

  useEffect(() => {
    const fetchCampaignDonors = async () => {
      let response = await getCampaign(
        nonprofit_name,
        animal_name,
        campaign_id
      );
      return response.donations_details;
    };
    const fetchAll = async () => {
      let tempDonationsDetails = await fetchCampaignDonors();

      //  Filters for only completed donations
      const filteredDonations = tempDonationsDetails.filter((donation)=>{
        return donation["status"] === "completed"
      })
      setDonationsDetails(filteredDonations);
    };
    try {
      if (campaign_id >= 0) fetchAll();
    } catch (error) {
      console.error(error);
    }
  }, [nonprofit_name, animal_name, campaign_id]);

  return (
    <>
      <div className={classes.campaign_feature_donors}>
        {donationsDetails && donationsDetails.length <= 0 ? (
          <div className={classes.campaign_feature_donors_container}>
            No updates
          </div>
        ) : (
          ""
        )}
        {donationsDetails &&
          donationsDetails.map((donation) => {
            return (
              <div
                className={classes.campaign_feature_donors_container}
                key={donation.pid}
              >
                <p>
                  {donation.donor_first_name} donated ${donation.amount}
                </p>
                <p className={classes.campaign_feature_donors_timestamp}>
                  {donation.donation_date}
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
}
