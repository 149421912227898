import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getNpInfo } from "../../../api/NonprofitInfo";
import { dashboard } from "../../../api/Dashboard";
import { refreshStripeUrl } from "../../../api/RefreshStripeLink";
import { updateNonprofitInfo } from "../../../api/UpdateNonprofitInfo";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import Quill from "quill";
import Editor from "../CampaignModal/Editor/Editor";
import classes from "./NonprofitProfile.module.css";
import dashboardClasses from "../Dashboard.module.css";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import Spinner from "../../shared/LoadingSpinner/Spinner";

export default function NonprofitProfile() {
  const MAX_SIZE = 3 * 1024 * 1024;
  const [localUserData, setLocalUserData] = useState(null);
  const [npData, setNpData] = useState(null);
  const [refreshLink, setRefreshLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [profileFormEnabled, setProfileFormEnabled] = useState(false);
  const [nonprofitProfileFormData, setNonprofitProfileFormData] = useState({
    org_name: "",
    ein_num: "",
    address: {
      street: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
    },
    phone: "",
    mission_stmt: "",
    logo: null,
    instagram_url: "",
    facebook_url: "",
    website_url: "",
    amazon_wishlist_url: "",
    chewy_url: "",
    tiktok_url: "",
  });
  const [nonprofitProfileFormErrors, setNonprofitProfileFormErrors] = useState(
    {}
  );

  const [createError, setCreateError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log(nonprofitProfileFormData);
  }, [nonprofitProfileFormData]);

  useEffect(() => {
    const fetchUserDataAndNpData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          const response = await dashboard(userData.id);
          setLocalUserData(response);

          if (response && response.nonprofit) {
            const npInfo = await getNpInfo(response.nonprofit);

            setNpData(npInfo);
            if (
              npInfo &&
              npInfo.is_approved &&
              npInfo.stripe_connect_url !== ""
            ) {
              const url = await refreshStripeUrl(npInfo.id);
              setRefreshLink(url);
            }
            return npInfo;
          }
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/");
      }
    };

    const fetchNonprofitProfileFormData = (tempNpData) => {
      return {
        org_name: tempNpData.org_name,
        ein_num: tempNpData.ein_num,
        address: {
          street: tempNpData.address.street,
          city: tempNpData.address.city,
          state: tempNpData.address.state,
          zip_code: tempNpData.address.zip_code,
          country: tempNpData.address.country,
        },
        phone: tempNpData.phone,
        mission_stmt: tempNpData.mission_stmt,
        logo: tempNpData.logo,
        instagram_url: tempNpData.instagram_url,
        facebook_url: tempNpData.facebook_url,
        website_url: tempNpData.website_url,
        amazon_wishlist_url: tempNpData.amazon_wishlist_url,
        chewy_url: tempNpData.chewy_url,
        tiktok_url: tempNpData.tiktok_url,
      };
    };

    const fetchAll = async () => {
      setIsLoading(true);
      const tempNpData = await fetchUserDataAndNpData();
      const tempNonprofieProfileFormData =
        fetchNonprofitProfileFormData(tempNpData);
      setNpData(tempNpData);
      setNonprofitProfileFormData(tempNonprofieProfileFormData);
      setIsLoading(false);
    };

    try {
      fetchAll();
    } catch (error) {
      console.error("Error in nonprofit profile", error);
    }
  }, [navigate]);

  const handleNonprofitProfileInputChange = (e) => {
    const { name, value } = e.target;

    const regExURLs = {
      website_url: /^(|[^\s@]+\.[^\s@]+)$/,
    };

    if (regExURLs.website_url && !regExURLs.website_url.test(value)) {
      setNonprofitProfileFormErrors((prevErrors) => ({
        ...(prevErrors || {}),
        [name]: [`Invalid url format.`],
      }));
    } else {
      setNonprofitProfileFormErrors((prevErrors) => {
        const { [name]: removed, ...rest } = prevErrors || {};
        return rest;
      });
    }

    setNonprofitProfileFormData({
      ...nonprofitProfileFormData,
      [name]: value,
    });
  };

  const handleNonprofitAddressInputChange = (e) => {
    const { name, value } = e.target;
    setNonprofitProfileFormData({
      ...nonprofitProfileFormData,
      address: {
        ...nonprofitProfileFormData.address,
        [name]: value,
      },
    });
  };

  const handleNonprofitProfileFormSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(nonprofitProfileFormData);
      const submitData = new FormData();

      if (selectedFile) {
        submitData.append("logo", nonprofitProfileFormData.logo);
      }

      submitData.append("org_name", nonprofitProfileFormData.org_name);
      submitData.append("ein_num", nonprofitProfileFormData.ein_num);

      const addressObject = {
        street: nonprofitProfileFormData.address.street,
        city: nonprofitProfileFormData.address.city,
        state: nonprofitProfileFormData.address.state,
        zip_code: nonprofitProfileFormData.address.zip_code,
        country: nonprofitProfileFormData.address.country,
      };
      submitData.append("address", JSON.stringify(addressObject));
      submitData.append("phone", nonprofitProfileFormData.phone);
      submitData.append("mission_stmt", nonprofitProfileFormData.mission_stmt);

      const correctUrl = (url) => {
        if (url && !url.startsWith("https://") && !url.startsWith("http://")) {
          return "https://" + url;
        }
        return url;
      };

      if (nonprofitProfileFormData.instagram_url) {
        submitData.append(
          "instagram_url",
          correctUrl(nonprofitProfileFormData.instagram_url)
        );
      }
      if (nonprofitProfileFormData.facebook_url) {
        submitData.append(
          "facebook_url",
          correctUrl(nonprofitProfileFormData.facebook_url)
        );
      }
      if (nonprofitProfileFormData.website_url) {
        submitData.append(
          "website_url",
          correctUrl(nonprofitProfileFormData.website_url)
        );
      }
      if (nonprofitProfileFormData.amazon_wishlist_url) {
        submitData.append(
          "amazon_wishlist_url",
          correctUrl(nonprofitProfileFormData.amazon_wishlist_url)
        );
      }
      if (nonprofitProfileFormData.chewy_url) {
        submitData.append(
          "chewy_url",
          correctUrl(nonprofitProfileFormData.chewy_url)
        );
      }
      if (nonprofitProfileFormData.tiktok_url) {
        submitData.append(
          "tiktok_url",
          correctUrl(nonprofitProfileFormData.tiktok_url)
        );
      }
      submitData.append("tos", true);

      if (localUserData) {
        await updateNonprofitInfo(localUserData.nonprofit, submitData);
        setCreateError("");
        setUploaded(false);
        alert("Updated successfully!");
        setProfileFormEnabled(false);
        setNonprofitProfileFormErrors(null);
      } else {
        console.error("No local user data!");
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      const formattedError = lineBreaks(errorMessage);
      console.error(error);
      setCreateError(formattedError);
    }
  };

  const enableEdits = () => {
    setProfileFormEnabled(!profileFormEnabled);
  };

  const disableEdits = () => {
    setProfileFormEnabled(false);
  };

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  const handleEditorChange = (content, delta, source, editor) => {
    setNonprofitProfileFormData((prevState) => ({
      ...prevState,
      mission_stmt: content,
    }));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_SIZE) {
        console.log("max");
        setCreateError("File size is too large (must be under 3MB)");
        event.target.value = "";
        setNonprofitProfileFormData({
          ...nonprofitProfileFormData,
          logo: null,
        });
      } else {
        setCreateError("");
        setNonprofitProfileFormData({
          ...nonprofitProfileFormData,
          logo: file,
        });
        setUploaded(true);
      }
    }
  };

  const handleChangeImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={`${dashboardClasses.dashboard_content} ml-14 pt-12`}>
      <div className={dashboardClasses.dashboard_block}>
        <h2 className="text-3xl text-left font-bold my-2 !text-orange">
          Non-Profit Profile
        </h2>
        <div className="my-4 flex flex-row items-center justify-center mx-auto bg-[#FFFBF9] w-full h-[120px]">
          <p>Account Onboarding</p>
          <div className="w-1/4 ml-8 -mt-2">
            <ProgressBar npData={npData} />
          </div>
          {npData?.is_approved === false ||
          npData?.stripe_account?.charges_enabled === true ? (
            ""
          ) : (
            <div className="flex justify-center items-center w-[228px]">
              {localUserData && localUserData.np_access_level === "admin" ? (
                <GeneralButton
                  colour="blue"
                  className="!p-1 text-[0.9rem] flex justify-center bg-[var(--default-blue)] text-white border-none rounded cursor-pointer no-underline w-fit"
                  onClick={() =>
                    (window.location.href = "/refresh-stripe-connect")
                  }
                >
                  Complete Onboarding
                </GeneralButton>
              ) : (
                <p className="ml-4 text-[0.9rem]">
                  Contact your Nonprofit Admin for them to complete the
                  onboarding process.
                </p>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row w-full h-auto md:h-[300px] mb-[1rem] md:mb-0 gap-4">
          <div className="flex flex-col w-full md:w-1/2">
            <h2 className="text-xl mb-4 md:mb-0 !text-black flex flex-start">
              Non-Profit Image
            </h2>
            <img
              src={
                selectedFile ? URL.createObjectURL(selectedFile) : npData.logo
              }
              className="my-auto mx-auto w-full md:w-4/5 max-h-[200px] object-contain rounded-[8px] min-w-[200px]"
            />
            <div className="flex flex-row items-center gap-2 mx-auto md:mx-0">
              {uploaded ? (
                <button
                  onClick={handleNonprofitProfileFormSubmit}
                  className="mb-1.25 mt-2 md:mt-0 mx-auto py-1 px-3 rounded-md shadow-md bg-orange hover:bg-orange-dark1 font-semibold text-white"
                >
                  Save
                </button>
              ) : (
                <>
                  <button
                    onClick={handleChangeImageClick}
                    className="mb-1.25 mt-2 md:mt-0 mx-auto py-1 px-3 rounded-md shadow-md bg-orange hover:bg-orange-dark1 font-semibold text-white"
                  >
                    Change Image
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full h-auto md:w-1/2 items-start">
            <h2 className="text-xl mb-4 !text-black">Mission Statement</h2>
            <div className="m-auto object-contain md:object-none w-full h-[270px] border border-neutral-400 rounded-[8px]">
              <label className={`${classes.nonprofit_profile_form_label_tall}`}>
                <Editor
                  defaultValue={nonprofitProfileFormData.mission_stmt}
                  onTextChange={handleEditorChange}
                  readOnly={!profileFormEnabled}
                  className="quill-editor"
                />
              </label>
            </div>
          </div>
        </div>
        {npData && npData?.stripe_account ? (
          <div className="my-4 flex flex-col items-center justify-center mx-auto bg-[#FFFBF9] w-full h-[120px]">
            <h2 className="text-xl mb-4 !text-black">Stripe Account</h2>
            <div className="flex flex-row items-center justify-center w-full">
              <p className="pr-[10px] text-black">Account ID: </p>
              <p className="text-black bg-red-200 rounded-[8px] border border-neutral-400 p-[1px_4px]">
                {npData.stripe_account.id}
              </p>
            </div>
          </div>
        ) : (
          <div className="my-4 flex flex-col items-center justify-center mx-auto bg-[#FFFBF9] w-full h-[120px]">
            <p className="text-black">
              Thank you for registering a nonprofit with Sponsor A Pet! We use
              Stripe to process payments, and you can connect your bank account
              with us via Stripe.
              <br />
              We are currently reviewing your nonprofit information, and you
              will be able to link your bank account with us once you are
              approved! Watch out for an email notification for your approval
              status.
            </p>
          </div>
        )}
        <h2 className="text-xl !text-black">Account Details</h2>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleNonprofitProfileFormSubmit}
        >
          <div className="flex flex-col md:flex-row justify-between gap-4 mt-4">
            <label className="flex flex-[0.5] flex-col gap-2 text-left text-[1rem]">
              Organization Name:
              <br />
              <input
                type="text"
                name="org_name"
                value={nonprofitProfileFormData.org_name}
                onChange={handleNonprofitProfileInputChange}
                disabled={!profileFormEnabled}
                className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
              />
            </label>
            <label className="flex flex-[0.5] flex-col gap-2 text-left text-[1rem]">
              EIN:
              <br />
              <input
                type="number"
                name="ein_num"
                value={nonprofitProfileFormData.ein_num}
                onChange={handleNonprofitProfileInputChange}
                disabled={!profileFormEnabled}
                className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
              />
            </label>
            <label className="flex flex-[0.5] flex-col gap-2 text-left text-[1rem]">
              Phone:
              <br />
              <input
                type="number"
                name="phone"
                value={nonprofitProfileFormData.phone}
                onChange={handleNonprofitProfileInputChange}
                disabled={!profileFormEnabled}
                className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
              />
            </label>
          </div>

          <div className="flex flex-col md:flex-row justify-between gap-4">
            <label className="flex flex-[1] flex-col gap-2 text-left text-[1rem]">
              Street Address:
              <br />
              <input
                type="text"
                name="street"
                value={nonprofitProfileFormData.address.street}
                onChange={handleNonprofitAddressInputChange}
                disabled={!profileFormEnabled}
                className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
              />
            </label>
            <label className="flex flex-[0.5] flex-col gap-2 text-left text-[1rem]">
              City:
              <br />
              <input
                type="text"
                name="city"
                value={nonprofitProfileFormData.address.city}
                onChange={handleNonprofitAddressInputChange}
                disabled={!profileFormEnabled}
                className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
              />
            </label>
          </div>

          <div className="flex flex-col md:flex-row justify-between gap-4">
            <label className="flex flex-[0.5] flex-col gap-2 text-left text-[1rem]">
              State:
              <br />
              <select
                name="state"
                value={nonprofitProfileFormData.address.state}
                onChange={handleNonprofitAddressInputChange}
                disabled={!profileFormEnabled}
                className="opacity-100 h-8 appearance-none pl-1 border border-neutral-400 bg-neutral-100 text-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select a state</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
            </label>
            <label className="flex flex-[0.5] flex-col gap-2 text-left text-[1rem]">
              Country:
              <br />
              <select
                name="country"
                value={nonprofitProfileFormData.address.country}
                onChange={handleNonprofitAddressInputChange}
                disabled={!profileFormEnabled}
                className="opacity-100 h-8 appearance-none pl-1 border border-neutral-400 bg-neutral-100 text-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="US">US</option>
              </select>
            </label>
            <label className="flex flex-[0.5] flex-col gap-2 text-left text-[1rem]">
              Zip Code:
              <br />
              <input
                type="text"
                name="zip_code"
                value={nonprofitProfileFormData.address.zip_code}
                onChange={handleNonprofitAddressInputChange}
                disabled={!profileFormEnabled}
                className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
              />
            </label>
          </div>

          <div className="bg-[#FFFBF9] w-full h-auto">
            <h2 className="text-xl mb-4 !text-black mt-3">Links</h2>
            <div className="flex flex-col md:flex-row justify-between gap-4 mt-4 ml-4 mr-4">
              <label className="flex flex-col gap-2 text-left text-[1rem]">
                Website:
                <br />
                <input
                  type="url"
                  name="website_url"
                  value={nonprofitProfileFormData.website_url}
                  onChange={handleNonprofitProfileInputChange}
                  disabled={!profileFormEnabled}
                  className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
                />
                {nonprofitProfileFormErrors &&
                nonprofitProfileFormErrors.website_url ? (
                  <p className="text-red m-0">
                    {nonprofitProfileFormErrors.website_url.join(" ")}
                  </p>
                ) : (
                  ""
                )}
              </label>
              <label className="flex flex-col gap-2 text-left text-[1rem]">
                Instagram:
                <br />
                <input
                  type="url"
                  name="instagram_url"
                  value={nonprofitProfileFormData.instagram_url}
                  onChange={handleNonprofitProfileInputChange}
                  disabled={!profileFormEnabled}
                  className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
                />
                {nonprofitProfileFormErrors &&
                nonprofitProfileFormErrors.instagram_url ? (
                  <p className="text-red m-0">
                    {nonprofitProfileFormErrors.instagram_url.join(" ")}
                  </p>
                ) : (
                  ""
                )}
              </label>
              <label className="flex flex-col gap-2 text-left text-[1rem]">
                Facebook:
                <br />
                <input
                  type="url"
                  name="facebook_url"
                  value={nonprofitProfileFormData.facebook_url}
                  onChange={handleNonprofitProfileInputChange}
                  disabled={!profileFormEnabled}
                  className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
                />
                {nonprofitProfileFormErrors &&
                nonprofitProfileFormErrors.facebook_url ? (
                  <p className="text-red m-0">
                    {nonprofitProfileFormErrors.facebook_url.join(" ")}
                  </p>
                ) : (
                  ""
                )}
              </label>
            </div>

            <div className="flex flex-col md:flex-row justify-between gap-4 mt-4 ml-4 mr-4 pb-4">
              <label className="flex flex-col gap-2 text-left text-[1rem]">
                Amazon Wishlist:
                <br />
                <input
                  type="url"
                  name="amazon_wishlist_url"
                  value={nonprofitProfileFormData.amazon_wishlist_url}
                  onChange={handleNonprofitProfileInputChange}
                  disabled={!profileFormEnabled}
                  className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
                />
                {nonprofitProfileFormErrors &&
                nonprofitProfileFormErrors.amazon_wishlist_url ? (
                  <p className="text-red m-0">
                    {nonprofitProfileFormErrors.amazon_wishlist_url.join(" ")}
                  </p>
                ) : (
                  ""
                )}
              </label>
              <label className="flex flex-col gap-2 text-left text-[1rem]">
                Tiktok:
                <br />
                <input
                  type="url"
                  name="tiktok_url"
                  value={nonprofitProfileFormData.tiktok_url}
                  onChange={handleNonprofitProfileInputChange}
                  disabled={!profileFormEnabled}
                  className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
                />
                {nonprofitProfileFormErrors &&
                nonprofitProfileFormErrors.tiktok_url ? (
                  <p className="text-red m-0">
                    {nonprofitProfileFormErrors.tiktok_url.join(" ")}
                  </p>
                ) : (
                  ""
                )}
              </label>
              <label className="flex flex-col gap-2 text-left text-[1rem]">
                Chewy:
                <br />
                <input
                  type="url"
                  name="chewy_url"
                  value={nonprofitProfileFormData.chewy_url}
                  onChange={handleNonprofitProfileInputChange}
                  disabled={!profileFormEnabled}
                  className="h-8 bg-neutral-100 border border-neutral-400 text-black py-1.5 px-1 rounded-md"
                />
                {nonprofitProfileFormErrors &&
                nonprofitProfileFormErrors.chewy_url ? (
                  <p className="text-red m-0">
                    {nonprofitProfileFormErrors.chewy_url.join(" ")}
                  </p>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
          <div className={classes.nonprofit_profile_form_button_array}>
            {localUserData && localUserData.np_access_level !== "viewer" && (
              <>
                {!profileFormEnabled ? (
                  <GeneralButton
                    colour="orange"
                    type="button"
                    onClick={enableEdits}
                    className="!font-semibold"
                  >
                    Edit Information
                  </GeneralButton>
                ) : (
                  <>
                    <GeneralButton
                      colour="orange"
                      type="button"
                      onClick={disableEdits}
                      className="!font-semibold"
                    >
                      Cancel
                    </GeneralButton>
                    <GeneralButton
                      colour="orange"
                      type="submit"
                      disabled={!profileFormEnabled}
                      className="!font-semibold"
                    >
                      Save Details
                    </GeneralButton>
                  </>
                )}
              </>
            )}
          </div>
        </form>
        {createError && <p className="text-red m-0">{createError}</p>}
      </div>
    </div>
  );
}
