

const circleSlideShowText = [
  "We Help Non-Profits Raise Money",
  "Non-profits Ask Us to Help",
  "We Organize Campaigns",
  "We Advocate Pets’ Rights",
  "We Send the Money to Non-Profits",
  "The Pet Receives Help",
];

const circleSlideShowAlt = [
  "Elevating Non-Profit Impact through Vital Fundraising",
  "Non-Profits Turn to Us for Critical Support and Action",
  "Empowering Change: We Orchestrate Campaigns for Animal Welfare",
  "We Passionately Champion the Rights and Well-Being of Every Pet",
  "Channeling Support: Directing Funds to Non-Profits for Maximum Impact",
  "Transforming Lives: Ensuring Every Pet Receives the Care They Deserve",
];

export default function CircleText({
  handleMouseEnter,
  handleMouseLeave,
  hover,
}) {
  return (
    <>
      <p
        style={{ gridArea: "1 / 4" }}
        className="transition-all ease duration-300 p-2.5 rounded-lg hover:text-blue hover:shadow-lg hover:bg-white hover:cursor-pointer hover:font-bold hover:z-[1]"
        onMouseEnter={() => handleMouseEnter(0)}
        onMouseLeave={handleMouseLeave}
        id="0"
      >
        {hover === 0 ? circleSlideShowAlt[0] : circleSlideShowText[0]}
      </p>
      <p
        style={{ gridArea: "3 / 6" }}
        className="transition-all ease duration-300 p-2.5 rounded-lg hover:text-blue hover:shadow-lg hover:bg-white hover:cursor-pointer hover:font-bold hover:z-[1]"
        onMouseEnter={() => handleMouseEnter(1)}
        onMouseLeave={handleMouseLeave}
        id="1"
      >
        {hover === 1 ? circleSlideShowAlt[1] : circleSlideShowText[1]}
      </p>
      <p
        style={{ gridArea: "5 / 6" }}
        className="transition-all ease duration-300 p-2.5 rounded-lg hover:text-blue hover:shadow-lg hover:bg-white hover:cursor-pointer hover:font-bold hover:z-[1]"
        onMouseEnter={() => handleMouseEnter(2)}
        onMouseLeave={handleMouseLeave}
        id="2"
      >
        {hover === 2 ? circleSlideShowAlt[2] : circleSlideShowText[2]}
      </p>
      <p
        style={{ gridArea: "7 / 4" }}
        className="transition-all ease duration-300 p-2.5 rounded-lg hover:text-blue hover:shadow-lg hover:bg-white hover:cursor-pointer hover:font-bold hover:z-[1]"
        onMouseEnter={() => handleMouseEnter(3)}
        onMouseLeave={handleMouseLeave}
        id="3"
      >
        {hover === 3 ? circleSlideShowAlt[3] : circleSlideShowText[3]}
      </p>
      <p
        style={{ gridArea: "5 / 2" }}
        className="transition-all ease duration-300 p-2.5 rounded-lg hover:text-blue hover:shadow-lg hover:bg-white hover:cursor-pointer hover:font-bold hover:z-[1]"
        onMouseEnter={() => handleMouseEnter(4)}
        onMouseLeave={handleMouseLeave}
        id="4"
      >
        {hover === 4 ? circleSlideShowAlt[4] : circleSlideShowText[4]}
      </p>
      <p
        style={{ gridArea: "3 / 2" }}
        className="transition-all ease duration-300 p-2.5 rounded-lg hover:text-blue hover:shadow-lg hover:bg-white hover:cursor-pointer hover:font-bold hover:z-[1]"
        onMouseEnter={() => handleMouseEnter(5)}
        onMouseLeave={handleMouseLeave}
        id="5"
      >
        {hover === 5 ? circleSlideShowAlt[5] : circleSlideShowText[5]}
      </p>
    </>
  );
}
