import React, { useState, useEffect } from "react";
import CreateBlog from "./CreateBlog";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import { fetchBlogs } from "../../../api/FetchBlogs";
import EditableBlogCard from "./EditableBlogCard";
import EditBlogPage from "./EditBlogPage";
import { blue } from "@mui/material/colors";
import classes from "./DisplayBlogs.module.css";

const DisplayBlogs = () => {
  const [createClicked, setCreateClicked] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [blogPosts, setBlogPosts] = useState([]);
  const [activeCategory, setActiveCategory] = useState("blogs");
  const [editClicked, setEditClicked] = useState(false);
  const [activeBlog, setActiveBlog] = useState([]);

  useEffect(() => {
    const getBlogPosts = async () => {
      try {
        const response = await fetchBlogs();
        const sortedBlogs = response.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setBlogPosts(sortedBlogs);
      } catch (error) {
        console.error(error);
      }
    };
    getBlogPosts();
  }, []);

  const filteredBlogs = blogPosts.filter(
    (blog) =>
      blog.category === activeCategory &&
      blog.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleEditClick = (blog) => {
    setActiveBlog(blog)
    setEditClicked(true);
  };

  const handleEditClose = () => {
    setEditClicked(false);
  };

  const handleCreateClick = () => {
    setCreateClicked(true);
  };

   const handleCloseCreate = () => {
     setCreateClicked(false);
   };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleDelete = (id) => {
    setBlogPosts(blogPosts.filter((blog) => blog.id !== id));
  };

  if (editClicked) {
    return <EditBlogPage blogInfo={activeBlog} onClose={handleEditClose} />;
  }

  if (createClicked) {
    return <CreateBlog onClose={() => handleCloseCreate()} />;
  }

  return (
    <div className="flex flex-col items-center">
      <TextField
        type="text"
        value={searchInput}
        onChange={handleSearchChange}
        placeholder="Search for a blog..."
        className={classes.searchInput}
        size="small"
      />
      <Select
        value={activeCategory}
        onChange={(event) => setActiveCategory(event.target.value)}
        className={classes.select}
        size="small"
      >
        <MenuItem value="blogs">All Blogs</MenuItem>
        <MenuItem value="corporate">Corporate Sponsors</MenuItem>
        <MenuItem value="success">Success Stories</MenuItem>
      </Select>
      <div className="mt-4 mb-4">
        <Button
          variant="contained"
          onClick={handleCreateClick}
          style={{ backgroundColor: blue[500], color: "white" }}
          size="large"
          className={classes.createButton}
        >
          Create a new blog
        </Button>
      </div>
      <div className={`border p-12 ${classes.gridContainer}`}>
        <Grid container spacing={4}>
          {filteredBlogs.map((blog) => (
            <Grid item xs={12} sm={6} md={4} key={blog.id}>
              <EditableBlogCard
                onEdit={()=> handleEditClick(blog)}
                onDelete={handleDelete}
                {...blog}
                blogInfo={blog}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default DisplayBlogs;
