import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const addMember = async (formData) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
    const response = await axios.post(`${API_URL}/nonprofits/invite-member`, formData, config).then((response) => {
      return response.data;
    });
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Add member error:", error.response);
      throw error;
    }
  }
};
