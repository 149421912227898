import React, { useState } from "react";
import { postEvents, addGuests, addSponsors } from "../../../api/Events";
import classes from "./EventsPage.module.css";
import Editor from "../../Dashboard/CampaignModal/Editor/Editor";
import { validateUrl } from "../../shared/Utils/ValidateUrls";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { eventErrorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const maxSize = 3 * 1024 * 1024;

export default function EventsPage({ onClose }) {
  const [event, setEvent] = useState({
    event_name: "",
    title: "",
    description: "",
    banner_image: "", //1 Image
    np_logo: "", //1 Image
    np_blurb: "",
    email: "",
    website: "",
    phone_number: "",
    partner_logo: "", //1 Image
    street: "",
    unit: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    datetime: "",
    end_datetime: "",
    partner_name: "",
    nonprofit_name: "",
    guests_of_honour: [{ id: 1, image: "", name: "" }],
    sponsors: [{ id: 1, image: "", name: "" }],
  });
  const [errorState, setErrorState] = useState({ error: "", success: "" });
  const [urlErrors, setUrlErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const addHttps = (url) => {
    if (url && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    return url;
  };

  const correctUrl = (url) => {
    if (url && !url.startsWith("https://") && !url.startsWith("http://")) {
      return "https://" + url;
    }
    return url;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "event_link") {
      if (value.trim() === "") {
        setUrlErrors("");
      } else {
        const validationResponse = validateUrl(addHttps(value.trim()));
        setUrlErrors(
          validationResponse
            ? ""
            : "Invalid URL format. Expected format: https://www.sponsorapet.org"
        );
      }
    }

    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (event, id) => {
    const file = event.target.files[0];

    if (file.size > maxSize) {
      setErrorState({
        error: "File size is too large (Must be under 3 MB).",
        success: "",
      });
      event.target.value = "";
      return;
    }

    setEvent((prevState) => ({
      ...prevState,
      [id]: file,
    }));
  };

  const handleEditorChange = (content) => {
    setEvent((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const handleSponsorChange = (id, field, value) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      sponsors: prevEvent.sponsors.map((sponsor) =>
        sponsor.id === id ? { ...sponsor, [field]: value } : sponsor
      ),
    }));
  };

  const handleSponsorImageChange = (event, id) => {
    const file = event.target.files[0];

    if (file.size > maxSize) {
      setErrorState({
        error: "File size is too large (Must be under 3 MB).",
        success: "",
      });
      event.target.value = "";
      return;
    } else {
    }

    setEvent((prevEvent) => ({
      ...prevEvent,
      sponsors: prevEvent.sponsors.map((sponsor) => {
        return sponsor.id === id ? { ...sponsor, image: file } : sponsor;
      }),
    }));
  };

  const handleGuestImageChange = (event, id) => {
    const file = event.target.files[0];

    if (file.size > maxSize) {
      setErrorState({
        error: "File size is too large (Must be under 3 MB).",
        success: "",
      });
      event.target.value = "";
      return;
    }

    setEvent((prevEvent) => ({
      ...prevEvent,
      guests_of_honour: prevEvent.guests_of_honour.map((guest) =>
        guest.id === id ? { ...guest, image: file } : guest
      ),
    }));
  };

  const addSponsor = () => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      sponsors: [
        ...prevEvent.sponsors,
        { id: prevEvent.sponsors.length + 1, image: "", name: "" },
      ],
    }));
  };

  const removeSponsor = (id) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      sponsors: prevEvent.sponsors.filter((sponsor) => sponsor.id !== id),
    }));
  };

  const handleGuestChange = (id, field, value) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      guests_of_honour: prevEvent.guests_of_honour.map((guest) =>
        guest.id === id ? { ...guest, [field]: value } : guest
      ),
    }));
  };

  const addGuest = () => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      guests_of_honour: [
        ...prevEvent.guests_of_honour,
        { id: prevEvent.guests_of_honour.length + 1, image: "", name: "" },
      ],
    }));
  };

  const removeGuest = (id) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      guests_of_honour: prevEvent.guests_of_honour.filter(
        (guest) => guest.id !== id
      ),
    }));
  };

  const handleDateChange = (date, id) => {
    const utcDateTime = new Date(date.toUTCString().slice(0, -4));

    if (id === "start" && utcDateTime > event.end_datetime) {
      setEvent((prev) => ({
        ...prev,
        datetime: utcDateTime,
        end_datetime: "",
      }));
    } else {
      setEvent((prev) => ({
        ...prev,
        [id === "start" ? "datetime" : "end_datetime"]: utcDateTime,
      }));
    }
  };

  const onSubmitCreateEvents = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (event.startDate === "" || event.end_datetime === "") {
      setErrorState({
        error: "Please select a start date and a end date",
        success: "",
      });
      setLoading(false);
      return;
    }

    if (
      event.street === "" ||
      event.city === "" ||
      event.state === "" ||
      event.zip_code === "" ||
      event.country === ""
    ) {
      setErrorState({
        error: "Please input a valid address",
        success: "",
      });
      setLoading(false);
      return;
    }

    const addressObject = {
      street: event.street,
      city: event.city,
      state: event.state,
      zip_code: event.zip_code,
      country: event.country,
      unit: event.unit,
    };

    const formData = new FormData();
    formData.append("address", JSON.stringify(addressObject));
    formData.append("event_name", event.event_name);
    formData.append("title", event.title);
    formData.append("description", event.description);

    formData.append("np_blurb", event.np_blurb);
    formData.append("email", event.email);
    formData.append("website", correctUrl(event.website));
    formData.append("phone_number", event.phone_number);

    formData.append("event_link", correctUrl(event.event_link));
    formData.append("datetime", event.datetime.toISOString());
    formData.append("end_datetime", event.end_datetime.toISOString());

    formData.append("nonprofit_name", event.nonprofit_name);
    formData.append("partner_name", event.partner_name);
    formData.append("banner_image", event.banner_image);

    formData.append("np_logo", event.np_logo);
    formData.append("partner_logo", event.partner_logo);

    try {
      const eventResponse = await postEvents(formData);
      const eventId = eventResponse.id;
      // Create Sponsors
      for (const sponsor of event.sponsors) {
        const sponsorFormData = new FormData();
        sponsorFormData.append("name", sponsor.name);
        sponsorFormData.append("image", sponsor.image);
        sponsorFormData.append("event", Number(eventId));

        const resp = await addSponsors(sponsorFormData);
      }

      // Create Guests of Honour
      for (const guest of event.guests_of_honour) {
        const guestFormData = new FormData();
        guestFormData.append("name", guest.name);
        guestFormData.append("image", guest.image);
        guestFormData.append("event", Number(eventId));

        const resp = await addGuests(guestFormData);
      }

      setErrorState({
        error: "",
        success: "Event and related entities created successfully!",
      });
    } catch (eventError) {
      const errorMessage = eventErrorHandler(eventError);
      const errorMessageWithBreaks = lineBreaks(errorMessage);
      setErrorState({ error: errorMessageWithBreaks, success: "" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="overflow-y-auto h-full">
      <h1
        className={`text-orange cursor-pointer w-1/5 hover:bg-blue hover:text-white transition duration-300 rounded-lg`}
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faArrowLeft} /> Go Back
      </h1>
      <h1 className="text-orange">Create Event</h1>
      <form
        onSubmit={onSubmitCreateEvents}
        className={`${classes.form_column} mx-40`}
      >
        <label className={classes.input_example}>
          Event Name <span className="text-[--default-red]">*</span>
        </label>
        <input
          type="text"
          name="event_name"
          value={event.event_name}
          onChange={handleChange}
          placeholder="Event Name"
          className="text-black"
          // required
        />
        <label className={classes.input_example}>
          Event Title <span className="text-[--default-red]">*</span>
        </label>
        <input
          name="title"
          value={event.title}
          onChange={handleChange}
          placeholder="Event Title"
          className="text-black"
          // required
        />
        <label className={classes.input_example}>
          Event Description <span className="text-[--default-red]">*</span>
        </label>
        <Editor
          defaultValue={event.description}
          onTextChange={handleEditorChange}
        />
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Street (E.g. 188 King St.)
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="text"
              name="street"
              value={event.street}
              onChange={handleChange}
              placeholder="E.g. 188 King St."
              // required
            />
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              City (E.g. San Francisco)
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="text"
              name="city"
              value={event.city}
              onChange={handleChange}
              placeholder="E.g. San Francisco"
              // required
            />
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              State <span className="text-[--default-red]">*</span>
            </p>
            <select
              name="state"
              value={event.state}
              onChange={handleChange}
              placeholder="E.g. CA"
              className={`border p-2 py-2.5 rounded-md`}
              // required
            >
              <option value="" disabled>
                Select State
              </option>
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NE">NE</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="WA">WA</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
            </select>
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Zip Code (E.g. 94107){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="number"
              name="zip_code"
              value={event.zip_code}
              onChange={handleChange}
              placeholder="E.g. 94107"
              // required
            />
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Country <span className="text-[--default-red]">*</span>
            </p>
            <select
              name="country"
              value={event.country}
              onChange={handleChange}
              className={`border p-2 py-2.5 rounded-md text-grey ${classes.select}`}
              // required
            >
              <option value="" disabled>
                Select Country
              </option>
              <option value="US">US</option>
            </select>
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>Unit (E.g. 2)</p>
            <input
              type="number"
              name="unit"
              value={event.unit}
              onChange={handleChange}
              placeholder="E.g. 2"
            />
          </div>
        </div>

        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>Nonprofit Blurb</p>
            </div>
            <textarea
              type="text"
              name="np_blurb"
              value={event.np_blurb}
              onChange={handleChange}
              placeholder="Nonprofit Blurb"
              className="!h-40"
            />
          </div>
        </div>

        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Phone (E.g. 1234567890){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="number"
              name="phone_number"
              value={event.phone_number}
              onChange={handleChange}
              placeholder="E.g. 1234567890"
            />
          </div>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>
                Nonprofit Email <span className="text-[--default-red]">*</span>
              </p>
            </div>
            <input
              type="text"
              name="email"
              value={event.email}
              onChange={handleChange}
              placeholder="Nonprofit Email"
              // required
            />
          </div>
        </div>

        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>
                Nonprofit Website{" "}
                <span className="text-[--default-red]">*</span>
              </p>
            </div>
            <input
              type="text"
              name="website"
              value={event.website}
              onChange={handleChange}
              placeholder="E.g. https://www.sponserapet.org"
              // required
            />
            {urlErrors.instagram_url && (
              <div className="text-[var(--default-red)]">
                {urlErrors.instagram_url}
              </div>
            )}
          </div>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>
                Event Link <span className="text-[--default-red]">*</span>
              </p>
            </div>
            <input
              type="text"
              name="event_link"
              value={event.event_link}
              onChange={handleChange}
              placeholder="E.g. https://www.sponserapet.org"
              // required
            />
            {urlErrors.instagram_url && (
              <div className={classes.create_error}>
                {urlErrors.instagram_url}
              </div>
            )}
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>
                Start Date
                <span className="text-[--default-red]">*</span>
              </p>
            </div>
            <DatePicker
              selected={event.datetime}
              onChange={(date) => handleDateChange(date, "start")}
              startDate={new Date()}
              minDate={new Date()}
              showTimeSelect
              // required
            />
          </div>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>
                End Date
                <span className="text-[--default-red]">*</span>
              </p>
            </div>
            <DatePicker
              selected={event.end_datetime}
              onChange={(date) => handleDateChange(date, "end")}
              startDate={event.datetime}
              endDate={event.end_datetime}
              minDate={event.datetime}
              showTimeSelect
              // required
            />
          </div>
        </div>

        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>Partner Name</p>
            </div>
            <input
              type="text"
              name="partner_name"
              value={event.partner_name}
              onChange={handleChange}
              placeholder="Partner Name"
              // required
            />
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>Nonprofit Name</p>
            <input
              type="text"
              name="nonprofit_name"
              value={event.nonprofit_name}
              onChange={handleChange}
              placeholder="Nonprofit Name"
            />
          </div>
        </div>

        <div className="flex flex-row">
          <p className={classes.input_example}>
            Banner Image
            <span className="text-[--default-red]">*</span>
          </p>
        </div>
        <input
          type="file"
          name="banner_image"
          onChange={(e) => handleImageChange(e, "banner_image")}
          className="text-black"
          accept="image/*"
          // required
        />

        <div className="flex flex-row">
          <p className={classes.input_example}>Nonprofit Logo</p>
        </div>
        <input
          type="file"
          name="np_logo"
          onChange={(e) => handleImageChange(e, "np_logo")}
          className="text-black"
          accept="image/*"
        />

        <div className="flex flex-row">
          <p className={classes.input_example}>Partner Logo</p>
        </div>
        <input
          type="file"
          name="partner_logo"
          onChange={(e) => handleImageChange(e, "partner_logo")}
          className="text-black"
          accept="image/*"
        />

        <label className="mb-2 font-bold text-left">Sponsors</label>

        {event.sponsors.map((sponsor, index) => (
          <div key={sponsor.id} className={classes.form_row}>
            <div className={classes.form_tab_col}>
              <div className="flex items-center">
                <p className={classes.input_example}>Sponsor Image</p>
              </div>
              <input
                type="file"
                name={`sponsor_image_${sponsor.id}`}
                onChange={(e) => handleSponsorImageChange(e, sponsor.id)}
                className="text-black"
                accept="image/*"
              />
            </div>
            <div className={classes.form_tab_col}>
              <div className="flex items-center">
                <p className={classes.input_example}>Sponsor Name</p>
              </div>
              <input
                type="text"
                name={`sponsor_name_${sponsor.id}`}
                value={sponsor.name}
                onChange={(e) =>
                  handleSponsorChange(sponsor.id, "name", e.target.value)
                }
                className="text-black"
              />
            </div>
            <button
              className={`${
                index > 0 ? "text-[var(--default-red)]" : "text-white"
              } text-7xl rounded-xl justify-center`}
              type="button"
              onClick={() => removeSponsor(sponsor.id)}
              disabled={index === 0}
            >
              -
            </button>
          </div>
        ))}

        <div>
          <button
            className="text-blue !w-32 content-center"
            type="button"
            onClick={addSponsor}
          >
            + Add more
          </button>
        </div>

        <label className="mb-2 font-bold text-left">Guest of Honours</label>

        {event.guests_of_honour.map((guest, index) => (
          <div key={guest.id} className={classes.form_row}>
            <div className={classes.form_tab_col}>
              <div className="flex items-center">
                <p className={classes.input_example}>Guest of Honour Image</p>
              </div>
              <input
                type="file"
                name={`guest_image_${guest.id}`}
                onChange={(e) => handleGuestImageChange(e, guest.id)}
                className="text-black"
                accept="image/*"
              />
            </div>
            <div className={classes.form_tab_col}>
              <div className="flex items-center">
                <p className={classes.input_example}>Guest of Honour Name</p>
              </div>
              <input
                type="text"
                name={`guest_name_${guest.id}`}
                value={guest.name}
                onChange={(e) =>
                  handleGuestChange(guest.id, "name", e.target.value)
                }
                className="text-black"
              />
            </div>
            <button
              className={`${
                index > 0 ? "text-[var(--default-red)]" : "text-white"
              } text-7xl rounded-xl justify-center`}
              type="button"
              onClick={() => removeGuest(guest.id)}
              disabled={index === 0}
            >
              -
            </button>
          </div>
        ))}
        <div>
          <button
            className="text-blue !w-32 content-center"
            type="button"
            onClick={addGuest}
          >
            + Add more
          </button>
        </div>

        <div>
          <GeneralButton colour={"blue"} type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save Event"}
          </GeneralButton>
        </div>
        {errorState.success && (
          <p className="text-green-500">{errorState.success}</p>
        )}
        {errorState.error ? (
          <p className="text-[var(--default-red)]">{errorState.error}</p>
        ) : (
          ""
        )}
      </form>
    </div>
  );
}
