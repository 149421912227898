import React, { useEffect, useState } from "react";
import { signUp } from "../../api/Signup";
import { useUser } from "../../api/UserContext";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GeneralButton from "../shared/Buttons/GeneralButton";
import classes from "./SignupPage.module.css";
import { Button, useMediaQuery } from "@mui/material";

function SignupPage({ props }) {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    user_type: "",
    token: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const { updateUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [urlToken, setUrlToken] = useSearchParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts correcting the input
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setPasswordError("");

    try {
      // Use email as username for the API call
      const signUpData = {
        ...formData,
        username: formData.email, // Set username as email
      };
      delete signUpData.confirmPassword; // Remove confirmPassword before the API call

      const data = await signUp(signUpData);
      if (data && !data.password) {
        updateUser(data); // Update global state with the received data
        navigate(`/profile`);
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
      console.error("Error during signup:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (urlToken.get("token")) {
      setFormData((prevState) => ({
        ...prevState,
        token: urlToken.get("token"),
        user_type: "nonprofit_member",
      }));
    }
  },[])

  const matches540px = useMediaQuery('(max-width: 540px)');

  return (
    <>
    {matches540px ? ( 
      <div className="flex justify-center items-center min-h-screen pt-20 pb-20">
      <form className={`${classes.signup_card} mt-6`} onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold text-orange mb-6">
          Sign Up
        </h2>

        <label className="text-left text-gray-800 mb-2">
          First Name: <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          required
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          placeholder="First Name"
        />

        <label className="text-left text-gray-800 mb-2">
          Last Name: <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          required
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          placeholder="Last Name"
        />

        <label className="text-left text-gray-800 mb-2">
          Email: <span className="text-red-500">*</span>
        </label>
        <input
          type="email"
          name="email"
          required
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
        />

        <label className="text-left text-gray-800 mb-2">Phone Number:</label>
        <input
          type="number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone Number"
        />

        <label className="text-left text-gray-800 mb-2">
          Password: <span className="text-red-500">*</span>
          <br />{" "}
          <span className="text-xs mb-2">
            (at least 8 characters long with letters and numbers)
          </span>
        </label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
        />

        <label className="text-left text-gray-800 mb-2">
          Confirm Password: <span className="text-red-500">*</span>
        </label>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          placeholder="Confirm Password"
        />

        {!urlToken.get("token") ? (
          <>
          <label className="text-left text-gray-800 mb-2 text-sm">
          Do you intend to set up a nonprofit?{" "}
          <span className="text-red-500">*</span>
        </label>
        <select
          name="user_type"
          value={formData.user_type}
          onChange={handleChange}
        >
          <option value="" disabled>
            Select an option
          </option>
          <option value="nonprofit_member">Yes</option>
          <option value="regular_user">No</option>
        </select>
        </>
        ) : ""}
        

        {passwordError && (
          <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
            {passwordError}
          </div>
        )}
        {generalError && (
          <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
            {generalError}
          </div>
        )}

        <div className="flex justify-center mt-10">
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "173px",
              height: "48px",
              padding: "12px 48px",
              borderRadius: "31px",
              fontFamily: "Inter",
              fontSize: "1rem",
              fontWeight: "400",
              lineHeight: "1.2",
              color: "var(--default-white) !important",
              cursor: "pointer",
              gap: "23px",
              marginBottom: "2rem",
              textTransform: "none",
              backgroundColor: "var(--default-orange)",
              "&:hover": {
                backgroundColor: "var(--default-orange)",
              },
              "&:active, &:focus": {
                backgroundColor: "var(--default-orange)",
                outline: "none",
              },
            }}
          >
            Sign Up
          </Button>
        </div>

        <button 
          to="/login"
          className="underline !bg-transparent !p-0">
            Already Have an Account? Log in!
        </button>
      </form>
    </div>
    ) : (
      <div className="flex justify-center items-center min-h-screen gray-100 pt-20 pb-20">
        <form className={`${classes.signup_card} mt-24`} onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-orange mb-4">
            Create Your Account
          </h2>

          <label>
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
          />

          <label>
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name"
          />

          <label>
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />

          <label>Phone Number</label>
          <input
            type="number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number"
          />

          <label>
            Password <span className="text-red-500">*</span>
            <br />{" "}
            <span className="text-xs mb-2">
              (at least 8 characters long with letters and numbers)
            </span>
          </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
          />

          <label>
            Confirm Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
          />

          {!urlToken.get("token") ? (
            <>
            <label>
            Do you intend to set up a nonprofit?{" "}
            <span className="text-red-500">*</span>
          </label>
          <select
            name="user_type"
            value={formData.user_type}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select an option
            </option>
            <option value="nonprofit_member">Yes</option>
            <option value="regular_user">No</option>
          </select>
          </>
          ) : ""}
          

          {passwordError && (
            <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
              {passwordError}
            </div>
          )}
          {generalError && (
            <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
              {generalError}
            </div>
          )}

          <GeneralButton colour="blue" type="submit">
            Sign Up
          </GeneralButton>

          <p className="text-center m-4">
            Already have an account?{" "}
            <Link
              to="/login"
              state={{ back: `${state && state.back ? state.back : "/"}` }}
              className="underline text-blue"
            >
              Login
            </Link>
          </p>
        </form>
      </div>
    )}
    </>
  );
}

export default SignupPage;
