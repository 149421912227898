import React, { useEffect, useState } from "react";
import dashboardClasses from "../Dashboard.module.css";
import classes from "./ConnectBankAccount.module.css";
import { refreshAffiliateUrl } from "../../../api/RefreshAffiliateUrl";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { useNavigate } from "react-router-dom";

const ConnectBankAccount = () => {
  const [localUserData, setLocalUserData] = useState(null);
  const [refreshLink, setRefreshLink] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const userDataString = localStorage.getItem("userData");
      if (userDataString) {
        try {
          const userData = JSON.parse(userDataString);
          setLocalUserData(userData);
          if (localUserData && localUserData.stripe_account) {
            const url = await refreshAffiliateUrl(userData.id);
            setRefreshLink(url);
          }
        } catch (error) {
          console.error("Failed to parse user data: ", error);
          localStorage.removeItem("userData");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div className={`${dashboardClasses.dashboard_content} h-full ml-14 mt-10 p-4`}>
      {localUserData && localUserData?.stripe_account ? (
        <>
          {localUserData.stripe_connect_url === "" ? (
            <div>
              <div className={`${dashboardClasses.dashboard_block} !border p-4 mb-8`}>
                <h2 className="text-2xl font-bold my-2 !text-orange">
                  Stripe Account
                </h2>
                {localUserData.stripe_account ? (
                  <div className={`${classes.account_details} flex flex-row w-full gap-5`}>
                    <p className={`${classes.account_label} text-right font-bold pr-2.5`}>Account ID</p>
                    <p className={`${classes.account_id} text-left`}>
                      {localUserData.stripe_account.id}
                    </p>
                  </div>
                ) : (
                  <p>No Stripe account found.</p>
                )}
              </div>
              {localUserData.stripe_account && (
                <div className={`${dashboardClasses.dashboard_block} !border p-4 mb-8`}>
                  <h2 className="text-2xl font-bold my-2 !text-orange">Required Actions</h2>
                  <p>Link your bank account</p>
                  <button onClick={() => (window.location.href = refreshLink)}>
                    Link
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className={`${dashboardClasses.dashboard_block} !border p-4 mb-8`}>
                <h2 className="text-2xl font-bold my-2 !text-orange">
                  Stripe Account
                </h2>
                <div className={`${classes.account_details} flex flex-row w-full gap-5`}>
                  <p className={`${classes.account_label} text-right font-bold pr-2.5`}>Account ID</p>
                  <p className={`${classes.account_id} text-left`}>
                    {localUserData.stripe_account.id}
                  </p>
                </div>
              </div>
              {localUserData.stripe_account.charges_enabled === true ? (
                <div>
                  <p>Your bank account is connected!</p>
                </div>
              ) : (
                <div className={`${dashboardClasses.dashboard_block} !border p-4 mb-8`}>
                  <h2 className="text-2xl font-bold my-2 !text-orange">Required Actions</h2>
                  <p>
                    Complete the onboarding process to start receiving payments.
                  </p>
                  <div className={`${classes.onboard_btn} flex justify-center items-center`}>
                    {localUserData &&
                    localUserData.user_type === "affiliate" ? (
                      <GeneralButton
                        colour="blue"
                        className={`${classes.stripe_button} flex justify-center p-2 text-white cursor-pointer text-base rounded border-none no-underline`}
                        onClick={() =>
                          (window.location.href = "/refresh-affiliate-connect")
                        }
                      >
                        Complete Onboarding
                      </GeneralButton>
                    ) : (
                      <p>
                        Please contact support to complete the onboarding
                        process.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div>
          <p>
            Thank you for registering as an affiliate with Sponsor A Pet! We use
            Stripe to process payments, and you can connect your bank account
            with us via Stripe.
            <br />
            There are no required actions for you currently.
          </p>
        </div>
      )}
    </div>
  );
};

export default ConnectBankAccount;
