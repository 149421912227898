import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import InternalError from "./components/shared/InternalError.js";
import { healthCheck } from "./api/HealthCheck.js";
import useSticky from "./hooks/useSticky.js";
import Header from "./components/shared/Header/Header.js";
import Footer from "./components/shared/Footer/Footer.js";
import HomePage from "./components/HomePage/HomePage.js";
import SignupPage from "./components/SignupPage/SignupPage.js";
import LoginPage from "./components/LoginPage/LoginPage.js";
import Dashboard from "./components/Dashboard/Dashboard";
import AboutUs from "./components/AboutUs/AboutUs/AboutUs";
import Faq from "./components/AboutUs/Faq/Faq";
import ContactUs from "./components/AboutUs/ContactUs/ContactUs";
import OurMission from "./components/AboutUs/OurMission/OurMission";
import PartnerSearch from "./components/Partners/PartnerSearch/PartnerSearch";
import NonprofitCreate from "./components/Dashboard/NonprofitCreate/NonprofitCreate.js";
import PartnerFeature from "./components/Partners/PartnerFeature/PartnerFeature";
import NewsletterPage from "./components/NewsletterPage/NewsletterPage.js";
import NonprofitSearch from "./components/Nonprofits/NonprofitSearch/NonprofitSearch";
import NonprofitFeature from "./components/Nonprofits/NonprofitFeature/NonprofitFeature";
import RefreshStripeConnect from "./components/shared/RefreshStripeConnect/RefreshStripeConnect.js";
import TermsService from "./components/shared/Footer/TermsService/TermsService.js";
import RefundPolicy from "./components/shared/Footer/RefundPolicy/RefundPolicy.js";
import BlogSearch from "./components/Blog/BlogSearch/BlogSearch.js";
import BlogPage from "./components/Blog/BlogPage/BlogPage.js";
import CampaignSearch from "./components/Campaigns/CampaignSearch/CampaignSearch";
import CampaignFeature from "./components/Campaigns/CampaignFeature/CampaignFeature";
import PetStar from "./components/PetStar/PetStar";
import ForgotPassword from "./components/shared/ForgotPassword/ForgotPassword.js";
import ResetPassword from "./components/shared/ResetPassword/ResetPassword.js";
import PetstarSuccess from "./components/shared/PetstarSuccess/PetstarSuccess.js";
import PrivacyPolicy from "./components/shared/Footer/PrivacyPolicy/PrivacyPolicy";
import ShopSearch from "./components/Shop/ShopSearch/ShopSearch.js";
import ShopProduct from "./components/Shop/ShopProduct/ShopProduct.js";
import Cart from "./components/Shop/Cart/Cart.js";
import AdminSignup from "./components/AdminSignup/AdminSignup.js";
import LogoutPopup from "./components/shared/LogoutPopup/LogoutPopup.js";
import NotFound from "./components/shared/Utils/NotFound.js";
import EventSearch from "./components/Events/EventSearch/EventSearch.js";
import EventDetails from "./components/Events/EventSearch/EventDetails.js";
import AffiliateLanding from "./components/Affiliate/Affiliate.js";
import AffiliateSignup from "./components/Affiliate/AffiliateSignup.js";
import AffiliateSuccess from "./components/Affiliate/AffiliateSuccess.js";
import { dashboard } from "./api/Dashboard.js";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "./store/index.js";
import ConfirmSubscription from "./components/NewsletterPage/ConfirmSubscription/ConfirmSubscription.js";
import RefreshAffiliateConnect from "./components/shared/RefreshAffiliateConnect/RefreshAffiliateConnect.js";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard.js";

const useLogout = () => {
  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  };

  return logout;
};

const useAutoLogout = (logout, setOpenModal, logoutAfterSeconds = 300) => {
  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);

      logoutTimer = setTimeout(() => {
        if (localStorage.getItem("userData")) {
          setOpenModal("logout");
          logout();
        }
      }, logoutAfterSeconds * 1000);
    };

    // Reset the timer on these events
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Initial timer setup
    resetTimer();

    // Cleanup
    return () => {
      clearTimeout(logoutTimer);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [logoutAfterSeconds, logout, setOpenModal]);
};

const FooterWithReRender = () => {
  const location = useLocation();

  useEffect(() => {
    // Trigger any re-render logic you need here
  }, [location]);

  return <Footer />;
};

function App() {
  const isSticky = useSticky();
  const [openModal, setOpenModal] = useState("");
  const [isServerUp, setIsServerUp] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const logout = useLogout();
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  // Centralized Error Handling
  const handleError = (error) => {
    if (!error.response) {
      // Network error (e.g., server is down or unreachable)
      console.error("Network Error: Could not connect to the server.", error);
      setShowErrorPopup(true);
      setIsServerUp(false);
    } else {
      // Other errors (e.g., 4xx responses)
      console.error("Error:", error.response);
    }
  };

  // Health Check with Timeout
  useEffect(() => {
    const checkHealth = async () => {
      try {
        // Create a timeout promise
        const timeout = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 3000)
        );

        // Race the healthCheck API call against the timeout
        const internalError = await Promise.race([healthCheck(), timeout]);

        console.log("Internal Error: ", internalError);

        if (internalError.data && internalError.data.serverstatus === "healthy" 
          && internalError.data.database === "healthy") {
          setShowErrorPopup(false);
          setIsServerUp(true); // Server is up
        } else {
          setShowErrorPopup(true);
          setIsServerUp(false); // Server is down
        }
      } catch (error) {
        handleError(error);
      }
    };

    checkHealth();
  }, []);

  useAutoLogout(logout, setOpenModal, 3600); // 1 hour

  // Fetch User Data
  useEffect(() => {
    if (isServerUp && !showErrorPopup) { // Only fetch data if the server is up and no error has been detected
      const fetchUserData = async () => {
        try {
          const userDataString = localStorage.getItem("userData");

          if (userDataString) {
            const userDataParsed = JSON.parse(userDataString);
            const response = await dashboard(userDataParsed.id);
            dispatch(userDataActions.updateUserData(JSON.stringify(response)));
          }
        } catch (error) {
          handleError(error);
          // Clear the localStorage items if they are corrupted or on error
          localStorage.removeItem("userData");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");

          if (Object.keys(userData).length !== 0) {
            dispatch(userDataActions.removeUserData());
          }
        }
      };

      if (Object.keys(userData).length === 0) {
        fetchUserData();
      }
    }
  }, [dispatch, userData, isServerUp, showErrorPopup]);

  if (showErrorPopup) {
    return (
      <>
        <InternalError />
      </>
    );
  }

  return (
    <Router>
      <div className="App">
        <Header sticky={isSticky} />
        {openModal === "logout" && (
          <LogoutPopup
            setOpenModal={(type) => {
              setOpenModal(type);
              if (type === "") {
                window.location.href = "/";
              }
            }}
            logout={logout}
          />
        )}
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/admin-signup" element={<AdminSignup />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/profile" element={<Dashboard />} />
            <Route path="/internal/dashboard" element={<AdminDashboard />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/our-mission" element={<OurMission />} />
            <Route path="/partners" element={<PartnerSearch />} />
            <Route
              path="/partners/:organization_name/:partner_id"
              element={<PartnerFeature />}
            />
            <Route path="/create-nonprofit" element={<NonprofitCreate />} />
            <Route
              path="/newsletter-unsubscribe"
              element={<NewsletterPage />}
            />
            <Route path="/non-profit" element={<NonprofitSearch />} />
            <Route
              path="/non-profit/:org_name/:nonprofit_id"
              element={<NonprofitFeature />}
            />
            <Route
              path="/refresh-stripe-connect"
              element={<RefreshStripeConnect />}
            />
            <Route path="/terms-of-service" element={<TermsService />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blogs" element={<BlogSearch />} />
            <Route path="/blogs/:title" element={<BlogPage />} />
            <Route path="/campaigns" element={<CampaignSearch />} />
            <Route
              path="/campaigns/:nonprofit_name/:animal_name/:campaign_id"
              element={<CampaignFeature />}
            />
            <Route path="/petstar" element={<PetStar />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/petstar/success" element={<PetstarSuccess />} />
            <Route path="/shop" element={<ShopSearch />} />
            <Route path="/shop/:product_id" element={<ShopProduct />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/events" element={<EventSearch />} />
            <Route path="/events/:title/:id" element={<EventDetails />} />
            <Route path="/affiliates" element={<AffiliateLanding />} />
            <Route path="/affiliates/signup" element={<AffiliateSignup />} />
            <Route
              path="/affiliates/success/"
              element={<AffiliateSuccess />}
            />
            <Route path="/confirm/:id" element={<ConfirmSubscription />} />
            <Route
              path="/refresh-affiliate-connect"
              element={<RefreshAffiliateConnect />}
            />
            {/* more routes */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <FooterWithReRender />
      </div>
    </Router>
  );
}

export default App;
