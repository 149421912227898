import React, { useState } from "react";
import formClasses from "../../DashboardForm.module.css";
import dashboardClasses from "../../Dashboard.module.css";
import { Pagination, PaginationItem, useMediaQuery } from "@mui/material";
import classes from "./UserDonationTable.module.css";

function isWithinLastWeek(date) {
  let currentDate = new Date();
  let differenceMilliseconds = currentDate - date;
  let millisecondsInDay = 1000 * 60 * 60 * 24;
  let daysDifference = Math.floor(differenceMilliseconds / millisecondsInDay);
  return daysDifference <= 7;
}

function isSameMonthAsToday(date) {
  let currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth()
  );
}

export default function UserDonationTable({ data, npData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentNpPage, setCurrentNpPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [searchQuery, setSearchQuery] = useState("");
  const [npSearchQuery, setNpSearchQuery] = useState("");
  const [timeRange, setTimeRange] = useState("month");
  const [npTimeRange, setNpTimeRange] = useState("month");
  const [expandRow, setExpandRow] = useState(null);
  const [npExpandRow, setNpExpandRow] = useState(null);

  const matchesMax430px = useMediaQuery("(max-width: 430px");

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

   const handleNpSearchChange = (event) => {
     setNpSearchQuery(event.target.value);
     setCurrentPage(1); // Reset to first page on search
   };

  // Handle time range change
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    setCurrentPage(1); // Reset to first page on time range change
  };

   const handleNpTimeRangeChange = (event) => {
     setNpTimeRange(event.target.value);
     setCurrentPage(1); // Reset to first page on time range change
   };


  // Filter data based on search query and time range
  const filterData = (data) => {
    return data.filter((donation) => {
      const donationDate = new Date(donation.donation_date);
      let isInTimeRange = false;

      if (timeRange === "month") {
        isInTimeRange = isSameMonthAsToday(donationDate);
      } else if (timeRange === "week") {
        isInTimeRange = isWithinLastWeek(donationDate);
      }

      return (
        isInTimeRange &&
        (donation.nonprofit_org_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
          donation.animal_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
      );
    });
  };

  const filterNpData = (data) => {
    return data.filter((donation) => {
      const donationDate = new Date(donation.donation_date);
      let isInTimeRange = false;

      if (npTimeRange === "month") {
        isInTimeRange = isSameMonthAsToday(donationDate);
      } else if (npTimeRange === "week") {
        isInTimeRange = isWithinLastWeek(donationDate);
      }

      return (
        isInTimeRange &&
        donation.nonprofit_org_name
          .toLowerCase()
          .includes(npSearchQuery.toLowerCase())
      );
    });
  };
  const filteredData = data ? filterData(data) : [];
  const filteredNpData = npData ? filterNpData(npData) : [];

  // Calculate the current items for data
  const indexOfLastItemData = currentPage * itemsPerPage;
  const indexOfFirstItemData = indexOfLastItemData - itemsPerPage;
  const currentItemsData = data
    ? filteredData.slice(indexOfFirstItemData, indexOfLastItemData)
    : [];

  // Calculate the current items for npData
  const indexOfLastItemNpData = currentPage * itemsPerPage;
  const indexOfFirstItemNpData = indexOfLastItemNpData - itemsPerPage;
  const currentItemsNpData = npData
    ? filteredNpData.slice(indexOfFirstItemNpData, indexOfLastItemNpData)
    : [];

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;
  const maxVisiblePages = 6;

  const totalPagesData = filteredData
    ? Math.ceil(filteredData.length / itemsPerPage)
    : [];
  const totalPagesNpData = filteredNpData
    ? Math.ceil(filteredNpData.length / itemsPerPage)
    : [];

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNpChange = (event, value) => {
    setCurrentNpPage(value);
  };

  const renderItem = (item, totalPages) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChange(null, page)}
        />
      );
    }
    if (matchesMax430px) {
      if (
        item.page > currentPage - Math.floor(maxVisiblePages / 2) &&
        item.page < currentPage + Math.floor(maxVisiblePages / 2)
      ) {
        return <PaginationItem {...item} />;
      }
    } else {
      return <PaginationItem {...item} />;
    }
  };

  const renderNpItem = (item, totalPages) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleNpChange(null, page)}
        />
      );
    }
    if (matchesMax430px) {
      if (
        item.page > currentNpPage - Math.floor(maxVisiblePages / 2) &&
        item.page < currentNpPage + Math.floor(maxVisiblePages / 2)
      ) {
        return <PaginationItem {...item} />;
      }
    } else {
      return <PaginationItem {...item} />;
    }
  };

  // Function that handles row expansion
  const handleExpandRow = (index) => {
    setExpandRow(expandRow === index ? null : index);
  };

   const handleNpExpandRow = (index) => {
     setNpExpandRow(npExpandRow === index ? null : index);
   };


  return (
    <>
      {data && data.length > 0 && (
        <div className={`${dashboardClasses.dashboard_block} mb-8`}>
          <h2 className="text-orange !text-3xl font-bold text-left mb-2">
            Animal Campaign Donations
          </h2>
          <div className="flex flex-col mb-4">
            <div className="flex justify-end mb-2">
              <select
                value={timeRange}
                onChange={handleTimeRangeChange}
                className="text-md mb-2"
              >
                <option value="month">This Month</option>
                <option value="week">This Week</option>
              </select>
            </div>
            <div className={`flex items-center relative w-full`}>
              <svg
                className={`absolute top-2 left-2`}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3496 13.3559C13.2563 13.4478 13.1306 13.4994 12.9996 13.4996C12.8668 13.499 12.7393 13.4476 12.6434 13.3559L9.94336 10.6496C8.80622 11.6047 7.34425 12.084 5.86236 11.9874C4.38046 11.8908 2.99306 11.2259 1.98951 10.1313C0.985965 9.03661 0.443754 7.59681 0.475967 6.11212C0.508179 4.62743 1.11233 3.21249 2.16241 2.16241C3.21249 1.11233 4.62743 0.508179 6.11212 0.475967C7.59681 0.443754 9.03661 0.985965 10.1313 1.98951C11.2259 2.99306 11.8908 4.38046 11.9874 5.86236C12.084 7.34426 11.6047 8.80623 10.6496 9.94336L13.3496 12.6434C13.3969 12.6899 13.4344 12.7453 13.46 12.8065C13.4856 12.8677 13.4988 12.9333 13.4988 12.9996C13.4988 13.0659 13.4856 13.1316 13.46 13.1927C13.4344 13.2539 13.3969 13.3093 13.3496 13.3559ZM6.24961 10.9996C7.18907 10.9996 8.10743 10.721 8.88857 10.1991C9.6697 9.67715 10.2785 8.9353 10.638 8.06736C10.9976 7.19941 11.0916 6.24434 10.9083 5.32293C10.7251 4.40152 10.2727 3.55515 9.60837 2.89085C8.94407 2.22655 8.0977 1.77416 7.17629 1.59088C6.25488 1.4076 5.29981 1.50166 4.43186 1.86118C3.56391 2.2207 2.82206 2.82952 2.30013 3.61065C1.77819 4.39178 1.49961 5.31015 1.49961 6.24961C1.50126 7.50888 2.00224 8.7161 2.89268 9.60654C3.78312 10.497 4.99034 10.998 6.24961 10.9996Z"
                  fill="var(--grey-icon)"
                  fillOpacity="0.5"
                />
              </svg>
              <input
                type="text"
                placeholder="Search by Non-Profit Name or Animal Name"
                value={searchQuery}
                onChange={handleSearchChange}
                className={`p-2 pl-8 border text-black border-gray-300 rounded-lg mb-4 w-full h-7 `}
              />
            </div>
          </div>

          {matchesMax430px ? (
            <table
              className={`${formClasses.table_styles_override} !w-full table-fixed !border-separate ${classes.table}`}
            >
              <thead>
                <tr>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Non-Profit Name
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Animal Name
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItemsData.map((donation, index) => (
                  <tr
                    key={index}
                    className="bg-sky-50"
                    onClick={() => handleExpandRow(index)}
                  >
                    <td className="rounded-l-xl text-neutral-600 bg-sky-50 break-words">
                      {donation.nonprofit_org_name}

                      {expandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Date
                            <span className="text-neutral-600 !text-xs font-bold">
                              {donation.donation_date}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      {donation.animal_name}

                      {expandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Payment ID
                            <span className="text-neutral-600 !text-xs font-bold break-all">
                              {donation.pid}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      ${donation.amount}
                    </td>

                    {expandRow === index ? (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <svg
                          width="16"
                          height="9"
                          viewBox="0 0 16 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.999528 9L-3.47717e-07 7.95484L7.33176 0.287717C7.51087 0.103191 7.75054 -2.82587e-07 8 -2.74042e-07C8.24946 -2.65496e-07 8.48913 0.103191 8.66824 0.287717L16 7.95485L14.9995 9L8 1.68092L0.999528 9Z"
                            fill="#7F7F7F"
                          />
                        </svg>
                      </td>
                    ) : (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <svg
                          width="16"
                          height="9"
                          viewBox="0 0 16 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.0005 1.21503e-07L16 1.04515L8.66824 8.71228C8.48913 8.89681 8.24946 9 8 9C7.75054 9 7.51087 8.89681 7.33176 8.71228L1.39656e-07 1.04515L1.00047 8.10373e-09L8 7.31908L15.0005 1.21503e-07Z"
                            fill="#7F7F7F"
                          />
                        </svg>
                      </td>
                    )}
                  </tr>
                ))}
                {filteredData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="4">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          ) : (
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className="text-neutral-500">Non-Profit Name</th>
                  <th className="text-neutral-500">Animal Name</th>
                  <th className="text-neutral-500">Amount</th>
                  <th className="text-neutral-500">Date</th>
                  <th className="text-neutral-500">Payment ID</th>
                </tr>
              </thead>
              <tbody>
                {currentItemsData.map((donation, index) => (
                  <tr key={index} className="bg-sky-50">
                    <td className="rounded-l-xl text-neutral-600">
                      {donation.nonprofit_org_name}
                    </td>
                    <td className="text-neutral-600">{donation.animal_name}</td>
                    <td className="text-neutral-600">${donation.amount}</td>
                    <td className="text-neutral-600">
                      {donation.donation_date}
                    </td>
                    <td className="rounded-r-xl text-neutral-600 break-all">
                      {donation.pid}
                    </td>
                  </tr>
                ))}
                {filteredData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="5">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          )}

          <nav className="overflow-x-auto w-full mt-4 flex justify-end">
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  backgroundColor: "var(--light-grey)",
                  borderRadius: "0",
                },
                ".Mui-selected": {
                  backgroundColor: "var(--default-orange) !important",
                  borderRadius: "0",
                },
              }}
              count={totalPagesData}
              page={currentPage}
              onChange={handleChange}
              className="flex list-none justify-center"
              color="primary"
              renderItem={renderItem}
            />
          </nav>
        </div>
      )}
      {npData && npData.length > 0 && (
        <div className={`${dashboardClasses.dashboard_block}`}>
          <h2 className="text-orange !text-3xl font-bold text-left mb-2">
            Non-Profit Donations
          </h2>
          <div className="flex flex-col mb-4">
            <div className="flex justify-end mb-2">
              <select
                value={npTimeRange}
                onChange={handleNpTimeRangeChange}
                className="text-md mb-2"
              >
                <option value="month">This Month</option>
                <option value="week">This Week</option>
              </select>
            </div>
            <div className={`flex items-center relative w-full`}>
              <svg
                className={`absolute top-2 left-2`}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3496 13.3559C13.2563 13.4478 13.1306 13.4994 12.9996 13.4996C12.8668 13.499 12.7393 13.4476 12.6434 13.3559L9.94336 10.6496C8.80622 11.6047 7.34425 12.084 5.86236 11.9874C4.38046 11.8908 2.99306 11.2259 1.98951 10.1313C0.985965 9.03661 0.443754 7.59681 0.475967 6.11212C0.508179 4.62743 1.11233 3.21249 2.16241 2.16241C3.21249 1.11233 4.62743 0.508179 6.11212 0.475967C7.59681 0.443754 9.03661 0.985965 10.1313 1.98951C11.2259 2.99306 11.8908 4.38046 11.9874 5.86236C12.084 7.34426 11.6047 8.80623 10.6496 9.94336L13.3496 12.6434C13.3969 12.6899 13.4344 12.7453 13.46 12.8065C13.4856 12.8677 13.4988 12.9333 13.4988 12.9996C13.4988 13.0659 13.4856 13.1316 13.46 13.1927C13.4344 13.2539 13.3969 13.3093 13.3496 13.3559ZM6.24961 10.9996C7.18907 10.9996 8.10743 10.721 8.88857 10.1991C9.6697 9.67715 10.2785 8.9353 10.638 8.06736C10.9976 7.19941 11.0916 6.24434 10.9083 5.32293C10.7251 4.40152 10.2727 3.55515 9.60837 2.89085C8.94407 2.22655 8.0977 1.77416 7.17629 1.59088C6.25488 1.4076 5.29981 1.50166 4.43186 1.86118C3.56391 2.2207 2.82206 2.82952 2.30013 3.61065C1.77819 4.39178 1.49961 5.31015 1.49961 6.24961C1.50126 7.50888 2.00224 8.7161 2.89268 9.60654C3.78312 10.497 4.99034 10.998 6.24961 10.9996Z"
                  fill="var(--grey-icon)"
                  fillOpacity="0.5"
                />
              </svg>
              <input
                type="text"
                placeholder="Search by Non-Profit Name"
                value={npSearchQuery}
                onChange={handleNpSearchChange}
                className={`p-2 pl-8 border text-black border-gray-300 rounded-lg mb-4 w-full h-7 `}
              />
            </div>
          </div>

          {matchesMax430px ? (
            <table
              className={`${formClasses.table_styles_override} !w-full table-fixed !border-separate ${classes.table}`}
            >
              <thead>
                <tr>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Non-Profit Name
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    <></>
                  </th>
                  <th className="text-sm font-medium text-neutral-500 align-top">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItemsNpData.map((donation, index) => (
                  <tr
                    key={index}
                    className="bg-sky-50"
                    onClick={() => handleNpExpandRow(index)}
                  >
                    <td className="rounded-l-xl text-neutral-600 bg-sky-50 break-words">
                      {donation.nonprofit_org_name}

                      {npExpandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Date
                            <span className="text-neutral-600 !text-xs font-bold">
                              {donation.donation_date}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      {donation.animal_name}

                      {npExpandRow === index && (
                        <div className="flex justify-center mt-3">
                          <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                            Payment ID
                            <span className="text-neutral-600 !text-xs font-bold break-all">
                              {donation.pid}
                            </span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td className="text-neutral-600 text-center">
                      ${donation.amount}
                    </td>

                    {npExpandRow === index ? (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <svg
                          width="16"
                          height="9"
                          viewBox="0 0 16 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.999528 9L-3.47717e-07 7.95484L7.33176 0.287717C7.51087 0.103191 7.75054 -2.82587e-07 8 -2.74042e-07C8.24946 -2.65496e-07 8.48913 0.103191 8.66824 0.287717L16 7.95485L14.9995 9L8 1.68092L0.999528 9Z"
                            fill="#7F7F7F"
                          />
                        </svg>
                      </td>
                    ) : (
                      <td
                        className="bg-sky-50 mb-14 rounded-r-xl"
                        align="center"
                      >
                        <svg
                          width="16"
                          height="9"
                          viewBox="0 0 16 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.0005 1.21503e-07L16 1.04515L8.66824 8.71228C8.48913 8.89681 8.24946 9 8 9C7.75054 9 7.51087 8.89681 7.33176 8.71228L1.39656e-07 1.04515L1.00047 8.10373e-09L8 7.31908L15.0005 1.21503e-07Z"
                            fill="#7F7F7F"
                          />
                        </svg>
                      </td>
                    )}
                  </tr>
                ))}
                {filteredNpData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="4">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          ) : (
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className="text-neutral-500">Non-Profit Name</th>
                  <th className="text-neutral-500">Amount</th>
                  <th className="text-neutral-500">Date</th>
                  <th className="text-neutral-500">Payment ID</th>
                </tr>
              </thead>
              <tbody>
                {currentItemsNpData.map((donation, index) => (
                  <tr key={index} className="bg-sky-50">
                    <td className="rounded-l-xl text-neutral-600">
                      {donation.nonprofit_org_name}
                    </td>
                    <td className="text-neutral-600">${donation.amount}</td>
                    <td className="text-neutral-600">
                      {donation.donation_date}
                    </td>
                    <td className="rounded-r-xl text-neutral-600 break-all">
                      {donation.pid}
                    </td>
                  </tr>
                ))}
                {filteredNpData.length <= 0 ? (
                  <tr className="bg-sky-50">
                    <td colSpan="5">Sorry, no matching results!</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          )}

          <nav className="overflow-x-auto w-full mt-4 flex justify-end">
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  backgroundColor: "var(--light-grey)",
                  borderRadius: "0",
                },
                ".Mui-selected": {
                  backgroundColor: "var(--default-orange) !important",
                  borderRadius: "0",
                },
              }}
              count={totalPagesNpData}
              page={currentNpPage}
              onChange={handleNpChange}
              className="flex list-none justify-center"
              color="primary"
              renderItem={renderNpItem}
            />
          </nav>
        </div>
      )}
    </>
  );
}
