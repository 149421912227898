import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getEmailTemplates = async () => {
  try {
    const response = await axios.get(`${API_URL}/emails/template/`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Get email templates error:", error.response);
      throw error;
    }
  }
};
