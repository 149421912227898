import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMagnifyingGlass,
  faStar,
  faCat,
  faDog,
  faHorse,
  faOtter,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCampaigns } from "../../../api/Campaigns";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { extractTextContent } from "../../shared/Utils/ExtractText";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";
import classes from "./CampaignSearch.module.css";
import CampaignCard from "../CampaignCard/CampaignCard";

export const campaignExcerpt = (plainText) => {
  const EXCERPT_CHARS = 45;
  const EXCERPT_SENTENCES = 1;
  let sentences =
    plainText.split(".").slice(0, EXCERPT_SENTENCES).join(". ") + ".";
  return sentences.length > EXCERPT_CHARS
    ? sentences.substring(0, EXCERPT_CHARS) + "..."
    : sentences;
};

export default function CampaignSearch() {
  const [campaigns, setCampaigns] = useState([]);
  const [tag, setTag] = useState("");
  const [ageFilter, setAgeFilter] = useState("");
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState(
    searchParams.get("search") || ""
  );

  useEffect(() => {
    const getAllCampaigns = async () => {
      try {
        const response = await getCampaigns();

        setCampaigns(response.reverse());
      } catch (error) {
        console.error(`Could not get campaigns`, error);
      }
    };

    getAllCampaigns();
  }, []);

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    setSearchParams({ search: e.target.value });
  };
  const handleTagChange = (e) => {
    setTag(e.target.value);
  };
  const handleClick = (campaign) => {
    navigate(
      `/campaigns/${urlifyName(campaign.nonprofit_name)}/${urlifyName(
        campaign.animal_name
      )}/${campaign.id}`
    );
  };

  return (
    <div className={`main-div ${classes.campaign_search}`}>
      <h1>Find Pets in Need</h1>
      <div className={classes.filter_panel}>
        <div className={classes.tags_panel}>
          <label className={classes.tag_button}>
            <input
              className={classes.hide_radio_button}
              type="radio"
              name="species"
              id="species-all"
              value=""
              defaultChecked
              onChange={handleTagChange}
            />
            <FontAwesomeIcon icon={faStar} />
            <p>All</p>
          </label>
          <label className={classes.tag_button}>
            <input
              className={classes.hide_radio_button}
              type="radio"
              name="species"
              id="species-dog"
              value="dog"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon icon={faDog} />
            <p>Dogs</p>
          </label>
          <label className={classes.tag_button}>
            <input
              className={classes.hide_radio_button}
              type="radio"
              name="species"
              id="species-cat"
              value="cat"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon icon={faCat} />
            <p>Cats</p>
          </label>
          <label className={classes.tag_button}>
            <input
              className={classes.hide_radio_button}
              type="radio"
              name="species"
              id="species-farm"
              value="farm"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon icon={faHorse} />
            <p>Farm</p>
          </label>
          <label className={classes.tag_button}>
            <input
              className={classes.hide_radio_button}
              type="radio"
              name="species"
              id="species-exotic"
              value="exotic"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon icon={faOtter} />
            <p>Exotic</p>
          </label>
        </div>
        <div className={classes.dropdown_panel}>
          <select
            className={classes.age_dropdown}
            value={ageFilter}
            onChange={(e) => setAgeFilter(e.target.value)}
          >
            <option value="">All Ages</option>
            <option value="1">Newborn</option>
            <option value="2">Young</option>
            <option value="3">Adult</option>
            <option value="4">Senior</option>
            <option value="5">Unknown</option>
          </select>
        </div>
      </div>
      <div className={classes.filter_panel}>
        <div
          className={`${classes.search_panel}`}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <input
            type="text"
            placeholder="Type to search by name, city, or state"
            value={searchParam}
            onInput={handleSearch}
          />
        </div>
      </div>
      <div
        className={`${classes.campaign_search_card_container} ${classes.campaign_search_card_container_mobile}`}
      >
        {campaigns
          .filter((campaign) => {
            return (
              campaign.species.toLowerCase().includes(tag.toLowerCase()) &&
              (ageFilter === "" || campaign.age === parseInt(ageFilter)) &&
              (campaign.animal_name
                .toLowerCase()
                .includes(searchParam.toLowerCase()) ||
                campaign.nonprofit_address.city
                  .toLowerCase()
                  .includes(searchParam.toLowerCase()) ||
                campaign.nonprofit_address.state
                  .toLowerCase()
                  .includes(searchParam.toLowerCase()))
            );
          })
          .map((campaign) => {
            // make a check to see if we want to render this item or not
            if (
              !(
                campaign.species.toLowerCase().includes(tag.toLowerCase()) &&
                (campaign.animal_name
                  .toLowerCase()
                  .includes(searchParam.toLowerCase()) ||
                  campaign.nonprofit_address.city
                    .toLowerCase()
                    .includes(searchParam.toLowerCase()) ||
                  campaign.nonprofit_address.state
                    .toLowerCase()
                    .includes(searchParam.toLowerCase()))
              )
            )
              return "";

            // we have determined this card matches the search param. render it
            return (
              <CampaignCard
                key={campaign.id}
                campaign={campaign}
                handleClick={handleClick}
              />
            );
          })}
      </div>
    </div>
  );
}
