import React from "react";
import QRCode from "qrcode.react";
import classes from "./QRCodeModal.module.css";
import formClasses from "../DashboardForm.module.css";

function QRCodeModal({ isOpen, onClose, campaignUrl }) {
  if (!isOpen) return null;

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "CampaignQRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(campaignUrl).then(
      () => {
        alert("Campaign URL copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // Function to open Facebook share dialog
  const shareToFb = () => {
    const facebookUrl = `https://www.facebook.com/dialog/share?app_id=952019939706310&display=popup&href=${encodeURIComponent(
      campaignUrl
    )}&redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer`;
    window.open(facebookUrl, "_blank");
  };

  // Function to open Twitter share dialog
  const shareToX = () => {
    const text = `Check out our newest Animal Campaign at`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(campaignUrl)}`;
    window.open(twitterUrl, "_blank");
  };

  return (
    <div className={formClasses.modal_overlay}>
      <div className={classes.qr_modal_content}>
        <button onClick={onClose}>X</button>
        <div>
          <h1 className={classes.qr_modal_title}>Share</h1>
          <QRCode
            id="qrcode"
            value={campaignUrl}
            size={256}
            level={"H"}
            style={{ margin: "0 auto" }}
          />
          <div className={classes.qr_btn_container}>
            <button
              className={classes.download_qr_button}
              onClick={downloadQRCode}
            >
              Download QR Code
            </button>
            <button
              className={classes.copy_url_button}
              onClick={copyToClipboard}
            >
              Copy Campaign Link
            </button>
          </div>
          <div className={classes.qr_btn_container}>
            <button className={classes.download_qr_button} onClick={shareToFb}>
              Share to Facebook
            </button>
            <button className={classes.copy_url_button} onClick={shareToX}>
              Share to Twitter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRCodeModal;
