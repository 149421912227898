import { faMoneyBill, faTv, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./DashboardBanner.module.css";

export default function DashboardBanner({ donations, campaignsCreated }) {
  console.log(donations);
  console.log(campaignsCreated);
  const allDonations = donations.reduce(
    (acc, donation) => +acc + +donation.amount,
    0
  );
  const allCampaigns = campaignsCreated.reduce((acc, campaign) => acc + 1, 0);
  const allDonationsWithoutLeadingZeros = allDonations
    .toString()
    .replace(/^0+/, "");

  let testArr = [];
  let donors = 0;
  //To count how many individual donors there are, sorted by email
  for (let i = 0; i < donations.length; i++) {
    if (!testArr.includes(donations[i].donor_email)) {
      testArr.push(donations[i].donor_email);
      donors++;
    }
  }

  return (
    <div
      className={`bg-white rounded-[30px] border-black border-[0.5px] flex flex-row justify-center items-center p-6 ${classes.container} w-full my-4 mx-auto max-[1170px]:w-[90%] max-[1025px]:w-[95%]`}
    >
      <div
        className={`px-8 border-r-[#F0F0F0] border-r-2 flex flex-row max-[1025px]:px-4`}
      >
        <div className={`${classes.image_container} flex mr-4`}>
          <div className={`${classes.image} justify-center items-center`}>
            <FontAwesomeIcon icon={faMoneyBill} />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-[#ACACAC]">Donations</p>
          <p className="text-4xl font-bold mt-[-8px]">
            ${allDonationsWithoutLeadingZeros}
          </p>
        </div>
      </div>
      <div
        className={`px-8 border-r-[#F0F0F0] border-r-2 flex flex-row max-[1025px]:px-4`}
      >
        <div className={`${classes.image_container} flex mr-4`}>
          <div className={`${classes.image} justify-center items-center`}>
            <FontAwesomeIcon icon={faUser} />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-[#ACACAC]">Donors Added</p>
          <p className="text-4xl font-bold mt-[-8px]">{donors}</p>
        </div>
      </div>
      <div
        className={`px-8 border-r-[#F0F0F0] flex flex-row max-[1025px]:px-4`}
      >
        <div className={`${classes.image_container} flex mr-4`}>
          <div className={`${classes.image} justify-center items-center`}>
            <FontAwesomeIcon icon={faTv} />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-[#ACACAC]">Campaigns Created</p>
          <p className="text-4xl font-bold mt-[-8px]">{allCampaigns}</p>
        </div>
      </div>
    </div>
  );
}
