import { useParams } from "react-router";
import { getNonprofit } from "../../../api/Nonprofits";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { useNavigate } from "react-router-dom";
import { faArrowRight, faGlobe, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getCampaigns } from "../../../api/Campaigns";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareInstagram,
  faSquareFacebook,
  faTiktok,
  faAmazon,
} from "@fortawesome/free-brands-svg-icons";
import { fetchBlogs } from "../../../api/FetchBlogs";
import chewyVector from "../../../assets/chewy_vector.svg";
import BlogCard from "../../Blog/BlogSearch/BlogCard/BlogCard";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import NotFound from "../../shared/Utils/NotFound";
import classes from "./NonprofitFeature.module.css";
import CampaignCard from "../../Campaigns/CampaignCard/CampaignCard";
import {
  generateNPDonation,
  generateNPSubscription,
} from "../../../api/GenerateDonation";
import {
  NPDonationSuccess,
  NPSubscriptionSuccess,
} from "../../../api/DonationSuccess";
import SuccessModal from "../../Campaigns/CampaignFeature/SuccessModal/SuccessModal";
import LoginSignupModal from "../../shared/LoginSignupModal/LoginSignupModal";
import { useSelector } from "react-redux";

export default function NonprofitFeature() {
  const { org_name, nonprofit_id } = useParams(); // Access the id parameter using useParams hook
  const [nonprofit, setNonprofit] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [successStories, setSuccessStories] = useState([]);
  const [error, setError] = useState(null);
  const [coverFee, setCoverFee] = useState(false);
  const [prevAmount, setPrevAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [donationAmount, setDonationAmount] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [donationId, setDonationId] = useState(0);
  const [stripe, setStripe] = useState(null);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [elements, setElements] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoginSignupModalOpen, setLoginSignupModalOpenModalOpen] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [ifReadMore, setIfReadMore] = useState(false);
  const [npStatement, setNpStatement] = useState([]);
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [donateClicked, setDonateClicked] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const userData = useSelector((state) => state.userData.userData);

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    const fetchNonprofit = async () => {
      const response = await getNonprofit(nonprofit_id, org_name);

      return response;
    };
    const fetchCampaigns = async () => {
      const response = await getCampaigns();
      return response.filter(
        (campaign) => campaign.nonprofit === parseInt(nonprofit_id)
      );
    };
    const fetchSuccessStories = async (paramNonprofit) => {
      if (!paramNonprofit) {
        return [];
      }
      const response = await fetchBlogs(paramNonprofit);
      return response.filter((blog) =>
        paramNonprofit.success_stories.includes(blog.id)
      );
    };
    const fetchAll = async () => {
      const tempNonprofit = await fetchNonprofit();
      const tempCampaigns = await fetchCampaigns();
      const tempSuccessStories = await fetchSuccessStories(tempNonprofit);

      // Getting only the inner text of HTML (quill attaches <p> tag to the statement)
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = tempNonprofit.mission_stmt;
      const text = tempDiv.textContent || tempDiv.innerText || "";

      const statement = parseDescription(text);
      const sentences = getStatementArray(statement);
      setNpStatement(sentences);

      setNonprofit(tempNonprofit);
      console.log(tempNonprofit);
      setCampaigns(tempCampaigns.reverse());
      setSuccessStories(tempSuccessStories);
    };
    try {
      fetchAll();
    } catch (error) {
      console.error(error);
    }
  }, [nonprofit_id, org_name]);

  // scroll to top on load of this component:
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const npExcerpt = (plainText) => {
    let excerpt = plainText.substring(0, 50);
    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";
    return excerpt;
  };

  const handleOptionClick = (amount) => {
    setShowCustomAmount(false);

    if (customAmount) {
      setCoverFee(false);
      setCustomAmount("");
    }

    if (selectedOption === amount) {
      setSelectedOption(null);
      setPrevAmount(0);
      setDonationAmount(0);
    } else {
      setSelectedOption(amount);
      setPrevAmount(amount);
      setDonationAmount(amount);
      setCoverFee(false);
    }
  };

  const handleCoverFeeChange = (e) => {
    setCoverFee(e.target.checked);
    const fee = parseFloat((prevAmount * 0.05).toFixed(2));
    if (e.target.checked) {
      setDonationAmount(Number(prevAmount) + fee);
    } else {
      setDonationAmount(Number(prevAmount));
    }
  };

  const handleInputChange = (e) => {
    setPrevAmount(e.target.value);
    setCustomAmount(e.target.value);
    setDonationAmount(parseFloat(e.target.value));
    if (selectedOption) {
      setSelectedOption(null);
      setCoverFee(false);
    }
  };

  const handleDonationSubmit = async (event) => {
    event.preventDefault();

    let amountToDonate = selectedOption || parseFloat(customAmount);

    if (coverFee) {
      amountToDonate += parseFloat((amountToDonate * 0.03).toFixed(2));
    }

    setDonationAmount(amountToDonate);

    const initializePayment = async (nonprofit) => {
      if (!nonprofit) return;

      const data = {
        user: localUserData.id,
        nonprofit: nonprofit_id,
        amount: amountToDonate,
      };
      try {
        let response;
        if (selectedTab === 0) {
          // ONE-TIME DONATION
          response = await generateNPDonation(data);
        } else if (selectedTab === 1) {
          // MONTHLY DONATION
          response = await generateNPSubscription(data);
        }
        console.log(response);
        setClientSecret(response.client_secret);
        setDonationId(response.donation_id);
        setDonateClicked(true);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    initializePayment(nonprofit);
  };

  useEffect(() => {
    if (clientSecret) {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
      const appearance = {
        /* appearance */
      };
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const elementsInstance = stripeInstance.elements({
        clientSecret,
        appearance,
      });
      const cardElement = elementsInstance.create("payment", options);
      cardElement.mount("#payment-element");

      cardElement.on("ready", () => {
        setIsStripeLoading(false);
      });

      setStripe(stripeInstance);
      setElements(elementsInstance);
    }
  }, [clientSecret]);

  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentResponse("Payment Element is not loaded.");
      return;
    }

    setIsStripeLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentResponse(error.message);
      } else {
        if (paymentIntent && paymentIntent.status === "succeeded") {
          setPaymentResponse({ status: "success" });
          setShowModal(true);
          if (selectedTab === 0) {
            //ONE-TIME DONATION
            await NPDonationSuccess({ donation_id: donationId });
          } else if (selectedTab === 1) {
            //MONTHLY DONATION
            await NPSubscriptionSuccess({ donation_id: donationId });
          }
        } else {
          setPaymentResponse({ error: error });
        }
      }
    } catch (error) {
      setPaymentResponse("An error occurred during payment.");
      console.error(error);
    } finally {
      setIsStripeLoading(false);
    }
  };

  const handleClick = (item) => {
    navigate(
      `/campaigns/${urlifyName(nonprofit.org_name)}/${urlifyName(
        item.animal_name
      )}/${item.id}`
    );
  };

  const toTitleCase = (str) => {
    if (!str) return "";
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const openLoginSignupModal = (type) => {
    setLoginOrSignup(type);
    setLoginSignupModalOpenModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeLoginSignupModal = () => {
    setLoginSignupModalOpenModalOpen(false);
    document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  const handleButtonClick = () => {
    setSelectedOption(null);
    setPrevAmount(0);
    setDonationAmount(0);
    setCoverFee(false);
    setShowCustomAmount(!showCustomAmount);
  };

  // Videos
  const handlePrevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? nonprofit.video_urls.split(",").length - 1 : prevIndex - 1
    );
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === nonprofit.video_urls.split(",").length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentVideoIndex(index);
  };

  if (error) {
    return <NotFound />;
  }

  // Handles switching from 'Read more' to 'Read less'
  const handleReadMoreButton = () => {
    return ifReadMore ? setIfReadMore(false) : setIfReadMore(true);
  };

  // Splitting NP mission statement into an array of sentences
  const getStatementArray = (text) => {
    return text.match(/[^.!?]+[.!?]+[\s]*/g) || [];
  };

  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
    setShowCustomAmount(false);
    setCoverFee(false);
    setCustomAmount("");
    setSelectedOption(null);
    setPrevAmount(0);
    setDonationAmount(0);
  };

  return (
    <div className={`${classes.nonprofit_feature_page} main-div`}>
      {nonprofit ? (
        <div className={`${classes.nonprofit_feature} main-div`}>
          <div className={classes.nonprofit_header_container}>
            <img src={nonprofit.logo} alt="Company Logo" />
            <div className={classes.nonprofit_description_container}>
              <h4 className="text-gray-600">
                {toTitleCase(nonprofit.address.city)}, {nonprofit.address.state}
              </h4>
              <h1>{nonprofit.org_name}</h1>
              <h2 className="text-xl text-blue font-bold">Mission Statement</h2>

              {npStatement.length > 3 ? (
                <>
                  <p>{ifReadMore ? npStatement : npStatement.slice(0, 3)}</p>
                  <button
                    className={`${classes.read_more_button}`}
                    onClick={() => handleReadMoreButton()}
                  >
                    {ifReadMore ? "Read less.." : "Read more..."}
                  </button>
                </>
              ) : (
                <p>{npStatement}</p>
              )}

              <div className={classes.social_icon_container}>
                {nonprofit.instagram_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.instagram_url}
                  >
                    <FontAwesomeIcon
                      icon={faSquareInstagram}
                      className="icon"
                    />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.facebook_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.facebook_url}
                  >
                    <FontAwesomeIcon icon={faSquareFacebook} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.tiktok_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.tiktok_url}
                  >
                    <FontAwesomeIcon icon={faTiktok} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.amazon_wishlist_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.amazon_wishlist_url}
                  >
                    <FontAwesomeIcon icon={faAmazon} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.website_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={nonprofit.website_url}
                  >
                    <FontAwesomeIcon icon={faGlobe} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {nonprofit.chewy_url ? (
                  <a
                    href={nonprofit.chewy_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="flex w-[10%] h-[95%]"
                      alt=""
                      src={chewyVector}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>

              <p>EIN: {nonprofit.ein_num}</p>
              <div
                className={`border rounded-2xl -ml-1 my-8 p-4 shadow-lg ${
                  donateClicked ? "max-w-[330px]" : "max-w-[590px]"
                }`}
              >
                <h2 className="mb-4 text-xl text-blue font-bold">
                  Support Our Cause
                </h2>

                {!donateClicked && (
                  <div>
                    <button
                      className={`mr-4 py-2 font-semibold ${
                        selectedTab === 0
                          ? "text-blue border-b-2 border-blue"
                          : "text-gray-500 hover:text-blue"
                      }`}
                      onClick={() => handleTabChange(0)}
                    >
                      One-Time Donation
                    </button>
                    {nonprofit.price_ids && nonprofit.price_ids.length > 0 && (
                      <button
                        className={`py-2 font-semibold ${
                          selectedTab === 1
                            ? "text-blue border-b-2 border-blue"
                            : "text-gray-500 hover:text-blue"
                        }`}
                        onClick={() => handleTabChange(1)}
                      >
                        Monthly Donation
                      </button>
                    )}
                  </div>
                )}

                {paymentResponse && paymentResponse.status && showModal ? (
                  <SuccessModal
                    message="Payment succeeded!"
                    onClose={closeModal}
                    showModal={showModal}
                  />
                ) : (
                  ""
                )}
                <div>
                  {clientSecret ? (
                    <form
                      id="payment-form"
                      className={classes.payment_form}
                      onSubmit={handleSubmitPayment}
                    >
                      <div id="payment-element"></div>
                        <button className={classes.submit_btn} type="submit">
                          Complete Donation
                        </button>
                    </form>
                  ) : localUserData ? (
                    <>
                      <h2 className="mt-2 !text-gray">
                        {" "}
                        Choose a {selectedTab === 0
                          ? "One-Time"
                          : "Monthly"}{" "}
                        Donation Amount
                      </h2>
                      <div className="flex flex-row gap-6 my-2">
                        {[10, 25, 50, 100].map((amount) => (
                          <button
                            key={amount}
                            className={`${
                              selectedOption === amount
                                ? "!bg-orange !text-white"
                                : "hover:bg-gray-200"
                            } border font-semibold rounded-2xl text-[#0000008F] py-2.5 px-7`}
                            type="button"
                            onClick={() => handleOptionClick(amount)}
                            style={{ border: "1px solid #0000004F" }}
                          >
                            ${amount}
                          </button>
                        ))}
                        <button
                          className={`${
                            showCustomAmount ? "!bg-orange !text-white" : "hover:bg-gray-200"
                          } border font-semibold rounded-2xl text-[#0000008F] py-2.5 px-7`}
                          onClick={handleButtonClick}
                          style={{ border: "1px solid #0000004F" }}
                        >
                          Other
                        </button>
                      </div>
                      {showCustomAmount && (
                        <input
                          type="number"
                          id="donation-amount"
                          name="donation-amount"
                          placeholder="Custom donation amount"
                          className="w-[20%] h-7 text-[0.95rem] my-auto rounded-lg border border-gray-400 mb-2"
                          value={customAmount}
                          onChange={handleInputChange}
                        />
                      )}
                      <form
                        id={classes.amount_form}
                        className="w-full no-underline"
                        onSubmit={handleDonationSubmit}
                      >
                        <div className="flex flex-row gap-2">
                          {selectedTab === 0 && (
                            <>
                              <label htmlFor="coverFee" className="text-sm">
                                Would you like to cover the transaction fee of
                                ($
                                {(prevAmount * 0.03).toFixed(2)})?
                              </label>
                              <div className="-mt-4">
                                <input
                                  type="checkbox"
                                  id="coverFee"
                                  checked={coverFee}
                                  onChange={handleCoverFeeChange}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <button
                          id="submit-amount"
                          className={`bg-orange hover:bg-orange-dark1 font-semibold text-white rounded-3xl w-1/5 h-[3rem] ${
                            selectedTab === 0 ? "-mt-3" : "mt-2"
                          }`}
                        >
                          Donate
                        </button>
                      </form>
                    </>
                  ) : (
                    <div>
                      <h4 className={`mb-9`}>
                        {isLoginSignupModalOpen ? (
                          loginOrSignup === "login" ? (
                            <LoginSignupModal
                              loginOrSignup={"login"}
                              isOpen={openLoginSignupModal}
                              onClose={closeLoginSignupModal}
                              onFormSuccess={handleFormSuccess}
                            />
                          ) : (
                            <LoginSignupModal
                              loginOrSignup={"signup"}
                              isOpen={openLoginSignupModal}
                              onClose={closeLoginSignupModal}
                              onFormSuccess={handleFormSuccess}
                            />
                          )
                        ) : (
                          ""
                        )}
                        <button
                          className="underline text-orange"
                          onClick={() => openLoginSignupModal("signup")}
                        >
                          Sign Up
                        </button>{" "}
                        or{" "}
                        <button
                          className="underline text-orange"
                          onClick={() => openLoginSignupModal("login")}
                        >
                          login
                        </button>{" "}
                        to donate
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Get to Know Us Section */}
          {nonprofit.video_urls && nonprofit.video_urls.length > 0 && (
            <div className="mt-10 flex flex-col items-center">
              <h2 className="text-3xl text-orange font-bold text-center mb-6">
                Get to Know Us
              </h2>
              <div className="relative w-full max-w-2xl">
                {/* Arrows for larger screens */}
                <div className="hidden sm:flex absolute inset-y-0 left-0 items-center -ml-20">
                  <button className="p-2 text-orange hover:text-orange-dark1" onClick={handlePrevVideo}>
                    <FontAwesomeIcon icon={faArrowLeft} className="w-6 h-6" />
                  </button>
                </div>
                <div className="hidden sm:flex absolute inset-y-0 right-0 items-center -mr-20">
                  <button className="p-2 text-orange hover:text-orange-dark1" onClick={handleNextVideo}>
                    <FontAwesomeIcon icon={faArrowRight} className="w-6 h-6" />
                  </button>
                </div>
                
                <div className="flex justify-center">
                  <iframe
                    src={nonprofit.video_urls.split(",")[currentVideoIndex]}
                    className="w-full h-80 border-2 border-blue rounded-md"
                    allowFullScreen
                    title={`Nonprofit Video ${currentVideoIndex + 1}`}
                  ></iframe>
                </div>
                
                {/* Arrows for mobile screens */}
                <div className="flex sm:hidden justify-between mt-4 w-full">
                  <button className="p-2 text-orange" onClick={handlePrevVideo}>
                    <FontAwesomeIcon icon={faArrowLeft} className="w-6 h-6" />
                  </button>
                  <button className="p-2 text-orange" onClick={handleNextVideo}>
                    <FontAwesomeIcon icon={faArrowRight} className="w-6 h-6" />
                  </button>
                </div>
              </div>
              
              {/* Selection Dots */}
              <div className="flex justify-center mt-4 space-x-2">
                {nonprofit.video_urls.split(",").map((_, index) => (
                  <div
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`w-3 h-3 rounded-full cursor-pointer ${
                      index === currentVideoIndex ? "bg-orange" : "bg-gray-200"
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          )}
              
          <div className={classes.np_campaign_container}>
            {campaigns.length > 0 ? (
              <>
                <h2 className="text-3xl text-orange font-bold">
                  {nonprofit.org_name}'s Animal Campaigns
                </h2>
                <div className={classes.campaigns_scrolling_container}>
                  {campaigns.map((campaign) => {
                    return (
                      <CampaignCard
                        key={campaign.id}
                        campaign={campaign}
                        handleClick={handleClick}
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}
            {successStories.length > 0 ? (
              <>
                <h2 className="text-3xl text-orange font-bold">
                  {nonprofit.org_name}'s Success Stories
                </h2>
                <div className={classes.campaigns_scrolling_container}>
                  {successStories.map((blog) => (
                    <BlogCard key={blog.id} {...blog} />
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        "404 No nonprofit found"
      )}
    </div>
  );
}
