import { useEffect, useState } from "react";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMagnifyingGlass,
  faStar as faSolidStar,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getNonprofits } from "../../../api/Nonprofits";
import { addFavorite, removeFavorite } from "../../../api/Favorite";
import { dashboard } from "../../../api/Dashboard";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";
import classes from "./NonprofitSearch.module.css";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import { useSelector } from "react-redux";

export default function NonprofitSearch() {
  const [nonprofits, setNonprofits] = useState([]);
  const [filteredNonprofits, setFilteredNonprofits] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState(
    searchParams.get("search") || ""
  );
  const [localUserData, setLocalUserData] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  const npExcerpt = (plainText) => {
    let excerpt = plainText.substring(0, 50);
    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";
    return excerpt;
  };

  // getting list of partners from api
  useEffect(() => {
    try {
      getNonprofits().then((response) => {
        setNonprofits(response.reverse()); // Reverse the nonprofits array here
      });
    } catch (error) {
      console.error(`Could not retrieve list of partners`);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);


  // Filter nonprofits based on the search parameter
  useEffect(() => {
    if (searchParam) {
      const filtered = nonprofits.filter((item) => {
        return (
          item.address.state
            .toLowerCase()
            .includes(searchParam.toLowerCase()) ||
          item.org_name.toLowerCase().includes(searchParam.toLowerCase())
        );
      });
      setFilteredNonprofits(filtered);
    } else {
      setFilteredNonprofits(nonprofits);
    }
  }, [searchParam, nonprofits]);

  // creating input callback for SEARCH bar
  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    setSearchParams({ search: e.target.value });
    // navigate(`/search?location=${encodeURIComponent(searchParam)}`);
  };

  const handleAddFavorite = async (id) => {
    if (!localUserData) return;
    try {
      await addFavorite({ nonprofit_id: id });
      const i = localUserData.favorite_nonprofits.indexOf(id);
      if (i < 0) {
        const newFavorites = [...localUserData.favorite_nonprofits, id];
        setLocalUserData({
          ...localUserData,
          favorite_nonprofits: newFavorites,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveFavorite = async (id) => {
    if (!localUserData) return;
    try {
      await removeFavorite({ nonprofit_id: id });
      const i = localUserData.favorite_nonprofits.indexOf(id);
      if (i >= 0) {
        const newFavorites = localUserData.favorite_nonprofits.filter(
          (favId) => favId !== id
        );
        setLocalUserData({
          ...localUserData,
          favorite_nonprofits: newFavorites,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStarClick = (event, isFavorite, id) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFavorite) {
      handleRemoveFavorite(id);
    } else {
      handleAddFavorite(id);
    }
  };

  return (
    <div className={`main-div ${classes.nonprofit_search}`}>
      <h1>Non-Profits</h1>
      <div className={classes.search_panel}>
        <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
        <input
          type="text"
          placeholder="Type to search by name or location"
          onInput={handleSearch}
          value={searchParam}
        />
      </div>
      <div className={classes.nonprofit_search_card_container}>
        {filteredNonprofits.map((item, index) => (
          <Link
            to={`/non-profit/${urlifyName(item.org_name)}/${item.id}`}
            key={index}
          >
            <div className={classes.nonprofit_search_card} key={item.org_name}>
              <div className={classes.nonprofit_search_card_header}>
                {item.address
                  ? `${item.address.city}, ${item.address.state}`
                  : ""}
                {localUserData ? (
                  localUserData.favorite_nonprofits.includes(item.id) ? (
                    <FontAwesomeIcon
                      icon={faSolidStar}
                      onClick={(e) => handleStarClick(e, true, item.id)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faRegularStar}
                      onClick={(e) => handleStarClick(e, false, item.id)}
                    />
                  )
                ) : null}
              </div>
              {item.logo ? (
                <img src={item.logo} alt={`Logo of ${item.org_name}`} />
              ) : null}
              <div className={classes.cardContent}>
                <h3 className="font-semibold text-2xl">
                  {cardTitleExcerpt(item.org_name, 35)}
                </h3>
                {parseDescription === item.mission_stmt ? (
                  <p>{npExcerpt(item.mission_stmt)}</p>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: npExcerpt(parseDescription(item.mission_stmt)),
                    }}
                  />
                )}
                <FontAwesomeIcon
                  className={classes.arrowIcon}
                  icon={faArrowRight}
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
