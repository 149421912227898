import { useState, useEffect } from "react";
import { contactUs } from "../../../api/ContactUs";
import { validateEmail } from "../../shared/Utils/ValidateEmail";
import classes from "./ContactModal.module.css";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import formClasses from "../DashboardForm.module.css";

export default function ContactModal({ closeModal }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [feError, setFEError] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const contactUsCall = async (formData) => {
    try {
      const response = await contactUs(formData);
      setSuccess("Your message has been sent successfully!");
      setError("");
    } catch (error) {
      let errorStr = errorHandler(error);
      const errorMessageWithBreaks = lineBreaks(errorStr);
      setError(errorMessageWithBreaks);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setError("");

    // Validation for the name length
    if (formData.name.length > 25) {
      setFEError("Name must be under 25 characters.");
      setSuccess("");
      return; // Stop further execution if validation fails
    }

    // Validation for the email format
    if (!validateEmail(formData.email)) {
      setFEError("Please enter a valid email address.");
      setSuccess("");
      return; // Stop further execution if validation fails
    }

    setFEError("");

    // If all validations pass, make the API call
    contactUsCall(formData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={formClasses.modal_overlay}>
      <div
        className={`${formClasses.modal} !border !border-[#00000080] !rounded-3xl`}
      >
        <div
          className={`${classes.main_div} ${classes.contact_form_container}`}
        >
          <h1>Contact Us</h1>
          <button onClick={closeModal} className={classes.close_button}>
            x
          </button>
          <form className={classes.contact_us_form} onSubmit={handleSubmit}>
            <div className={classes.input_group}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
              />
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
            />
            <textarea
              type="text"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
            />
            <GeneralButton colour="blue" type="submit">
              Send
            </GeneralButton>
            {feError && (
              <div className="text-[var(--default-red)]">{feError}</div>
            )}
            {error && <div className="text-[var(--default-red)]">{error}</div>}
            {success && <div>{success}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}
