import React, { useState } from "react";
import { createNonprofit } from "../../../api/CreateNonprofit";
import { useNavigate } from "react-router-dom";
import TermsModal from "../TermsModal/TermsModal";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import Quill from "quill";
import Editor from "../CampaignModal/Editor/Editor";
import classes from "./NonprofitCreate.module.css";

const Delta = Quill.import("delta");

function NonprofitCreate() {
  const MAX_SIZE = 3 * 1024 * 1024;
  const [formData, setFormData] = useState({
    org_name: "",
    ein_num: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    mission_stmt: "",
    logo: null, // For file input
    instagram_url: "",
    facebook_url: "",
    website_url: "",
    amazon_wishlist_url: "",
    chewy_url: "",
    tiktok_url: "",
  });
  const [urlErrors, setUrlErrors] = useState({
    instagram_url: "",
    facebook_url: "",
    website_url: "",
    amazon_wishlist_url: "",
    chewy_url: "",
    tiktok_url: "",
  });
  const [createError, setCreateError] = useState("");
  const [tosAccepted, setTosAccepted] = useState(false);
  const [showTosModal, setShowTosModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateUrl = (url) => {
    // Regex to check for a valid URL with paths
    const pattern =
      /^https:\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z0-9-]+)?(?:\/[^\s]*)?$/;
    return url && pattern.test(url);
  };

  const addHttps = (url) => {
    if (url && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    return url;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "logo") {
      const file = e.target.files[0];
      if (file) {
        if (file.size > MAX_SIZE) {
          setCreateError("File size is too large (must be under 3MB)");
          e.target.value = "";
          setFormData((prevState) => ({
            ...prevState,
            [name]: null,
          }));
        } else {
          setCreateError("");
          setFormData((prevState) => ({
            ...prevState,
            [name]: file,
          }));
        }
      }
    } else if (
      name === "instagram_url" ||
      name === "facebook_url" ||
      name === "website_url" ||
      name === "amazon_wishlist_url" ||
      name === "tiktok_url" ||
      name === "chewy_url"
    ) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value.trim(),
      }));
      if (value.trim() === "") {
        setUrlErrors((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      } else {
        const validationResponse = validateUrl(addHttps(value.trim()));
        setUrlErrors((prevState) => ({
          ...prevState,
          [name]: validationResponse
            ? ""
            : "Invalid URL format. Expected format: https://www.example.com",
        }));
      }
    } else if (name === "phone") {
      if (value.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setFormData((prevState) => ({
      ...prevState,
      mission_stmt: content, // Set description as HTML content
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    // Create a FormData object to send the file along with the rest of the form data
    const submitData = new FormData();

    // Append the logo file to the FormData object, if present
    if (formData.logo) {
      submitData.append("logo", formData.logo);
    }

    // Append other form fields to the FormData object
    submitData.append("org_name", formData.org_name);
    submitData.append("ein_num", formData.ein_num);

    // Construct the address JSON object and append it as a string
    const addressObject = {
      street: formData.street,
      city: formData.city,
      state: formData.state,
      zip_code: formData.zip_code,
      country: formData.country,
    };
    submitData.append("address", JSON.stringify(addressObject));
    submitData.append("phone", formData.phone);
    submitData.append("mission_stmt", formData.mission_stmt);

    // Correct URLs by adding https:// if not present and only if they are not empty
    const correctUrl = (url) => {
      if (url && !url.startsWith("https://") && !url.startsWith("http://")) {
        return "https://" + url;
      }
      return url;
    };

    if (formData.instagram_url) {
      submitData.append("instagram_url", correctUrl(formData.instagram_url));
    }
    if (formData.facebook_url) {
      submitData.append("facebook_url", correctUrl(formData.facebook_url));
    }
    if (formData.website_url) {
      submitData.append("website_url", correctUrl(formData.website_url));
    }
    if (formData.amazon_wishlist_url) {
      submitData.append(
        "amazon_wishlist_url",
        correctUrl(formData.amazon_wishlist_url)
      );
    }
    if (formData.chewy_url) {
      submitData.append("chewy_url", correctUrl(formData.chewy_url));
    }
    if (formData.tiktok_url) {
      submitData.append("tiktok_url", correctUrl(formData.tiktok_url));
    }
    submitData.append("tos", true);

    try {
      const data = await createNonprofit(submitData);
      navigate("/profile");
    } catch (error) {
      const errorMessage = errorHandler(error);
      const formattedError = lineBreaks(errorMessage);
      setCreateError(formattedError);
      console.error("Error during nonprofit creation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle TOS modal visibility
  const toggleTosModal = () => {
    setShowTosModal(!showTosModal);
  };

  // Handle TOS acceptance
  const handleAcceptTos = () => {
    setTosAccepted(true);
    setShowTosModal(!showTosModal);
  };

  return (
    <div className={`main-div ${classes.nonprofit_create_page}`}>
      <form
        className={classes.nonprofit_create_card}
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <h2 className="my-4 text-3xl text-orange font-semibold">
          Create Nonprofit
        </h2>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Organization Name (E.g. Pets){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="text"
              name="org_name"
              value={formData.org_name}
              onChange={handleChange}
              placeholder="E.g. Sponsor A Pet"
              required
            />
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              EIN Number (E.g. 123456789){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="number"
              name="ein_num"
              value={formData.ein_num}
              onChange={handleChange}
              placeholder="E.g. 123456789"
              required
            />
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Street (E.g. 188 King St.){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="text"
              name="street"
              value={formData.street}
              onChange={handleChange}
              placeholder="E.g. 188 King St."
            />
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              City (E.g. San Francisco){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="E.g. San Francisco"
            />
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              State <span className="text-[--default-red]">*</span>
            </p>
            <select
              name="state"
              value={formData.state}
              onChange={handleChange}
              placeholder="E.g. CA"
              className="border p-2 py-2.5 rounded-md text-grey"
            >
              <option value="" disabled>
                Select State
              </option>
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NE">NE</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="WA">WA</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
            </select>
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Zip Code (E.g. 94107){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="number"
              name="zip_code"
              value={formData.zip_code}
              onChange={handleChange}
              placeholder="E.g. 94107"
            />
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Country <span className="text-[--default-red]">*</span>
            </p>
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="border p-2 py-2.5 rounded-md text-grey"
            >
              <option value="" disabled>
                Select Country
              </option>
              <option value="US">US</option>
            </select>
          </div>
          <div className={classes.form_tab_col}>
            <p className={classes.input_example}>
              Phone (E.g. 1234567890){" "}
              <span className="text-[--default-red]">*</span>
            </p>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="E.g. 1234567890"
              required
            />
          </div>
        </div>
        <div className={classes.form_tab_col}>
          <p className={classes.input_example}>
            Mission Statement <span className="text-[--default-red]">*</span>
          </p>
          <Editor
            defaultValue={formData.mission_stmt}
            onTextChange={handleEditorChange}
            className={classes.ql_container}
          />
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>Instagram URL</p>
              <SharedTooltip
                id="my-tooltip"
                content="Example URL: (www.example.com or https://www.example.com)"
                aClass="ml-2 mb-0.5 cursor-pointer"
                toolClass="ml-1.5 mb-1"
              />
            </div>
            <input
              type="text"
              name="instagram_url"
              value={formData.instagram_url}
              onChange={handleChange}
              placeholder="E.g. https://www.instagram.com/sap"
            />
            {urlErrors.instagram_url && (
              <div className={classes.create_error}>
                {urlErrors.instagram_url}
              </div>
            )}
          </div>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>Facebook URL</p>
              <SharedTooltip
                id="my-tooltip"
                content="Example URL: (www.example.com or https://www.example.com)"
                aClass="ml-2 mb-0.5 cursor-pointer"
                toolClass="ml-1.5 mb-1"
              />
            </div>
            <input
              type="text"
              name="facebook_url"
              value={formData.facebook_url}
              onChange={handleChange}
              placeholder="E.g. https://www.facebook.com/sap"
            />
            {urlErrors.facebook_url && (
              <div className={classes.create_error}>
                {urlErrors.facebook_url}
              </div>
            )}
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>Website URL</p>
              <SharedTooltip
                id="my-tooltip"
                content="Example URL: (www.example.com or https://www.example.com)"
                aClass="ml-2 mb-0.5 cursor-pointer"
                toolClass="ml-1.5 mb-1"
              />
            </div>
            <input
              type="text"
              name="website_url"
              value={formData.website_url}
              onChange={handleChange}
              placeholder="E.g. https://www.sponsorapet.org"
            />
            {urlErrors.website_url && (
              <div className={classes.create_error}>
                {urlErrors.website_url}
              </div>
            )}
          </div>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>Amazon Wishlist URL</p>
              <SharedTooltip
                id="my-tooltip"
                content="Example URL: (www.example.com or https://www.example.com)"
                aClass="ml-2 mb-0.5 cursor-pointer"
                toolClass="ml-1.5 mb-1"
              />
            </div>
            <input
              type="text"
              name="amazon_wishlist_url"
              value={formData.amazon_wishlist_url}
              onChange={handleChange}
              placeholder=" E.g. https://www.amazon.com/wishlist"
            />
            {urlErrors.amazon_wishlist_url && (
              <div className="create-error">
                {urlErrors.amazon_wishlist_url}
              </div>
            )}
          </div>
        </div>
        <div className={classes.form_row}>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>TikTok URL</p>
              <SharedTooltip
                id="my-tooltip"
                content="Example URL: (www.example.com or https://www.example.com)"
                aClass="ml-2 mb-0.5 cursor-pointer"
                toolClass="ml-1.5 mb-1"
              />
            </div>
            <input
              type="text"
              name="tiktok_url"
              value={formData.tiktok_url}
              onChange={handleChange}
              placeholder="E.g. https://www.tiktok.com/sap"
            />
            {urlErrors.tiktok_url && (
              <div className={classes.create_error}>{urlErrors.tiktok_url}</div>
            )}
          </div>
          <div className={classes.form_tab_col}>
            <div className="flex items-center">
              <p className={classes.input_example}>Chewy URL</p>
              <SharedTooltip
                id="my-tooltip"
                content="Example URL: (www.example.com or https://www.example.com)"
                aClass="ml-2 mb-0.5 cursor-pointer"
                toolClass="ml-1.5 mb-1"
              />
            </div>
            <input
              type="text"
              name="chewy_url"
              value={formData.chewy_url}
              onChange={handleChange}
              placeholder="E.g. https://www.chewy.com/sap"
            />
            {urlErrors.chewy_url && (
              <div className={classes.create_error}>{urlErrors.chewy_url}</div>
            )}
          </div>
        </div>

        <p className="mt-0">
          Organization Logo <span className="text-[--default-red]">*</span>
        </p>
        <input
          type="file"
          name="logo"
          onChange={handleChange}
          placeholder="Logo"
          className="text-grey"
        />

        <GeneralButton
          colour="blue"
          className={`${classes.tos_button} ${
            tosAccepted ? "!bg-green-500" : ""
          }`}
          type="button"
          onClick={toggleTosModal}
        >
          Terms of Service <span className="text-[--default-red]">*</span>
        </GeneralButton>
        {createError && (
          <div className={classes.create_error}>{createError}</div>
        )}
        <GeneralButton
          type="submit"
          colour="blue"
          disabled={
            !formData.logo ||
            formData.logo.size > MAX_SIZE ||
            !tosAccepted ||
            isLoading
          }
        >
          Create
        </GeneralButton>
      </form>
      {showTosModal && (
        <TermsModal
          isOpen={showTosModal}
          onAccept={handleAcceptTos}
          onClose={toggleTosModal}
        />
      )}
    </div>
  );
}

export default NonprofitCreate;
