import classes from "./CampaignUpdatesBlock.module.css";

function parseTimestamp(timestamp) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateObj = new Date(timestamp);

  const year = dateObj.getFullYear();
  const monthIndex = dateObj.getMonth();
  const day = dateObj.getDate();

  const month = months[monthIndex];
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

export default function CampaignUpdatesBlock({ data }) {
  return (
    <>
      <div className={classes.campaign_updates}>
        {data && data.length <= 0 ? (
          <div className={classes.update_block}>No updates</div>
        ) : (
          ""
        )}
        {data && data.length > 0
          ? data.map((update) => {
              return (
                <div className={classes.update_block} key={update.id}>
                  <p>{update.update_content}</p>
                  <p className={classes.update_timestamp}>
                    {parseTimestamp(update.timestamp)}
                  </p>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}
