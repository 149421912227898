import { useParams } from "react-router";
import { getPartner } from "../../../api/Partners";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareInstagram,
  faSquareFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import classes from "./PartnerSearch.module.css";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";

export default function PartnerFeature() {
  const { organization_name, partner_id } = useParams(); // Access the id parameter using useParams hook
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    getPartner(partner_id, organization_name).then((response) => {
      setPartner(response);
    });
  }, [organization_name, partner_id]);

  const partnerCardExcerpt = (text) => {
    const EXCERPT_CHARS = 50;
    let excerpt = text.substring(0, EXCERPT_CHARS);

    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";

    return excerpt;
  };

  const handleClick = (url) => {
    window.open(url, "_self");
  };

  // scroll to top on load of this component:
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${classes.partner_feature_page} main-div`}>
      {partner ? (
        <div className={classes.partner_feature}>
          <div className={classes.partner_header_container}>
            <img src={partner.logo} alt="" />
            <div className={classes.partner_description_container}>
              <h4>
                {partner.city}, {partner.state}
              </h4>
              <h1 className="text-orange text-3xl my-2">
                {partner.organization_name}
              </h1>
              <p className="my-2">{partner.description}</p>
              <p className={classes.website_link}>
                Click To Visit:{" "}
                <a href={partner.website_url}>
                  {partner.website_url
                    .replace("https://", "")
                    .replace("www.", "")
                    .toLowerCase()}
                </a>
              </p>
              <div className={classes.social_icon_container}>
                {partner.facebook_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={partner.facebook_url}
                  >
                    <FontAwesomeIcon icon={faSquareFacebook} className="icon" />
                  </a>
                ) : (
                  ""
                )}
                {partner.instagram_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={partner.instagram_url}
                  >
                    <FontAwesomeIcon
                      icon={faSquareInstagram}
                      className="icon"
                    />
                  </a>
                ) : (
                  ""
                )}
                {partner.linkedin_url ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={partner.linkedin_url}
                  >
                    <FontAwesomeIcon icon={faLinkedin} className="icon" />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {partner.products.length !== 0 ? (
            <div className={classes.partner_products_section}>
              <hr className="m-[100px_0]"></hr>
              <h1 className="text-orange">Our Products</h1>
              <div className={classes.banner_products}>
                {partner.products
                  .filter((product) => product.type === "banner")
                  .map((banner) => (
                    <img
                      src={banner.product_img}
                      alt="banner"
                      key={banner.id}
                    />
                  ))}
              </div>
              <div className={classes.partner_products_scrollable}>
                {partner.products
                  .filter((product) => product.type === "product")
                  .map((product) => {
                    return (
                      <div className={classes.partner_product_card_wrapper}>
                        <div
                          className={classes.partner_product_card}
                          key={product.id}
                          onClick={() => handleClick(product.product_url)}
                        >
                          <img src={product.product_img} alt="" />
                          <h3 className="text-2xl font-semibold">
                            {cardTitleExcerpt(product.name, 35)}
                          </h3>
                          <p className="text-black">
                            {partnerCardExcerpt(product.description)}
                          </p>
                          <GeneralButton
                            colour={"blue"}
                            children={"View"}
                            fn={handleClick}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        "404 No partner found"
      )}
    </div>
  );
}
