import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMagnifyingGlass,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { getShopItems } from "../../../api/ShopItems";
import { dashboard } from "../../../api/Dashboard";
import { subscribeMarketingNewsletter } from "../../../api/SubscribeNewsletter";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import classes from "./ShopSearch.module.css";
import { useSelector } from "react-redux";

export default function ShopSearch() {
  const [shopItems, setShopItems] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [errorState, setErrorState] = useState("");
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // getting list of partners from api
  useEffect(() => {
    try {
      getShopItems().then((response) => {
        setShopItems(response);
      });
    } catch (error) {
      console.error(`Could not retrieve list of shop items`);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Newsletter Submit functions
  const handleSubmit = (event) => {
    event.preventDefault();
    subscribeToNewsletter(email);
  };

  const subscribeToNewsletter = async (email) => {
    try {
      const payload = {
        email: email,
        active: false,
      };
      await subscribeMarketingNewsletter(payload);
      setErrorState("Successfully Sent Email");

      setTimeout(() => {
        setErrorState("");
      }, 2000);
    } catch (error) {
      let errorStr = errorHandler(error);
      errorStr = errorStr.substring(7, errorStr.length - 1);
      const errorMessageWithBreaks = lineBreaks(errorStr);
      setErrorState(errorMessageWithBreaks);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // creating input callback for SEARCH bar
  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  return (
    <div
      className={`main-div max-w-[90rem] !w-full !mt-0 !mb-0 !pt-32 pb-32 shadow-md flex justify-center items-center`}
    >
      <h1 className="text-[var(--default-orange)] mb-4">Shop Sponsor a Pet</h1>
      <header className={classes.shop_header}>
        <h5 className="my-4 font-bold">
          Subscribe to find out first about our new items:
        </h5>
        <form
          className={`${classes.shop_newsletter_form} w-full`}
          onSubmit={handleSubmit}
        >
          <input
            className="newsletter-input" //index.css
            type="email"
            id="newsletter-email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <GeneralButton colour="blue" type="submit" className="w-32">
            Subscribe
          </GeneralButton>
        </form>
      </header>
      {errorState && (
        <p
          className={`${
            errorState === "Successfully Sent Email"
              ? "text-green-500"
              : "text-[var(--default-red)]"
          } -mt-5 mb-4`}
        >
          {errorState}
        </p>
      )}
      <div className={classes.shop_navbar}>
        <div className={classes.shop_search_panel}>
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
          <input
            type="text"
            placeholder="Search Products by Name"
            onInput={handleSearch}
          />
        </div>
        <div className="cursor-pointer justify-center align-middle">
          <Link className={classes.cart_link} to={`/cart`}>
            <FontAwesomeIcon
              icon={faCartShopping}
              className="text-2xl mt-[0.4rem]"
            />
          </Link>
        </div>
      </div>

      <div className={classes.shop_search_card_container}>
        {shopItems.map((item, index) => {
          // make a check to see if we want to render this item or not
          if (
            !item.title.toLowerCase().includes(searchParam.toLowerCase()) ||
            !item.visible
          )
            return "";

          // we have determined this card matches the search param. render it
          return (
            <Link to={`/shop/${item.product_id}`} key={index}>
              <div className={classes.shop_search_card} key={item.product_id}>
                {item.first_image_url ? (
                  <img src={item.first_image_url} alt={item.title} />
                ) : (
                  <img src="" alt={item.title} />
                )}
                <div>
                  <h3 className="text-xl font-bold">{item.title}</h3>
                  <p>From ${(item.first_price / 100).toFixed(2)}</p>
                  <div className={classes.link_icon}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
