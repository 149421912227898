import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCampaign, getCampaigns } from "../../../api/Campaigns";
import {
  faArrowLeft,
  faArrowRight,
  faStar as faSolidStar,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { getNpInfo } from "../../../api/NonprofitInfo";
import { urlifyName } from "../../shared/Utils/PageUrl";
import Slider from "react-slick";
import { dashboard } from "../../../api/Dashboard";
import { generateDonation } from "../../../api/GenerateDonation";
import { donationSuccess } from "../../../api/DonationSuccess";
import CampaignTabbedInfoBlock from "../CampaignTabbedInfoBlock/CampaignTabbedInfoBlock";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import { extractTextContent } from "../../shared/Utils/ExtractText";
import classes from "./CampaignFeature.module.css";
import SuccessModal from "./SuccessModal/SuccessModal";
import searchClasses from "../CampaignSearch/CampaignSearch.module.css";
import NotFound from "../../shared/Utils/NotFound";
import { useSelector } from "react-redux";
import LoginSignupModal from "../../shared/LoginSignupModal/LoginSignupModal";
import CampaignCard from "../CampaignCard/CampaignCard";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { addFavorite, removeFavorite } from "../../../api/Favorite";
import npClasses from "../../Nonprofits/NonprofitSearch/NonprofitSearch.module.css";

const npExcerpt = (plainText) => {
  let excerpt = plainText.substring(0, 50);
  // Ensure the last character isn't punctuation before adding ellipsis
  excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";
  return excerpt;
};

export default function CampaignFeature() {
  const { nonprofit_name, animal_name, campaign_id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [otherCampaigns, setOtherCampaigns] = useState([]);
  const [nonprofit, setNonprofit] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [donationAmount, setDonationAmount] = useState(0);
  const [donationId, setDonationId] = useState(0);
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [localUserData, setLocalUserData] = useState(null);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [blurredImages, setBlurredImages] = useState({});
  const [error, setError] = useState(null);
  const [coverFee, setCoverFee] = useState(false);
  const [prevAmount, setPrevAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const userData = useSelector((state) => state.userData.userData);

  const [isLoginSignupModalOpen, setLoginSignupModalOpenModalOpen] =
    useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [completedDonations, setCompletedDonations] = useState([]);

  const navigate = useNavigate();

  function terminalWarnings() {
    return isStripeLoading;
  }

  terminalWarnings();

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const tempLoadedCampaign = await getCampaign(
          nonprofit_name,
          animal_name,
          campaign_id
        );
        if (!tempLoadedCampaign) {
          throw new Error("Campaign not found");
        }
        if (!tempLoadedCampaign) return;
        const tempLoadedNonprofit = await getNpInfo(
          tempLoadedCampaign.nonprofit
        );
        let tempOtherCampaigns = await getCampaigns();
        tempOtherCampaigns = tempOtherCampaigns.filter(
          (item) =>
            item.nonprofit === tempLoadedCampaign.nonprofit &&
            item.id !== tempLoadedCampaign.id
        );
        if (tempOtherCampaigns.length > 3)
          tempOtherCampaigns = tempOtherCampaigns.slice(0, 3);

        const donations = tempLoadedCampaign.donations_details.filter(
          (donation) => {
            return donation["status"] === "completed";
          }
        );

        setCampaign(tempLoadedCampaign);
        setNonprofit(tempLoadedNonprofit);
        setOtherCampaigns(tempOtherCampaigns);
        setCompletedDonations(donations);
        return tempLoadedCampaign;
      } catch (error) {
        setError(error);
      }
    };
    try {
      fetchAll();
    } catch (error) {
      console.error("Error in CampaignFeature:", error);
    }
  }, [nonprofit_name, animal_name, campaign_id]);

  const handleDonationSubmit = async (event) => {
    event.preventDefault();

    let amountToDonate = selectedOption || parseFloat(customAmount);

    if (coverFee) {
      amountToDonate += parseFloat((amountToDonate * 0.03).toFixed(2));
    }

    setDonationAmount(amountToDonate);

    const initializePayment = async (campaign) => {
      if (!campaign || !campaign.donation_goal) return;

      const data = {
        campaign: campaign_id,
        amount: amountToDonate,
      };
      try {
        const response = await generateDonation(data);
        setClientSecret(response.client_secret);
        setDonationId(response.donation_id);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    initializePayment(campaign);
  };

  useEffect(() => {
    if (clientSecret) {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
      const appearance = {
        /* appearance */
      };
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const elementsInstance = stripeInstance.elements({
        clientSecret,
        appearance,
      });
      const cardElement = elementsInstance.create("payment", options);
      cardElement.mount("#payment-element");

      cardElement.on("ready", () => {
        setIsStripeLoading(false);
      });

      setStripe(stripeInstance);
      setElements(elementsInstance);
    }
  }, [clientSecret]);

  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentResponse("Payment Element is not loaded.");
      return;
    }

    setIsStripeLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentResponse(error.message);
      } else {
        if (paymentIntent && paymentIntent.status === "succeeded") {
          setPaymentResponse({ status: "success" });
          setShowModal(true);
          await donationSuccess({ donation_id: donationId });
        } else {
          setPaymentResponse({ error: error });
        }
      }
    } catch (error) {
      setPaymentResponse("An error occurred during payment.");
      console.error(error);
    } finally {
      setIsStripeLoading(false);
    }
  };

  const handleOptionClick = (amount) => {
    if (customAmount) {
      setCoverFee(false);
      setCustomAmount("");
    }

    if (selectedOption === amount) {
      setSelectedOption(null);
      setPrevAmount(0);
      setDonationAmount(0);
    } else {
      setSelectedOption(amount);
      setPrevAmount(amount);
      setDonationAmount(amount);
      setCoverFee(false);
    }
  };

  const handleImageClick = (imageUrl) => {
    setBlurredImages((prevState) => ({
      ...prevState,
      [imageUrl]: prevState[imageUrl] === false ? true : false,
    }));
  };

  const handleInputChange = (e) => {
    setPrevAmount(e.target.value);
    setCustomAmount(e.target.value);
    setDonationAmount(parseFloat(e.target.value));
    if (selectedOption) {
      setSelectedOption(null);
      setCoverFee(false);
    }
  };

  const handleCoverFeeChange = (e) => {
    setCoverFee(e.target.checked);
    const fee = parseFloat((prevAmount * 0.03).toFixed(2));
    if (e.target.checked) {
      setDonationAmount(Number(prevAmount) + Number(fee));
    } else {
      setDonationAmount(Number(prevAmount));
    }
  };

  let slideshowSettings = {
    dots: true,
    infinite:
      campaign && campaign.campaign_images
        ? campaign.campaign_images.length > 1
        : false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:
      campaign && campaign.campaign_images
        ? campaign.campaign_images.length > 1
        : false,
    autoplay: false,
    speed: 500,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [otherCampaigns]);

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  if (error) {
    return <NotFound />;
  }

  const openLoginSignupModal = (type) => {
    setLoginOrSignup(type);
    setLoginSignupModalOpenModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeLoginSignupModal = () => {
    setLoginSignupModalOpenModalOpen(false);
    document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  const handleClick = (item) => {
    const url = `/campaigns/${urlifyName(item.nonprofit_name)}/${urlifyName(
      item.animal_name
    )}/${item.id}`;
    navigate(url);
  };

  const handleStarClick = (event, isFavorite, id) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFavorite) {
      handleRemoveFavorite(id);
    } else {
      handleAddFavorite(id);
    }
  };

  const handleRemoveFavorite = async (id) => {
    if (!localUserData) return;
    try {
      await removeFavorite({ nonprofit_id: id });
      const i = localUserData.favorite_nonprofits.indexOf(id);
      if (i >= 0) {
        const newFavorites = localUserData.favorite_nonprofits.filter(
          (favId) => favId !== id
        );
        setLocalUserData({
          ...localUserData,
          favorite_nonprofits: newFavorites,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddFavorite = async (id) => {
    if (!localUserData) return;
    try {
      await addFavorite({ nonprofit_id: id });
      const i = localUserData.favorite_nonprofits.indexOf(id);
      if (i < 0) {
        const newFavorites = [...localUserData.favorite_nonprofits, id];
        setLocalUserData({
          ...localUserData,
          favorite_nonprofits: newFavorites,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {campaign ? (
        <div className={`${classes.campaign_feature} main-div`}>
          <div>
            <Link to={`/campaigns`}>
              <h2 className={classes.back_button}>
                <FontAwesomeIcon icon={faArrowLeft} /> Find a pet
              </h2>
            </Link>
          </div>
          <div className={classes.campaign_title}>
            <h1>{campaign.campaign_title}</h1>
          </div>
          <div className={classes.campaign_header}>
            <div className={classes.campaign_media_carousel}>
              {campaign.campaign_images &&
              campaign.campaign_images.length > 0 ? (
                <Slider {...slideshowSettings}>
                  {campaign.campaign_images
                    .sort((a, b) => a.order - b.order)
                    .map((image, index) => (
                      <div key={index} className={classes.carousel_slide}>
                        <div className={classes.image_container}>
                          <img
                            src={image.image_url}
                            alt={campaign.animal_name}
                            className={
                              image.graphic &&
                              blurredImages[image.image_url] !== false
                                ? classes.blurred
                                : ""
                            }
                          />
                          {image.graphic &&
                            blurredImages[image.image_url] !== false && (
                              <span
                                className={classes.show_overlay}
                                onClick={() =>
                                  handleImageClick(image.image_url)
                                }
                              >
                                Show
                              </span>
                            )}
                        </div>
                      </div>
                    ))}
                  {campaign.video_urls &&
                    campaign.video_urls.split(",").map((videoUrl, index) => (
                      <div key={index} className={classes.carousel_slide}>
                        <iframe
                          src={videoUrl.trim()}
                          title={`Campaign Video ${index + 1}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="w-[inherit] h-[inherit]"
                        />
                      </div>
                    ))}
                </Slider>
              ) : null}
            </div>
            <div className={classes.campaign_header_content}>
              {nonprofit &&
              nonprofit.address &&
              nonprofit.address.city &&
              nonprofit.address.state ? (
                <p className="text-[var(--unselected-grey)]">
                  {nonprofit.address.city.toUpperCase()},{" "}
                  {nonprofit.address.state.toUpperCase()}
                </p>
              ) : (
                ""
              )}
              <h1>
                {campaign && campaign.animal_name
                  ? campaign.donation_box_title
                  : "Donate"}
              </h1>
              <div className="flex-col mb-4">
                <div
                  className={`${classes.campaign_progress_text} justify-between`}
                >
                  <p>
                    {Math.round(
                      (campaign.donation_amount * 100) / campaign.donation_goal
                    )}
                    % Donation Completed
                  </p>
                  <p>
                    ${campaign.donation_amount} of ${campaign.donation_goal}{" "}
                    received
                  </p>
                </div>
                <div className={classes.campaign_progress_bar_background}>
                  <div
                    className={classes.campaign_progress_bar}
                    style={{
                      width: `${Math.round(
                        (campaign.donation_amount * 100) /
                          campaign.donation_goal
                      )}%`,
                    }}
                  />
                </div>
              </div>
              {paymentResponse && paymentResponse.status && showModal ? (
                <SuccessModal
                  message="Payment succeeded!"
                  onClose={closeModal}
                  showModal={showModal}
                />
              ) : (
                ""
              )}
              <div
                className={`${classes.campaign_donor_box} w-full flex flex-col justify-center items-center mb-4 rounded-lg`}
              >
                {clientSecret ? (
                  <form
                    id="payment-form"
                    className={classes.payment_form}
                    onSubmit={handleSubmitPayment}
                  >
                    <div id="payment-element"></div>
                    <button className={classes.submit_btn} type="submit">
                      Complete Donation
                    </button>
                  </form>
                ) : (
                  <>
                    <div
                      className={`${classes.donor_box} flex flex-col justify-center items-center`}
                    >
                      <h1 className="my-4">
                        ${Math.floor(campaign.donation_amount)} raised
                      </h1>
                      <h4>
                        by {completedDonations.length}
                        {completedDonations.length === 1
                          ? ` donor who has donated`
                          : ` donors who have donated`}
                      </h4>

                      <div className={classes.options_row}>
                        {[10, 25, 50, 100].map((amount) => (
                          <button
                            key={amount}
                            className={`${
                              selectedOption === amount
                                ? "!bg-orange !text-white"
                                : ""
                            }`}
                            type="button"
                            onClick={() => handleOptionClick(amount)}
                          >
                            ${amount}
                          </button>
                        ))}
                      </div>

                      <form
                        id={classes.amount_form}
                        className="w-full no-underline"
                        onSubmit={handleDonationSubmit}
                      >
                        <div className={classes.donation_amount_input}>
                          <input
                            type="number"
                            id="donation-amount"
                            name="donation-amount"
                            placeholder="Custom donation amount"
                            value={customAmount}
                            onChange={handleInputChange}
                          />
                          <div className="flex flex-row gap-2 pl-4 pr-4">
                            <label htmlFor="coverFee" className="text-sm">
                              Would you like to cover the transaction fee of ($
                              {(prevAmount * 0.03).toFixed(2)})?
                            </label>
                            <div className="-mt-4">
                              <input
                                type="checkbox"
                                id="coverFee"
                                checked={coverFee}
                                onChange={handleCoverFeeChange}
                              />
                            </div>
                          </div>

                          {localUserData ? (
                            <button id="submit-amount">Donate</button>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>

                      {!localUserData ? (
                        <div>
                          <h4 className={`mb-9`}>
                            {isLoginSignupModalOpen ? (
                              loginOrSignup === "login" ? (
                                <LoginSignupModal
                                  loginOrSignup={"login"}
                                  isOpen={openLoginSignupModal}
                                  onClose={closeLoginSignupModal}
                                  onFormSuccess={handleFormSuccess}
                                />
                              ) : (
                                <LoginSignupModal
                                  loginOrSignup={"signup"}
                                  isOpen={openLoginSignupModal}
                                  onClose={closeLoginSignupModal}
                                  onFormSuccess={handleFormSuccess}
                                />
                              )
                            ) : (
                              ""
                            )}
                            <button
                              className="underline text-orange"
                              onClick={() => openLoginSignupModal("signup")}
                            >
                              Sign Up
                            </button>{" "}
                            or{" "}
                            <button
                              className="underline text-orange"
                              onClick={() => openLoginSignupModal("login")}
                            >
                              login
                            </button>{" "}
                            to donate
                          </h4>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={classes.campaign_info}>
            {campaign && campaign.description ? (
              <>
                <div className="flex flex-col">
                  <h1>About {campaign.animal_name}</h1>
                  {parseDescription === campaign.description ? (
                    <p>{campaign.description}</p>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parseDescription(campaign.description),
                      }}
                    />
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            <CampaignTabbedInfoBlock />
          </div>
          <div className={classes.campaign_nonprofit}>
            {otherCampaigns.length > 0 && nonprofit ? (
              <>
                <h1>Can't decide what animal to help?</h1>
                <h2 className="my-4 text-2xl font-semibold text-blue">
                  Support our other campaigns!
                </h2>
                <div className={classes.other_campaigns_content}>
                  {otherCampaigns.map((item) => (
                    <CampaignCard
                      key={item.id}
                      campaign={item}
                      handleClick={handleClick}
                    />
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
            {nonprofit ? (
              <>
                <h2 className="mb-4 mt-12 text-blue font-semibold text-2xl">
                  More about {nonprofit.org_name}
                </h2>
                <div
                  className={`${npClasses.nonprofit_search_card_container} !justify-center`}
                >
                  <Link
                    to={`/non-profit/${urlifyName(nonprofit.org_name)}/${
                      nonprofit.id
                    }`}
                  >
                    <div className={npClasses.nonprofit_search_card}>
                      <div className={npClasses.nonprofit_search_card_header}>
                        {nonprofit.address
                          ? `${nonprofit.address.city}, ${nonprofit.address.state}`
                          : ""}
                        {localUserData ? (
                          localUserData.favorite_nonprofits.includes(
                            nonprofit.id
                          ) ? (
                            <FontAwesomeIcon
                              icon={faSolidStar}
                              onClick={(e) =>
                                handleStarClick(e, true, nonprofit.id)
                              }
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faRegularStar}
                              onClick={(e) =>
                                handleStarClick(e, false, nonprofit.id)
                              }
                            />
                          )
                        ) : null}
                      </div>
                      {nonprofit.logo ? (
                        <img
                          src={nonprofit.logo}
                          alt={`Logo of ${nonprofit.org_name}`}
                        />
                      ) : null}
                      <div className={npClasses.cardContent}>
                        <h3 className="font-semibold text-2xl">
                          {cardTitleExcerpt(nonprofit.org_name, 35)}
                        </h3>
                        {parseDescription === nonprofit.mission_stmt ? (
                          <p>{npExcerpt(nonprofit.mission_stmt)}</p>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: npExcerpt(
                                parseDescription(nonprofit.mission_stmt)
                              ),
                            }}
                          />
                        )}
                        <FontAwesomeIcon
                          className={npClasses.arrowIcon}
                          icon={faArrowRight}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-row ml-auto mt-[35vh] w-full h-full justify-center">
          <Spinner />
        </div>
      )}
    </>
  );
}
