import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { getCampaignByNonProfit } from "../../../api/Campaigns";

const ProgressBar = ({ npData }) => {
  const [progress, setProgress] = useState(25);

  useEffect(() => {
    const fetchCampaignData = async () => {
        try {
          const campaigns = await getCampaignByNonProfit(npData.id);

          if (campaigns && campaigns.length > 0) {
            setProgress(100);
          }
        } catch (error) {
          console.error("Error fetching campaign data:", error);
      }
    };

    fetchCampaignData();
  }, [npData]);


  useEffect(() => {
    if (npData?.stripe_account) {
      setProgress(50);
      if (npData?.stripe_account.charges_enabled) {
        setProgress(75);
      }
    }
  }, [npData]);

  return (
    <div> 
      <span>{progress}%</span>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export default ProgressBar;
