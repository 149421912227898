import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { subscribeMarketingNewsletter } from "../../../api/SubscribeNewsletter";
import { validateEmail } from "../Utils/ValidateEmail";
import { errorHandler } from "../Utils/ErrorHandler";
import { lineBreaks } from "../Utils/LineBreaks";
import classes from "./Footer.module.css";

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (emailError) setEmailError("");
  };

  const subscribeToNewsletter = async (email) => {
    if (!validateEmail(email)) {
      throw new Error("Please enter a valid email address.");
    }

    const payload = {
      email: email,
      active: false,
    };

    try {
      await subscribeMarketingNewsletter(payload);
      navigate("/");
      setEmail("");
      setEmailError("");
    } catch (error) {
      let errorMessage = errorHandler(error);
      if (errorMessage.includes("email:")) {
        errorMessage = errorMessage.substring(7, errorMessage.length - 1);
      }
      const errorMessageWithBreaks = lineBreaks(errorMessage);

      setEmailError(errorMessageWithBreaks);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email.trim() === "") {
      setEmailError("Please enter an email address.");
      return;
    }
    subscribeToNewsletter(email);
  };

  const handleClick = (event) => {
    const path = event.currentTarget.getAttribute("to");
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <footer className={classes.footer_container}>
      <form
        className={`${classes.footer_newsletter} !mr-20`}
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl">Subscribe for News & Updates</h2>
        <input
          type="email"
          id="newsletter-email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          className="text-black"
        />
        {emailError && (
          <p className="text-[var(--default-red)] font-medium">{emailError}</p>
        )}
        <button className="hover:underline" type="submit">
          Subscribe →
        </button>
      </form>
      <div className={classes.footer_links}>
        <div className="flex flex-col">
          <div className="flex flex-row gap-8">
            <div className={classes.footer_column}>
              <h4 className="font-bold">LEGAL</h4>
              <ul>
                <li>
                  <Link
                    className="no-underline text-white hover:underline"
                    to="/terms-of-service"
                    onClick={handleClick}
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    className="no-underline text-white hover:underline"
                    to="/refund-policy"
                    onClick={handleClick}
                  >
                    Refund Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="no-underline text-white hover:underline"
                    to="/privacy-policy"
                    onClick={handleClick}
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className={classes.footer_column}>
              <h4 className="font-bold">ABOUT</h4>
              <ul>
                <li>
                  <Link
                    className="no-underline text-white hover:underline"
                    to="/our-mission"
                    onClick={handleClick}
                  >
                    Our Mission
                  </Link>
                </li>
                <li>
                  <Link
                    className="no-underline text-white hover:underline"
                    to="/faq"
                    onClick={handleClick}
                  >
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    className="no-underline text-white hover:underline"
                    to="/contact-us"
                    onClick={handleClick}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          
          {/* Social Media Icons Row */}
          <div className="my-auto flex justify-center space-x-6">
            <a
              href="https://www.facebook.com/sponsorapetfoundation/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
              className="text-white hover:text-gray-300 transition"
            >
              <FontAwesomeIcon icon={faFacebookF} size="lg" />
            </a>
            <a
              href="https://www.instagram.com/sponsorapet/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
              className="text-white hover:text-gray-300 transition"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a
              href="https://www.linkedin.com/company/sponsor-a-pet"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
              className="text-white hover:text-gray-300 transition"
            >
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </a>
            <a
              href="https://www.tiktok.com/@sponsorapet"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok"
              className="text-white hover:text-gray-300 transition"
            >
              <FontAwesomeIcon icon={faTiktok} size="lg" />
            </a>
          </div>
        </div>
        <div className={classes.footer_column}>
          <h4 className="font-bold">SPONSOR A PET</h4>
          <p className="!mb-3">
            Sponsor a Pet, the happy fundraising platform, empowers Animal
            Non-Profits to share their stories and secure the vital resources
            needed to save animals' lives.
          </p>
          <Link
            className={`${classes.footer_btn} hover:underline`}
            to="/our-mission"
            onClick={handleClick}
          >
            Read more →
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
