import CustomImage from "../../shared/CustomImage/CustomImage";
import AboutPara from "./AboutPara/AboutPara";

// some stock images
import aboutUs from "../../../assets/about-us-1.png";
import aboutUs2 from "../../../assets/about-us-2.png";
import { useEffect } from "react";
import LinkButton from "../../shared/Buttons/LinkButton";
import classes from "./AboutUs.module.css";

export default function AboutUs() {
  const aboutText =
    "At Sponsor a Pet, we're dedicated to giving animal rescues a platform to share their stories and connect with animal lovers like you. Our mission is simple yet powerful: to provide every animal with a loving environment they can call home, ensuring their basic needs are met and advocating for the end of kill shelters, animal abuse, and habitat destruction.";
  const differentText =
    "We work hand in hand with passionate local organizations, amplifying our impact in communities as we unite under one common goal. Our platform highlights Animal Non-Profits’ stories of positivity and laughter by uplifting animals in need while expressing gratitude to the tireless volunteers who help these animals find their forever homes.";
  const accomplishTextOld =
    "We are Sponsor a pet a platform dedicated to providing Animal Non-Profits a platform to share their stories " + 
  "and help raise the necessary resources to save the lives of animal.";

  const accomplishText1 = (
    <p>
      Sponsor a Pet has successfully provided a platform for animal rescues to
      share their stories and connect with animal lovers, increasing visibility
      for these Animal Non-Profits and the animals they care for.
    </p>
  );

  const accomplishText2 = (
    <p>
      By collaborating with passionate local organizations, Sponsor a Pet has
      been able to amplify its impact in communities, working towards its
      mission of providing every animal with a loving home and addressing issues
      like kill shelters, animal abuse, and habitat destruction.
    </p>
  );

  const accomplishText3 = (
    <p>
      Through our platform, Sponsor a Pet has fostered a community of
      like-minded individuals who are dedicated to helping animals in need. By
      sharing uplifting stories and expressing gratitude to volunteers, they
      have cultivated a supportive environment for animal welfare.
    </p>
  );

  const accomplishText4 = (
    <p>
      Sponsor a Pet plays a role in advocating for animal rights and raising
      awareness about important issues such as the troubles of animals in
      shelters and the need for responsible pet ownership.
    </p>
  );

  const accomplishDescription = (
    <p>
      If you're inspired by our mission and want to make a difference, we invite
      you to join our community. Together, we're a team of passionate
      individuals with a shared vision of helping all animals. By coming
      together and moving in the same direction, we can create a lasting impact
      in communities across the country. Join us at Sponsor a Pet, and let's
      make a difference together.
    </p>
  );
  const involvedText =
    "On top of that, there’s much more you can do to get involved. " +
 "The easiest way is to share your story with us. We would love to hear from you and share your story. " +
  "What drives your passion for animals and a little about your background. " +
  "Another way would be to share local organizations that need our help, or local communities who would " + 
  "want to get involved. Our goal is to provide a platform for animals, non-profits, Individuals, and companies alike.";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${classes.about_container} main-div`}>
      <div className={classes.content}>
        <section className={classes.text_section}>
          <div className={classes.about_us}>
            <h2 className="text-3xl font-bold text-orange my-4">About us</h2>
            <AboutPara text={aboutText} />
          </div>

          <div className={classes.how_different}>
            <h2 className="text-3xl font-bold text-orange my-4">
              How are we different?
            </h2>
            <AboutPara text={differentText} />
          </div>
          <section>
            <LinkButton
              colour="blue"
              to="/campaigns"
              className={classes.donate_button}
            >
              Start Donating →
            </LinkButton>{" "}
            {/* change link out */}
          </section>
        </section>

        <section className={classes.image_section}>
          <CustomImage src={aboutUs} alt={"about-us"} />
        </section>
      </div>

      <div className={classes.accomplishments}>
        <h2 className="text-3xl font-bold text-orange my-4">
          What have we accomplished?
        </h2>
        <div className="grid grid-cols-[1fr_4.5fr] grid-rows-[0.7fr_1fr_1fr_1fr] text-left">
          <strong>Providing a Platform:</strong>
          <AboutPara text={accomplishText1} />
          <strong>Amplifying Impact:</strong>
          <AboutPara text={accomplishText2} />
          <strong>Fostering Community:</strong>
          <AboutPara text={accomplishText3} />
          <strong>Advocacy and Awareness:</strong>
          <AboutPara text={accomplishText4} />
        </div>
        <AboutPara text={accomplishDescription} className="!text-center" />
      </div>

      <div className={`${classes.content} ${classes.reverse_mobile}`}>
        <section className={classes.image_section}>
          <CustomImage src={aboutUs2} alt={"about-us-2"} />
        </section>
        <section className={classes.text_section}>
          <div className={classes.get_involved}>
            <h3 className="font-bold">How to get involved?</h3>
            <h2 className="text-3xl font-bold text-orange my-4">
              Signing Up as one of our members as a PetStar is a great start
            </h2>
            <AboutPara text={involvedText} />
            <LinkButton
              colour="blue"
              to="/petstar"
              className={classes.donate_button}
            >
              Join the PetStar Club
            </LinkButton>
          </div>
        </section>
      </div>
    </div>
  );
}
