import { useEffect, useState } from "react";
import { contactUs } from "../../../api/ContactUs";
import { validateEmail } from "../../shared/Utils/ValidateEmail";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import classes from "./ContactUs.module.css";
import { useLocation } from 'react-router-dom';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [feError, setFEError] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState({ error: "" });
  const [ifFromEvents, setIfFromEvents] = useState(false);

  const location = useLocation();
  const { fromEvents } = location.state || {};

  useEffect(() => {
    if (fromEvents) {
        setIfFromEvents(true);
    }
  }, [fromEvents]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const contactUsCall = async (formData) => {
    try {
      const response = await contactUs(formData);
      setSuccess("Your message has been sent successfully!");
      setError({ error: "" });
    } catch (error) {
      let errorStr = errorHandler(error);
      const errorMessageWithBreaks = lineBreaks(errorStr);
      setError({ error: errorMessageWithBreaks });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Validation for the name length
    if (formData.name.length > 25) {
      setFEError("Name must be under 25 characters.");
      setSuccess("");
      return; // Stop further execution if validation fails
    }

    // Validation for the email format
    if (!validateEmail(formData.email)) {
      setFEError("Please enter a valid email address.");
      setSuccess("");
      return; // Stop further execution if validation fails
    }

    setFEError("");

    // If all validations pass, make the API call
    contactUsCall(formData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`main-div ${classes.contact_form_container}`}>
      <h1>Contact Us</h1>
      <form className={classes.contact_us_form} onSubmit={handleSubmit}>
        <div className={classes.input_group}>
          <input
            type="text"
            name="name"
            className="border border-gray-400"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
          />
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />
        </div>
        <input
          type="number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone Number"
        />
        <textarea
          type="text"
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder={ifFromEvents ? "Please describe your event." : "Message"}
        />
        <GeneralButton colour="blue" type="submit">
          Send
        </GeneralButton>
        {feError && <div className="text-[var(--default-red)]">{feError}</div>}
        {error.error && (
          <div className="text-[var(--default-red)]">{error.error}</div>
        )}
        {success && <div className="text-green-500">{success}</div>}
      </form>
    </div>
  );
}
