import React, { useState, useEffect } from "react";
import classes from "./AffiliateSignup.module.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { affiliateSuccess } from "../../api/Affiliate";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../store";
import { dashboard } from "../../api/Dashboard";

const title = [
  "Create an Affiliate Account",
  "Pick A Plan",
  "Confirm Your Plan",
  "Add Payment Method",
  "You’re All Set!",
];

function AffiliateSuccess() {
  const [localUserData, setLocalUserData] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData)
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");

        if (userDataString) {
          const userDataParsed = JSON.parse(userDataString);
          const response = await dashboard(userDataParsed.id);
          dispatch(userDataActions.updateUserData(JSON.stringify(response)));
          setLocalUserData(response);
          localStorage.setItem("userData", JSON.stringify(response));
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        // Clear the localStorage items if they are corrupted or on error
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");

        if (Object.keys(userData).length !== 0) {
          dispatch(userDataActions.removeUserData());
        }
      }
    };
      fetchUserData();
  }, [dispatch, userData]);

  useEffect(() => {
    const getSuccess = async (localUserData) => {
      if (localUserData) {
        try {
          const response = await affiliateSuccess(localUserData.id);
          if (response) {
            return response;
          }
        } catch (error) {
          console.error("Error: ", error);
        }
      }
    };

    getSuccess(localUserData);
  }, [localUserData]);

  // Sends user to homepage after successful signup
  const handleHomeButton = () => {
    navigate("/");
  };
  return (
    <div className="main-div">
      {localUserData ? (
        <div
          className={`flex flex-col justify-center items-center overflow-clip`}
        >
          <div className={`${classes.signup_banner} flex flex-col w-full`}>
            <h1 className={`text-white`}>{title[4]}</h1>
          </div>
          <div className={`${classes.signup_form} flex flex-col w-full`}>
            <div className={`${classes.success_container}`}>
              <h2>
                We appreciate your help! Look out for an email in your inbox for
                confirmation!
              </h2>
              <Button
                variant="contained"
                sx={{
                  width: "284px",
                  height: "48px",
                  padding: "12px 40px",
                  borderRadius: "31px",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.2",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  textTransform: "none",
                  gap: "23px",
                  "&:hover": {
                    borderColor: "var(--default-orange)",
                    backgroundColor: "var(--default-orange)",
                  },
                  "&:focused": {
                    borderColor: "var(--default-orange)",
                    backgroundColor: "var(--default-orange)",
                  },
                  backgroundColor: "var(--default-orange)",
                }}
                onClick={handleHomeButton}
              >
                Take me Back Home
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex items-center justify-center absolute inset-0"
          style={{ transform: "translateY(-10%)" }}
        >
          <h2 className="text-6xl">404 Error - Page Not Found</h2>
        </div>
      )}
    </div>
  );
}

export default AffiliateSuccess;
