import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCampaigns } from "../../../api/Campaigns";
import NonprofitDonationTable from "./NonprofitDonationTable/NonprofitDonationTable";
import classes from "./NonprofitDonationList.module.css";
import dashboardClasses from "../Dashboard.module.css";
import { useSelector } from "react-redux";
import Spinner from "../../shared/LoadingSpinner/Spinner";

export default function NonprofitDonationList() {
  const [isLoading, setIsLoading] = useState(false);
  const [localUserData, setLocalUserData] = useState(null);
  const [donationDetails, setDonationDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  // Retrieve user data from localStorage
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Retrieve NP and campaign data
  useEffect(() => {
    if (!localUserData || !localUserData.nonprofit) return;

    setIsLoading(true); // Start loading

    const fetchCampaigns = async () => {
      try {
        const response = await getCampaigns();
        return response.filter(
          (campaign) => campaign.nonprofit === localUserData.nonprofit
        );
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    const fetchAll = async () => {
      let tempCampaigns = await fetchCampaigns();
      let tempDonationDetails = [];
      tempCampaigns.map((campaign) =>
        tempDonationDetails.push({
          animal_name: campaign.animal_name,
          data: campaign.donations_details,
        })
      );
      setDonationDetails(tempDonationDetails);
    };
    try {
      fetchAll();
    } catch (error) {
      console.error("Error getting donation details: ", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  }, [localUserData]);

  const searchMatch = (s) => {
    return s.toLowerCase().includes(searchValue.toLowerCase());
  };

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`${dashboardClasses.dashboard_content} w-full ml-14 pt-12`}>
      <div
        className={`${dashboardClasses.dashboard_block} flex flex-col gap-5 p-4 max-sm:hidden max-md:hidden min-lg:block`}
      >
        <h2 className="text-orange !text-3xl font-bold text-left">
          Animal Campaign Donations
        </h2>
      </div>
      <div className={`flex items-center relative w-full`}>
        <svg
          className={`absolute top-2 left-2`}
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3496 13.3559C13.2563 13.4478 13.1306 13.4994 12.9996 13.4996C12.8668 13.499 12.7393 13.4476 12.6434 13.3559L9.94336 10.6496C8.80622 11.6047 7.34425 12.084 5.86236 11.9874C4.38046 11.8908 2.99306 11.2259 1.98951 10.1313C0.985965 9.03661 0.443754 7.59681 0.475967 6.11212C0.508179 4.62743 1.11233 3.21249 2.16241 2.16241C3.21249 1.11233 4.62743 0.508179 6.11212 0.475967C7.59681 0.443754 9.03661 0.985965 10.1313 1.98951C11.2259 2.99306 11.8908 4.38046 11.9874 5.86236C12.084 7.34426 11.6047 8.80623 10.6496 9.94336L13.3496 12.6434C13.3969 12.6899 13.4344 12.7453 13.46 12.8065C13.4856 12.8677 13.4988 12.9333 13.4988 12.9996C13.4988 13.0659 13.4856 13.1316 13.46 13.1927C13.4344 13.2539 13.3969 13.3093 13.3496 13.3559ZM6.24961 10.9996C7.18907 10.9996 8.10743 10.721 8.88857 10.1991C9.6697 9.67715 10.2785 8.9353 10.638 8.06736C10.9976 7.19941 11.0916 6.24434 10.9083 5.32293C10.7251 4.40152 10.2727 3.55515 9.60837 2.89085C8.94407 2.22655 8.0977 1.77416 7.17629 1.59088C6.25488 1.4076 5.29981 1.50166 4.43186 1.86118C3.56391 2.2207 2.82206 2.82952 2.30013 3.61065C1.77819 4.39178 1.49961 5.31015 1.49961 6.24961C1.50126 7.50888 2.00224 8.7161 2.89268 9.60654C3.78312 10.497 4.99034 10.998 6.24961 10.9996Z"
            fill="var(--grey-icon)"
            fillOpacity="0.5"
          />
        </svg>
        <input
          type="text"
          placeholder="Search By Animal Name"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={`p-2 pl-8 border text-black border-gray-300 rounded-lg mb-4 w-full h-7 `}
        />
      </div>

      {donationDetails.length > 0 && (
        <>
          {donationDetails.map((item, index) => {
            if (!searchMatch(item.animal_name)) return "";
            if (item.data.length <= 0) return "";
            return (
              <NonprofitDonationTable
                name={item.animal_name}
                key={index}
                data={item.data}
              />
            );
          })}
        </>
      )}
      <div className={dashboardClasses.mobile_message}>
        <p>
          Open the webapp in a desktop browser to view your donor information.
        </p>
      </div>
    </div>
  );
}
