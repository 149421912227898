import React, { useState, useEffect } from "react";
import { deleteDonor } from "../../../../api/DeleteDonor";
import { updateDonor } from "../../../../api/UpdateDonor";
import { errorHandler } from "../../../shared/Utils/ErrorHandler";
import GeneralButton from "../../../shared/Buttons/GeneralButton";
import dashboardClasses from "../../Dashboard.module.css";
import formClasses from "../../DashboardForm.module.css";
import classes from "../DonorList.module.css";
import { Pagination, PaginationItem, useMediaQuery } from "@mui/material";


const DonorTable = ({ nonprofitId, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [donorList, setDonorList] = useState(data);
  const [confirmDelete, setConfirmDelete] = useState(-1);
  const [confirmUpdate, setConfirmUpdate] = useState(-1);
  const [errorMsg, setErrorMsg] = useState("");
  const [expandDonorRow, setExpandDonorRow] = useState(null);
  const [ifRowExpanded, setIfRowExpanded] = useState(false);

  // Saving data to send to the backend
  const [donorFormData, setDonorFormData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    source: "",
  });

  const matchesMax430px = useMediaQuery("(max-width: 430px");

  useEffect(() => {
    setDonorList(data);
  }, [data]);

  // Calculate the current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = donorList.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(donorList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;
  const maxVisiblePages = 6;

  const totalPages = Math.ceil(donorList.length / itemsPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const renderItem = (item) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChange(null, page)}
        />
      );
    }
    if (matchesMax430px) {
      if (item.page > currentPage - Math.floor(maxVisiblePages / 2) &&
        item.page < currentPage + Math.floor(maxVisiblePages / 2)) {
        return <PaginationItem {...item} />;
      }
    } else {
      return <PaginationItem {...item} />;
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const donorDeleteButtonCallback = (donor_id) => {
    try {
      deleteDonor(donor_id);
      const tempDonorList = donorList.filter((item) => item.id !== donor_id);
      setDonorList(tempDonorList);
      setConfirmDelete(-1);
      setExpandDonorRow(null);
    } catch (error) {
      console.error("Error deleting in donor table:", error);
      const errorMessage = errorHandler(error);
      setErrorMsg(errorMessage);
    }
  };

  // Updates DonorList based on input change
  const handleDonorProfileInputChange = (e, donorId) => {
    const { name, value } = e.target;
    setDonorList((prevData) =>
      prevData.map((donor) =>
        donor.id === donorId ? { ...donor, [name]: value } : donor
      )
    );
  };

  // useEffect to grab last letter from input (making sure input isn't cut off)
  useEffect(() => {
    if (confirmUpdate !== -1) {
      const updatedDonor = donorList.find(
        (donor) => donor.id === confirmUpdate
      );
      if (updatedDonor) {
        const { id, email, first_name, last_name, source } = updatedDonor;
        setDonorFormData({ id, email, first_name, last_name, source });
      }
    }
  }, [donorList, confirmUpdate]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Handles submit and sends data to backend
  const handleDonorProfileFormSubmit = async (e, donorId) => {
    e.preventDefault();

    // Ensures the form data is updated with the latest state before submitting
    const updatedDonor = donorList.find((donor) => donor.id === donorId);
    if (updatedDonor) {
      const { id, email, first_name, last_name, source } = updatedDonor;
      setDonorFormData({ id, email, first_name, last_name, source });

      if (first_name === "") {
        alert("Missing first name. Please include it!");
        return;
      } else if (last_name === "") {
        alert("Missing last name. Please include it!");
        return;
      } else if (!validateEmail(email)) {
        alert("Email is invalid.");
        return;
      }

      // send data to the backend
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("email", email);
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("source", source);

        const response = await updateDonor(nonprofitId, donorId, formData);
        alert("Updated successfully!");
        setConfirmUpdate(-1);
      } catch (error) {
        const errorMessage = errorHandler(error);
        setErrorMsg(errorMessage);
      }
    }
  };

  const donorUpdateButtonCallback = (donorId) => {
    setConfirmUpdate(donorId);
  };

  const handleExpandDonorRow = (index) => {
    setExpandDonorRow(expandDonorRow === index ? null : index);
  };

  return (
    <div className={`${dashboardClasses.dashboard_block}`}>
      {matchesMax430px ? (
        <table
          className={`${formClasses.table_styles_override} !w-full table-fixed !border-separate`}
        >
          <thead>
            <tr>
              <th className="text-sm font-medium text-neutral-500">
                First Name
              </th>
              <th className="text-sm font-medium text-neutral-500">
                Last Name
              </th>
              <th className="text-sm font-medium text-neutral-500">Email</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              currentItems.map((donor, index) => (
                <tr
                  key={index}
                  onClick={() => handleExpandDonorRow(index)}
                  className="cursor-pointer"
                >
                  <td
                    className="bg-sky-50 rounded-l-xl"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <input
                      className="text-sm text-neutral-600 enabled:!bg-white disabled:bg-sky-50 disabled:border-none text-center"
                      type="text"
                      name="first_name"
                      value={donor.first_name}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleDonorProfileInputChange(e, donor.id)
                      }
                      disabled={confirmUpdate !== donor.id}
                      form={`form-${donor.id}`}
                      required
                    />
                    {expandDonorRow === index && (
                      <div
                        className="flex justify-center mt-3"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                          Source
                          <span className="text-neutral-600 !text-xs font-bold">
                            {capitalizeFirstLetter(donor.source)}
                          </span>
                        </p>
                      </div>
                    )}
                  </td>
                  <td className="bg-sky-50 mb-14">
                    <input
                      className="text-sm text-neutral-600 enabled:!bg-white disabled:bg-sky-50 disabled:border-none text-center"
                      type="text"
                      name="last_name"
                      value={donor.last_name}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleDonorProfileInputChange(e, donor.id)
                      }
                      disabled={confirmUpdate !== donor.id}
                      form={`form-${donor.id}`}
                      required
                    />
                    {expandDonorRow === index && (
                      <div
                        className="flex justify-center  items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                          onClick={(e) => e.stopPropagation()}
                          id={`form-${donor.id}`}
                          className="text-sm text-neutral-600"
                        >
                          {confirmUpdate !== donor.id ? (
                            <GeneralButton
                              type="button"
                              colour="blue"
                              value="Save"
                              onClick={(e) => {
                                e.stopPropagation();
                                donorUpdateButtonCallback(donor.id);
                              }}
                            >
                              Update
                            </GeneralButton>
                          ) : (
                            <GeneralButton
                              className="w-full"
                              colour="green"
                              type="submit"
                              name={`submit-${donor.id}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDonorProfileFormSubmit(e, donor.id);
                              }}
                            >
                              Save
                            </GeneralButton>
                          )}
                        </form>
                      </div>
                    )}
                  </td>
                  <td className="bg-sky-50 mb-14">
                    <input
                      className="text-sm text-neutral-600 enabled:!bg-white disabled:bg-sky-50 disabled:border-none text-center"
                      type="email"
                      name="email"
                      value={donor.email}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleDonorProfileInputChange(e, donor.id)
                      }
                      disabled={confirmUpdate !== donor.id}
                      form={`form-${donor.id}`}
                      required
                    />
                    {expandDonorRow === index && (
                      <div
                        className="flex justify-center rounded-r-xl"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {confirmDelete === donor.id ? (
                          <GeneralButton
                            colour="red"
                            onClick={(e) => {
                              e.stopPropagation();
                              donorDeleteButtonCallback(donor.id);
                            }}
                          >
                            Confirm?
                          </GeneralButton>
                        ) : (
                          <GeneralButton
                            colour="red"
                            onClick={(e) => {
                              e.stopPropagation();
                              setConfirmDelete(donor.id);
                            }}
                          >
                            Delete
                          </GeneralButton>
                        )}
                      </div>
                    )}
                  </td>

                  {expandDonorRow === index ? (
                    <td className="bg-sky-50 mb-14 rounded-r-xl" align="center">
                      <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.999528 9L-3.47717e-07 7.95484L7.33176 0.287717C7.51087 0.103191 7.75054 -2.82587e-07 8 -2.74042e-07C8.24946 -2.65496e-07 8.48913 0.103191 8.66824 0.287717L16 7.95485L14.9995 9L8 1.68092L0.999528 9Z"
                          fill="#7F7F7F"
                        />
                      </svg>
                    </td>
                  ) : (
                    <td className="bg-sky-50 mb-14 rounded-r-xl" align="center">
                      <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.0005 1.21503e-07L16 1.04515L8.66824 8.71228C8.48913 8.89681 8.24946 9 8 9C7.75054 9 7.51087 8.89681 7.33176 8.71228L1.39656e-07 1.04515L1.00047 8.10373e-09L8 7.31908L15.0005 1.21503e-07Z"
                          fill="#7F7F7F"
                        />
                      </svg>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  className="bg-sky-50 mb-14 rounded-xl text-sm font-medium text-neutral-500 p-6"
                >
                  Sorry, no matching results!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <table
          className={` ${formClasses.table_styles_override} !w-full table-fixed !border-separate`}
        >
          <thead>
            <tr>
              <th className="text-sm font-medium text-neutral-500">
                First Name
              </th>
              <th className="text-sm font-medium text-neutral-500">
                Last Name
              </th>
              <th className="text-sm font-medium text-neutral-500">Email</th>
              <th className="text-sm font-medium text-neutral-500">Source</th>
              <th className="text-sm font-medium text-neutral-500">Update</th>
              <th className="text-sm font-medium text-neutral-500">Delete</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              currentItems.map((donor, index) => (
                <tr key={index}>
                  <td className="bg-sky-50 mb-14 rounded-l-xl">
                    <input
                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                      type="text"
                      name="first_name"
                      value={donor.first_name}
                      onChange={(e) =>
                        handleDonorProfileInputChange(e, donor.id)
                      }
                      disabled={confirmUpdate !== donor.id}
                      form={`form-${donor.id}`}
                      required
                    />
                  </td>
                  <td className="bg-sky-50 mb-14">
                    <input
                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                      type="text"
                      name="last_name"
                      value={donor.last_name}
                      onChange={(e) =>
                        handleDonorProfileInputChange(e, donor.id)
                      }
                      disabled={confirmUpdate !== donor.id}
                      form={`form-${donor.id}`}
                      required
                    />
                  </td>
                  <td className="bg-sky-50 mb-14">
                    <input
                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                      type="email"
                      name="email"
                      value={donor.email}
                      onChange={(e) =>
                        handleDonorProfileInputChange(e, donor.id)
                      }
                      disabled={confirmUpdate !== donor.id}
                      form={`form-${donor.id}`}
                      required
                    />
                  </td>
                  <td className="bg-sky-50 mb-14 text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center">
                    {capitalizeFirstLetter(donor.source)}
                  </td>
                  <td className="bg-sky-50 mb-14 ">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      id={`form-${donor.id}`}
                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                    >
                      {confirmUpdate !== donor.id ? (
                        <GeneralButton
                          type={"button"}
                          colour={"blue"}
                          value={"Save"}
                          onClick={() => {
                            donorUpdateButtonCallback(donor.id);
                          }}
                        >
                          Update
                        </GeneralButton>
                      ) : (
                        <GeneralButton
                          className={"w-full"}
                          colour={"green"}
                          type={"submit"}
                          name={`submit-${donor.id}`}
                          onClick={(e) => {
                            handleDonorProfileFormSubmit(e, donor.id);
                          }}
                        >
                          Save
                        </GeneralButton>
                      )}
                    </form>
                  </td>
                  <td className="bg-sky-50 mb-14 rounded-r-xl">
                    {confirmDelete === donor.id ? (
                      <GeneralButton
                        colour={"red"}
                        onClick={() => {
                          donorDeleteButtonCallback(donor.id);
                        }}
                      >
                        Confirm?
                      </GeneralButton>
                    ) : (
                      <GeneralButton
                        colour={"red"}
                        onClick={() => {
                          setConfirmDelete(donor.id);
                        }}
                      >
                        Delete
                      </GeneralButton>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="bg-sky-50 mb-14 rounded-xl text-sm font-medium text-neutral-500"
                >
                  Sorry, no matching results!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <nav
        className={`${classes.pages} overflow-x-auto max-w-full flex justify-end`}
      >
        <Pagination
          sx={{
            ".MuiPaginationItem-root": {
              backgroundColor: "var(--light-grey)",
              borderRadius: "0",
            },
            ".Mui-selected": {
              backgroundColor: "var(--default-orange) !important",
              borderRadius: "0",
            },
          }}
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          className={`${classes.pages} flex py-5 pl-5 list-none justify-center`}
          color="primary"
          renderItem={renderItem}
        />
      </nav>
    </div>
  );
};

export default DonorTable;
