import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteMember } from "../../../../api/DeleteMember";
import dashboardClasses from "../../Dashboard.module.css";
import formClasses from "../../DashboardForm.module.css";
import classes from "./MemberTable.module.css";
import newClasses from "../MemberList.module.css";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Pagination, PaginationItem } from "@mui/material";
import { DASHBOARD_MODALS } from "../../Dashboard";
import GeneralButton from "../../../shared/Buttons/GeneralButton";

export const MemberTable = ({ data, setModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [memberList, setMemberList] = useState(data);
  const [localUserData, setLocalUserData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(-1);
  const userData = useSelector((state) => state.userData.userData);
  const [searchParam, setSearchParam] = useState("");

  // Retrieve user data from localStorage
  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Calculate the current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = memberList.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(memberList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;

  const totalPages = Math.ceil(memberList.length / itemsPerPage);

  const memberDeleteButtonCallback = (memberId) => {
    try {
      deleteMember(memberId);
      const tempMemberList = memberList.filter((item) => item.id !== memberId);
      setMemberList(tempMemberList);
      setConfirmDelete(-1);
    } catch (error) {
      console.error("Error deleting in donor table:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  const renderItem = (item) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChange(null, page)}
        />
      );
    }
    return <PaginationItem {...item} />;
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className={dashboardClasses.dashboard_block}>
      <h2 className="text-left text-xl !text-black mt-8 mb-4">
        Team Members List
      </h2>
      <div
        className={`${classes.filter_panel} flex items-center mb-5 my-0 mx-auto gap-16`}
      >
        <div className={`flex items-center relative w-full`}>
          <svg
            className={`absolute top-2 left-2`}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3496 13.3559C13.2563 13.4478 13.1306 13.4994 12.9996 13.4996C12.8668 13.499 12.7393 13.4476 12.6434 13.3559L9.94336 10.6496C8.80622 11.6047 7.34425 12.084 5.86236 11.9874C4.38046 11.8908 2.99306 11.2259 1.98951 10.1313C0.985965 9.03661 0.443754 7.59681 0.475967 6.11212C0.508179 4.62743 1.11233 3.21249 2.16241 2.16241C3.21249 1.11233 4.62743 0.508179 6.11212 0.475967C7.59681 0.443754 9.03661 0.985965 10.1313 1.98951C11.2259 2.99306 11.8908 4.38046 11.9874 5.86236C12.084 7.34426 11.6047 8.80623 10.6496 9.94336L13.3496 12.6434C13.3969 12.6899 13.4344 12.7453 13.46 12.8065C13.4856 12.8677 13.4988 12.9333 13.4988 12.9996C13.4988 13.0659 13.4856 13.1316 13.46 13.1927C13.4344 13.2539 13.3969 13.3093 13.3496 13.3559ZM6.24961 10.9996C7.18907 10.9996 8.10743 10.721 8.88857 10.1991C9.6697 9.67715 10.2785 8.9353 10.638 8.06736C10.9976 7.19941 11.0916 6.24434 10.9083 5.32293C10.7251 4.40152 10.2727 3.55515 9.60837 2.89085C8.94407 2.22655 8.0977 1.77416 7.17629 1.59088C6.25488 1.4076 5.29981 1.50166 4.43186 1.86118C3.56391 2.2207 2.82206 2.82952 2.30013 3.61065C1.77819 4.39178 1.49961 5.31015 1.49961 6.24961C1.50126 7.50888 2.00224 8.7161 2.89268 9.60654C3.78312 10.497 4.99034 10.998 6.24961 10.9996Z"
              fill="var(--grey-icon)"
              fillOpacity="0.5"
            />
          </svg>
          <input
            type="text"
            placeholder="Type to search by name, city, or state"
            value={searchParam}
            onInput={handleSearch}
            className={`p-2 pl-8 border text-black border-gray-300 rounded-lg mb-4 w-full h-7 `}
          />
        </div>
      </div>
      <table className={`${classes.table}`}>
        <thead>
          <tr>
            <th className="text-center">First Name</th>
            <th>Last Name</th>
            <th>Access Level</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems
            .filter((member, index) => {
              return (
                member.first_name
                  .toLowerCase()
                  .includes(searchParam.toLowerCase()) ||
                member.last_name
                  .toLowerCase()
                  .includes(searchParam.toLowerCase())
              );
            })
            .map((member, index) => (
              <tr key={index}>
                <td>{member.first_name}</td>
                <td>{member.last_name}</td>
                <td>
                  {member.np_access_level.charAt(0).toUpperCase() +
                    member.np_access_level.slice(1)}
                </td>
                {localUserData &&
                localUserData.user_type === "nonprofit_admin" &&
                localUserData.id !== member.id ? (
                  <td className="group hover:!bg-[var(--default-red)]">
                    {confirmDelete === member.id ? (
                      <button
                        onClick={() => {
                          memberDeleteButtonCallback(member.id);
                        }}
                        className="group-hover:text-white"
                      >
                        Confirm?
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setConfirmDelete(member.id);
                        }}
                        className="group-hover:text-white"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {localUserData && localUserData.np_access_level === "admin" && (
        <div
          className={`${newClasses.button_container} justify-around items-center block md:hidden`}
        >
          <GeneralButton
            colour="blue"
            className={`${newClasses.donor_button} flex bg-blue text-white border-none cursor-pointer no-underline py-2 md:py-3 px-2`}
            onClick={() => {
              setModal(DASHBOARD_MODALS.addMember);
            }}
          >
            ADD NEW TEAM MEMBER
          </GeneralButton>
        </div>
      )}
      <nav className="overflow-x-auto max-w-full flex justify-center md:justify-end">
        <Pagination
          sx={{
            ".MuiPaginationItem-root": {
              backgroundColor: "var(--light-grey)",
              borderRadius: "0",
            },
            ".Mui-selected": {
              backgroundColor: "var(--default-orange) !important",
              borderRadius: "0",
            },
          }}
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          className="flex p-5 pr-0 list-none justify-center"
          color="primary"
          renderItem={renderItem}
        />
      </nav>
    </div>
  );
};
