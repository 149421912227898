import React, { useEffect, useState } from "react";
import dashboardClasses from "../Dashboard.module.css";
import formClasses from "../DashboardForm.module.css";
import { fosterDetail, fosterUpdate } from "../../../api/FosterSubmit";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import classes from "./EditFosterInterest.module.css";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";

export default function EditFosterInterest({ filledFormData }) {
  const [fosterInfo, setFosterInfo] = useState({ ...filledFormData });
  const [interestFormEnabled, setInterestFormEnabled] = useState(false);
  const [errorState, setErrorState] = useState({ error: "", success: "" });

  const handleFosterInterestChange = (e) => {
    const { name, value } = e.target;
    setFosterInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorState({ error: "", success: "" });
  };

  const handleEditability = () => {
    setInterestFormEnabled((prev) => !prev);
  };

  const handleFosterInterestSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fosterUpdate(fosterInfo.id, fosterInfo);
      setErrorState({
        error: "",
        success: "Successfully Edited Foster Interest Information",
      });
      setInterestFormEnabled(false);
    } catch (error) {
      const errorMessage = errorHandler(error);
      const formattedError = lineBreaks(errorMessage);
      console.error(error.response.data);
      setErrorState({ error: formattedError, success: "" });
    }
  };

  return (
    <div className={`${dashboardClasses.dashboard_content} ${classes.dashboard_content} pt-12 ml-14`}>
      <div className={dashboardClasses.dashboard_block}>
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold my-2 !text-orange">
            Edit Foster Interest Information
          </h2>
          <form
            className={formClasses.form_column}
            onSubmit={handleFosterInterestSubmit}
          >
            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label className={classes.foster_interest_form_label}>
                First Name:
                <br />
                <input
                  type="text"
                  name="first_name"
                  value={fosterInfo.first_name}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                />
              </label>

              <label className={classes.foster_interest_form_label}>
                Last Name:
                <br />
                <input
                  type="text"
                  name="last_name"
                  value={fosterInfo.last_name}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                />
              </label>
            </div>

            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label className={classes.foster_interest_form_label}>
                Email:
                <br />
                <input
                  type="text"
                  name="email"
                  value={fosterInfo.email}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                />
              </label>
              <label className={classes.foster_interest_form_label}>
                Phone:
                <br />
                <input
                  type="number"
                  name="phone"
                  value={fosterInfo.phone}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                />
              </label>
            </div>

            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label className={classes.foster_interest_form_label}>
                Street:
                <br />
                <input
                  type="text"
                  name="street"
                  value={fosterInfo.street}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                />
              </label>
              <label className={classes.foster_interest_form_label}>
                City:
                <br />
                <input
                  type="text"
                  name="city"
                  value={fosterInfo.city}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                />
              </label>
            </div>

            <div
              className={`${formClasses.form_row} ${classes.form_row} !justify-center gap-12 !mb-2`}
            >
              <label className={classes.foster_interest_form_label}>
                State:
                <br />
                <select
                  name="state"
                  value={fosterInfo.state}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-1.5 border text-black border-stone-300 rounded-lg disabled:bg-neutral-50 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="" disabled>
                    Select a state
                  </option>
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                </select>
              </label>
              <label className={classes.foster_interest_form_label}>
                Country:
                <br />
                <select
                  name="country"
                  value={fosterInfo.country}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-1.5 border text-black border-stone-300 rounded-lg disabled:bg-neutral-50 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="US">US</option>
                </select>
              </label>
              <label className={`${classes.foster_interest_form_label} !mt-1`}>
                Zip Code:
                <br />
                <input
                  type="text"
                  name="zip_code"
                  value={fosterInfo.zip_code}
                  onChange={handleFosterInterestChange}
                  disabled={!interestFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                />
              </label>
            </div>

            <div
              className={`${formClasses.form_row} ${classes.button_row} !justify-end gap-4`}
            >
              <GeneralButton
                colour="blue"
                type="button"
                onClick={handleEditability}
              >
                Edit
              </GeneralButton>
              <GeneralButton
                colour="orange"
                type="submit"
                disabled={!interestFormEnabled}
              >
                Save
              </GeneralButton>
            </div>
          </form>
          {errorState.error && (
            <p className="text-red -mt-2">{errorState.error}</p>
          )}
          {errorState.success && (
            <p className="text-green-500 -mt-4">{errorState.success}</p>
          )}
        </div>
      </div>
    </div>
  );
}
