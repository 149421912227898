import { useState } from "react";
import { sendEmail } from "../../../api/SendEmail";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import classes from "./ChooseDonorListModal.module.css";
import formClasses from "../DashboardForm.module.css";

export default function ChooseDonorListModal({
  isOpen,
  onClose,
  onSelect,
  createEmailJson,
  campaignId,
  nonprofitId,
  setShowSuccessMessage,
  setSendEmailError,
  sendEmailError,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);

  const handleSelectionChange = (e) => {
    setSelectedOption(e.target.value);
    if (showConfirmationPrompt) setShowConfirmationPrompt(false);
  };

  const handleSendEmail = async (numericSelection) => {
    const emailData = createEmailJson();
    try {
      const response = await sendEmail(
        emailData,
        numericSelection,
        campaignId,
        nonprofitId
      );
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error sending email: ", error);
      const errMsg = errorHandler(error);
      setSendEmailError(errMsg);
    }
  };

  const handleConfirmSelection = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const optionValues = { full: 0, donated: 1, notDonated: 2 };
    const numericSelection = optionValues[selectedOption];
    onSelect(numericSelection);
    await handleSendEmail(numericSelection); // Make the API call
  };

  // if (!isOpen) return null;

  return (
    <div className={classes.choose_overlay} onClick={onClose}>
      <div
        className={classes.modal_choose_donor}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.choose_cancel_div}>
          <button className={classes.choose_cancel_button} onClick={onClose}>
            X
          </button>
        </div>
        <h2 className="text-2xl font-semibold mb-8">Choose Donor List</h2>
        <div className="flex justify-center cursor-pointer">
          <select
            required
            value={selectedOption}
            onChange={handleSelectionChange}
          >
            <option value="">Select an option</option>
            <option value="full">Full Donor List</option>
            <option value="donated">People who have donated</option>
            <option value="notDonated">People who have not donated</option>
          </select>
        </div>
        <div>
          {!showConfirmationPrompt ? (
            <button
              className={formClasses.submit_button}
              type="button"
              onClick={() => {
                setShowConfirmationPrompt(true);
              }}
            >
              Send
            </button>
          ) : (
            <>
              <h3>Are you sure?</h3>
              <div className={classes.confirmation_prompt_buttons}>
                <button
                  className={formClasses.submit_button}
                  onClick={() => {
                    setShowConfirmationPrompt(false);
                  }}
                >
                  No
                </button>
                <button
                  className={formClasses.submit_button}
                  onClick={(e) => handleConfirmSelection(e)}
                >
                  Yes
                </button>
              </div>
            </>
          )}
          {sendEmailError && (
            <p className="text-[var(--default-red)]">{sendEmailError}</p>
          )}
        </div>
      </div>
    </div>
  );
}
