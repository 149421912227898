import React, { useState, useEffect } from "react";
import { dashboard } from "../../../api/Dashboard";
import { deleteCart, getCart, updateCart } from "../../../api/Cart";
import { getShopProduct } from "../../../api/ShopProduct";
import { updateProfile } from "../../../api/UpdateProfile";
import { createOrderPayment, placeOrder } from "../../../api/ShopOrders";
import { useNavigate } from "react-router-dom";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import classes from "./Cart.module.css";
import NotFound from "../../shared/Utils/NotFound";
import { errorHandler } from "../../shared/Utils/ErrorHandler";

function Cart() {
  const [localUserData, setLocalUserData] = useState(null);
  const [cartObjects, setCartObjects] = useState(null);
  const [shippingFee, setShippingFee] = useState(2.0);
  const [addressError, setAddressError] = useState("");
  const [formData, setFormData] = useState({
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    phone: "",
  });
  const [cartItems, setCartItems] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [elements, setElements] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState({
    error: "",
    status: "",
  });
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [feError, setFEError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          const response = await dashboard(userData.id);
          setLocalUserData(response);
          if (response.address) {
            setFormData({
              address: {
                address1: response.address.address1 || "",
                address2: response.address.address2 || "",
                city: response.address.city || "",
                state: response.address.state || "",
                country: response.address.country || "",
                zip: response.address.zip || "",
              },
              phone: response.phone || "",
              email: response.email || "",
            });
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
              phone: response.phone || "",
              email: response.email || "",
            }));
          }
          localStorage.setItem("userData", JSON.stringify(response));
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const getCartItems = async () => {
      try {
        const cartData = await getCart();
        setCartObjects(cartData || []);
      } catch (error) {
        console.error("Failed to fetch cart items:", error);
        setCartObjects([]);
      }
    };

    getCartItems();
  }, [localUserData]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (localUserData && cartObjects) {
        try {
          const productDetailsPromises = cartObjects.map(
            (cartItem) => getShopProduct(cartItem.product_id) // Assuming getShopProduct takes product_id as argument
          );
          const products = await Promise.all(productDetailsPromises);

          const results = cartObjects
            .map((cartItem, index) => {
              const product = products[index]; // Matching product by order
              if (product && product.variants) {
                const variant = product.variants.find(
                  (variant) => variant.variant_id === cartItem.variant_id
                );
                if (variant) {
                  return {
                    product_id: product.product_id,
                    productTitle: product.title,
                    variantDetail: variant,
                    variant_id: variant.variant_id,
                    quantity: cartItem.quantity,
                    image_url: cartItem.image_url,
                  };
                }
              }
              return null;
            })
            .filter((result) => result !== null);

          setCartItems(results);
        } catch (error) {
          console.error("Failed to fetch product details:", error);
        }
      }
    };

    fetchProductDetails();
  }, [localUserData, cartObjects]);

  useEffect(() => {
    if (cartItems) {
      const newSubtotal =
        cartItems.reduce(
          (acc, item) => acc + (item.variantDetail.price / 100) * item.quantity,
          0
        ) + 2.0;
      setSubtotal(newSubtotal);
    }
  }, [cartItems]);

  useEffect(() => {
    if (clientSecret) {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
      const appearance = {
        /* appearance */
      };
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const elementsInstance = stripeInstance.elements({
        clientSecret,
        appearance,
      });
      const cardElement = elementsInstance.create("payment", options);
      cardElement.mount("#payment-element");

      cardElement.on("ready", () => {
        setIsStripeLoading(false); // Set loading to false when the card element is ready
      });

      setStripe(stripeInstance);
      setElements(elementsInstance);
    }
  }, [clientSecret]);

  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Ensure both stripe and elements are available
      return;
    }

    // The 'payment' type refers to the Payment Element which can handle multiple payment methods
    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentResponse({ error: "Payment Element is not loaded." });
      return;
    }

    setIsStripeLoading(true); // Indicate loading process

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      // The PaymentIntent status will be returned in the paymentIntent object, and you can handle it as needed
      if (error) {
        throw error;
      } else {
        // Handle other statuses accordingly
        if (paymentIntent && paymentIntent.status === "succeeded") {
          await placeOrder({ cart: cartItems });
          setPaymentResponse({ status: "success" });
        } else {
          // Payment processing or requires further action
          setPaymentResponse({ error: error });
        }
      }
    } catch (error) {
      setPaymentResponse({ error: error.message });
      console.error(error);
    } finally {
      setIsStripeLoading(false);
    }
  };

  const handleQuantityChange = async (e, index) => {
    const newQuantity = parseInt(e.target.value);
    const updatedItems = cartItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedItems);
    await updateCart(updatedItems);
  };

  const handleChange = (product_id) => {
    navigate(`/shop/${product_id}`);
  };

  const handleDelete = async (index, product_id, variant_id) => {
    // Filter out the item at the given index
    const updatedItems = cartItems.filter((_, idx) => idx !== index);
    setCartItems(updatedItems);
    const response = await deleteCart({
      product_id: product_id,
      variant_id: variant_id,
    });

    // Redirect to /shop if the cart is empty
    if (updatedItems.length === 0) {
      navigate("/shop");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: {
        ...prevFormData.address,
        [name]: value,
      },
    }));
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    setAddressError("");
    try {
      if (localUserData) {
        const response = await updateProfile(localUserData.id, formData);
        alert("Address Updated Successfully!");
      } else {
        console.error("No local user data!");
      }
    } catch (error) {
      console.error("Threw Error with: ", formData, localUserData.id);
      const errorMsg = errorHandler(error);
      setAddressError(errorMsg);
    }
  };

  const handleCreatePayment = async (e) => {
    e.preventDefault();
    try {
      if (
        formData.address &&
        formData.address.address1 &&
        formData.address.city &&
        formData.address.state &&
        formData.address.country &&
        formData.address.zip &&
        formData.phone // Ensure phone is also validated
      ) {
        const response = await createOrderPayment({ amount: subtotal });
        setClientSecret(response.client_secret);
        setFEError("");
      } else {
        throw new Error(
          "Please ensure Address Fields and Phone Number below are correctly filled"
        );
      }
    } catch (error) {
      setFEError(error.message);
    }
  };

  if (!localUserData) {
    return <NotFound />;
  }

  return (
    <div className={`main-div ${classes.shopping_cart}`}>
      <h1>Shopping Cart</h1>
      <div className={classes.cart_page}>
        <div className="w-[70%]">
          {cartItems &&
            cartItems.map((item, index) => (
              <div key={index} className={classes.cart_item_card}>
                <div className="flex flex-row">
                  <div className={classes.image_box}>
                    <img src={item.image_url} alt={item.image_url} />
                  </div>
                  <div className={classes.info_block}>
                    <h3 className="text-xl font-semibold mb-4">
                      {item.productTitle}
                    </h3>
                    <p className="m-0">Quantity:</p>
                    <select
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(e, index)}
                      className={classes.quantity_selector}
                    >
                      {Array.from({ length: 10 }, (_, i) => i + 1).map(
                        (option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className={classes.price_and_actions}>
                  <h3 className="text-xl font-semibold">
                    ${(item.variantDetail.price / 100).toFixed(2)}
                  </h3>
                  <div className={classes.action_buttons_cart}>
                    <button
                      className="border p-2 rounded-md bg-blue text-white"
                      onClick={() => handleChange(item.product_id)}
                    >
                      Change
                    </button>
                    <button
                      className="border p-2 rounded-md bg-red-600 text-white"
                      onClick={() =>
                        handleDelete(
                          index,
                          item.product_id,
                          item.variantDetail.variant_id
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {cartItems && cartItems.length > 0 && (
          <div className={classes.checkout_section}>
            <h2 className="text-2xl font-bold">Subtotal: ${subtotal}</h2>
            <p>Shipping fee: ${shippingFee} (included above)</p>
            {clientSecret ? (
              <form
                id="payment-form"
                className={classes.payment_form}
                onSubmit={handleSubmitPayment}
              >
                {/* Stripe Element will be mounted here */}
                <div id="payment-element"></div>
                <GeneralButton
                  className="rounded-[4px] w-52 !font-bold "
                  type="submit"
                  disabled={isStripeLoading}
                  colour="blue"
                >
                  Complete Payment
                </GeneralButton>
                {paymentResponse.error && (
                  <p className="text-[var(--default-red)]">
                    {paymentResponse.error}
                  </p>
                )}
              </form>
            ) : (
              <>
                <GeneralButton
                  className="rounded-sm p-2 !font-bold"
                  onClick={handleCreatePayment}
                  colour="orange"
                  disabled={
                    !formData.address.address1 ||
                    !formData.address.city ||
                    !formData.address.state ||
                    !formData.address.country ||
                    !formData.address.zip ||
                    !formData.phone
                  }
                >
                  Proceed to Checkout
                </GeneralButton>
                {feError && (
                  <p className="text-[var(--default-red)]">{feError}</p>
                )}
              </>
            )}
            {paymentResponse && paymentResponse.status ? (
              <p>Payment succeeded! Your order has been placed!</p>
            ) : (
              ""
            )}
            <hr />
            <h2 className="text-2xl font-bold">Delivery Address</h2>
            <form onSubmit={handleAddressSubmit}>
              <div className={classes.address_form}>
                <label htmlFor="address1">Street Address</label>
                <input
                  type="text"
                  id="address1"
                  name="address1"
                  value={formData.address.address1}
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="address2">Unit/Apartment</label>
                <input
                  type="text"
                  id="address2"
                  name="address2"
                  value={formData.address.address2}
                  onChange={handleInputChange}
                />
                <div className={classes.inline_fields}>
                  <div>
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={formData.address.city}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="state">State/Region</label>
                    <select
                      id="state"
                      name="state"
                      value={formData.address.state}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select State</option>
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AZ">AZ</option>
                      <option value="AR">AR</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="IA">IA</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="ME">ME</option>
                      <option value="MD">MD</option>
                      <option value="MA">MA</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MS">MS</option>
                      <option value="MO">MO</option>
                      <option value="MT">MT</option>
                      <option value="NE">NE</option>
                      <option value="NV">NV</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NY">NY</option>
                      <option value="NC">NC</option>
                      <option value="ND">ND</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WV">WV</option>
                      <option value="WI">WI</option>
                      <option value="WY">WY</option>
                    </select>
                  </div>
                </div>
                <div className={classes.inline_fields}>
                  <div>
                    <label htmlFor="country">Country</label>
                    <select
                      id="country"
                      name="country"
                      value={formData.address.country}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Country</option>
                      <option value="US">US</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="zip">Zip Code</label>
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      value={formData.address.zip}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="phone">Phone Number (E.g. 1234567890)</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    required
                  />
                </div>
                <GeneralButton
                  type="submit"
                  colour="blue"
                  className="rounded-sm text-md !font-bold"
                >
                  Save Address
                </GeneralButton>
                {addressError && <div>{addressError}</div>}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Cart;
