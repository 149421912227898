import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getEvents } from "../../../api/Events";
import classes from "./EventSearch.module.css";
import { FormControl, InputLabel, TextField, MenuItem, Grid, useMediaQuery } from '@mui/material';
import Select from '@mui/material/Select';
import EventCard from "./EventCard";
import { toZonedTime } from "date-fns-tz";

// Arrow for drop down buttons
const CustomArrowIcon = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.5L6 6.5L11 1.5" stroke="black" strokeOpacity="0.56" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const weekdays = ["Weekday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

/* This page shows all upcoming and previous events
  Allows to search by event name, nonprofit name, city, and state
  Allows filtering of weekdays on top of the search (if search is on)
*/
function EventSearch() {
  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [previousEvents, setPreviousEvents] = useState([]);
  const [searchUpcomingEvents, setSearchUpcomingEvents] = useState([]);
  const [searchPreviousEvents, setSearchPreviousEvents] = useState([]);
  const [filterUpcomingEvents, setFilterUpcomingEvents] = useState(upcomingEvents);
  const [filterPreviousEvents, setFilterPreviousEvents] = useState(previousEvents);
  const [upcomingVisibleCount, setUpcomingVisibleCount] = useState(3);
  const [previousVisibleCount, setpreviousVisibleCount] = useState(3);
  const [ifSearch, setIfSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [selectedUpcoming, setSelectedUpcoming] = useState({
    weekday: 0,
  });
  const [selectedPrevious, setSelectedPrevious] = useState({
    weekday: 0,
  });
  const [stateSearch, setStateSearch] = useState("");
  const [searchCriteria, setSearchCriteria] = useState({
    event: "",
    nonprofit: "",
    city: "",
    state: "",
  })

  const navigate = useNavigate();

  const loadMoreUpcomingEvents = () => {
    setUpcomingVisibleCount(prevCount => prevCount + 3);
  };

  const loadMorePreviousEvents = () => {
    setpreviousVisibleCount(prevCount => prevCount + 3);
  };

  // Fetching all events
  useEffect(() => {
    const getAllEvents = async () => {
      try {
        const response = await getEvents();
        setEvents(response.reverse());
      } catch (e) {
      }
    }
    getAllEvents();
  }, []);

  // Splitting events to upcoming and previous
  useEffect(() => {
    let upcoming = [];
    let previous = [];

    const todayDate = new Date().toISOString().substring(0, 19);

    for (const idx in events) {
      if (events[idx]["datetime"].substring(0, 19) >= todayDate) {
        upcoming.push(events[idx]);
      } else {
        previous.push(events[idx]);
      }
    }

    setUpcomingEvents(upcoming);
    setPreviousEvents(previous);
    setFilterUpcomingEvents(upcoming);
    setFilterPreviousEvents(previous);
  }, [events]);

  // Function to search to events based on search inputs
  const searchEvents = (events, criteria, type = "") => {
    let filteredUpEvents = [];
    let filteredPrevEvents = [];
    setIfSearch(true);

    const valueEvent = criteria.event ? criteria.event.toLowerCase() : "";
    const valueNonprofit = criteria.nonprofit ? criteria.nonprofit.toLowerCase() : "";
    const valueCity = criteria.city ? criteria.city.toLowerCase() : "";
    const valueState = criteria.state ? criteria.state : "";

    if (type === "upcoming") {
        filteredUpEvents = events.filter(event =>
          (valueEvent !== "" ? event.title.toLowerCase().includes(valueEvent): true) &&
          (valueNonprofit !== "" ? event.nonprofit.toLowerCase().includes(valueNonprofit): true) &&
          (valueCity !== "" ? event.address.city.toLowerCase().includes(valueCity): true) &&
          (valueState !== "" ? event.address.state.includes(valueState): true)
        );
      return filteredUpEvents;
    } else {
      filteredPrevEvents = events.filter(event =>
        (valueEvent !== "" ? event.title.toLowerCase().includes(valueEvent): true) &&
        (valueNonprofit !== "" ? event.nonprofit.toLowerCase().includes(valueNonprofit): true) &&
        (valueCity !== "" ? event.address.city.toLowerCase().includes(valueCity): true) &&
        (valueState !== "" ? event.address.state.includes(valueState): true)
      );
    return filteredPrevEvents;
    }
  };

  // Function to set search criteria state and search results
  // State dropdown bypasses 'Enter' key for search
  const handleSearchChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }))

    if (value === "" || value === "State") {
      setIfSearch(false);
      setStateSearch(value);
    }

    if (name === "state" && value !== "State") {
      triggerSearch({
        ...searchCriteria,
        [name]: value
      });
      setStateSearch(value);
    }
  };

  // Function that triggers a search when 'Enter' is pressed
  // Searching is not automatic
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      triggerSearch(searchCriteria);
    }
  };

  // Triggers searchEvents function and sets state
  const triggerSearch = (criteria) => {
    let upcomingResults = searchEvents(upcomingEvents, criteria, "upcoming");
    let prevResults = searchEvents(previousEvents, criteria, "previous");
  
    setSearchUpcomingEvents(upcomingResults);
    setSearchPreviousEvents(prevResults);
  };

  // Function to filter results based on weekday
  const filterResults = (events, weekday) => {
    return events.filter((event) => {
      const eventDate = new Date(event.datetime);
      const zonedDate = toZonedTime(eventDate, 'UTC');
      const eventWeekday = zonedDate.getUTCDay();

      if (weekday === 0) {
        setIsFilter(false);
        return true;
      }

      return eventWeekday + 1 === weekday;
    });
  };

  // Handles drop down selection for weekdays on UPCOMING events
  const handleUpcomingChange = (e) => {
    const { name, value } = e.target;
    setSelectedUpcoming((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const weekday = value;
    let upcomingResults;
    setIsFilter(true);

    if (!ifSearch) {
      upcomingResults = filterResults(upcomingEvents, weekday);
    } else {
      upcomingResults = filterResults(searchUpcomingEvents, weekday);
    }

    setFilterUpcomingEvents(upcomingResults);
  };

  // Handles drop down selection for weekdays on PREVIOUS events
  const handlePreviousChange = (e) => {
    const { name, value } = e.target;
    setSelectedPrevious((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const weekday = value;
    let previousResults;
    setIsFilter(true);

    if (!ifSearch) {
      previousResults = filterResults(previousEvents, weekday);
    } else {
      previousResults = filterResults(searchPreviousEvents, weekday);
    }

    setFilterPreviousEvents(previousResults);
  };

  // Handles clicking 'Contact Us' button
  const handleContactButton = () => {
    navigate("/contact-us", { state: { fromEvents: true } });
  };

  // Sets conditional UI styles
  const matchesMax500px = useMediaQuery('(max-width: 500px)');

  return (
    <>
      <div className={`main-div !mb-0`}>
        <section className={`${classes.top_section} relative w-full h-80`}>
          <img
            className={`${classes.dog_img} relative w-full h-auto z-0`}
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/events-dog-img.png"
            alt="Events Dog"
          />
          <div
            className={`${classes.gradient_box} absolute z-10 w-full h-full opacity-90`}
          ></div>

          <section
            className={`absolute flex items-center z-30 left-2/4  ${classes.search_box} `}
          >
            <div
              className={`${classes.search_inputs} flex flex-wrap flex-row gap-10 w-full justify-center items-center`}
            >
              <FormControl
                sx={{ m: 1, width: 215, color: "white", borderBottom: "white" }}
                variant="standard"
                size="small"
                fullWidth
              >
                <TextField
                  id="event"
                  variant="standard"
                  name="event"
                  size="small"
                  label="Search Event Name"
                  inputProps={{
                    style: { color: "white" },
                    className: `${classes.inputfields}`,
                    onKeyDown: handleKeyDown,
                  }}
                  onChange={handleSearchChange}
                />
              </FormControl>
              <FormControl
                sx={{ m: 1, width: 215, color: "white" }}
                variant="standard"
                size="small"
                fullWidth
              >
                <TextField
                  id="nonprofit"
                  variant="standard"
                  name="nonprofit"
                  size="small"
                  label="Search Nonprofit Name"
                  inputProps={{
                    style: { color: "white" },
                    className: `${classes.inputfields}`,
                    onKeyDown: handleKeyDown,
                  }}
                  onChange={handleSearchChange}
                />
              </FormControl>
              <FormControl
                sx={{ m: 1, width: 215, color: "white" }}
                variant="standard"
                size="small"
                fullWidth
              >
                <TextField
                  id="city"
                  variant="standard"
                  name="city"
                  size="small"
                  label="Search City"
                  inputProps={{
                    style: { color: "white" },
                    className: `${classes.inputfields}`,
                    onKeyDown: handleKeyDown,
                  }}
                  onChange={handleSearchChange}
                />
              </FormControl>
              <FormControl 
                sx={{ m: 1, width: 65, color: "white" }}
                variant="standard"
                size="small"
                fullWidth
              >
                <InputLabel id="state">State</InputLabel>
                <Select
                  id="state"
                  name="state"
                  variant="standard"
                  value={stateSearch}
                  label="State"
                  size="small"
                  inputProps={{
                    style: { color: "white" },
                    className: `${classes.inputfields}`,
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // set maximum height for the dropdown menu
                      },
                    },
                  }}
                  IconComponent={CustomArrowIcon}
                  onChange={handleSearchChange}
                >
                  <MenuItem value="State">State</MenuItem>
                  {states.map((state, index) => 
                    <MenuItem key={index} value={state}>{state}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </section>
        </section>

        {ifSearch &&
        searchUpcomingEvents.length === 0 &&
        searchPreviousEvents.length === 0 ? (
          <h2 className={`${classes.no_events} mt-36 mb-24 text-center`}>No events matching search!</h2>
        ) : null}

        {ifSearch && searchUpcomingEvents.length > 0 ? (
          <section
            className={`${
              searchPreviousEvents.length > 0
                ? `${classes.upcoming_events_search} flex justify-center items-center`
                : `${classes.upcoming_events_single} flex justify-center items-center`
            } relative flex flex-col`}
          >
            <div className={`${classes.filter_section} flex flex-row justify-between items-center my-auto`}>
              <h2 className={`${classes.event_title} text-4xl font-normal`}>Upcoming Events</h2>
              <div>
                <Select
                  variant="outlined"
                  sx={{
                    width: "168px",
                    height: "46px",
                    border: "2px solid var(--grey-dropdown-bg)",
                    borderRadius: "50px",
                    color: "var(--dark-grey)",
                    background: "var(--grey-dropdown-bg)",
                    paddingRight: "20px",
                    marginRight: "5px",
                  }}
                  value={selectedUpcoming.weekday}
                  IconComponent={CustomArrowIcon}
                  onChange={handleUpcomingChange}
                  name="weekday"
                >
                  {weekdays.map((weekday, idx) => (
                    <MenuItem key={weekday} value={idx}>
                      {weekday}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={`${classes.cards_container} flex flex-row flex-wrap justify-center w-full gap-5`}>
              <Grid container spacing={3}>
                {!isFilter
                  ? searchUpcomingEvents
                      .slice(0, upcomingVisibleCount)
                      .map((event, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                          <EventCard eventInfo={event} />
                        </Grid>
                      ))
                  : filterUpcomingEvents
                      .slice(0, upcomingVisibleCount)
                      .map((event, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                          <EventCard eventInfo={event} />
                        </Grid>
                      ))}
              </Grid>
              {!isFilter &&
                upcomingVisibleCount < searchUpcomingEvents.length && (
                  <button className={`text-lg font-bold text-center`} onClick={loadMoreUpcomingEvents}>Load More</button>
                )}
              {isFilter &&
                upcomingVisibleCount < filterUpcomingEvents.length && (
                  <button className={`text-lg font-bold text-center`} onClick={loadMoreUpcomingEvents}>Load More</button>
                )}
            </div>
          </section>
        ) : null}

        {ifSearch && searchPreviousEvents.length > 0 ? (
          <section
            className={`${
              searchUpcomingEvents.length > 0
                ? `${classes.previous_events_search} flex flex-col justify-center items-center`
                : `${classes.previous_events_single} flex flex-col justify-center items-center`
            } relative`}
          >
            <div className={`${classes.filter_section} flex flex-row justify-between items-center`}>
              <h2 className={`${classes.event_title} text-4xl font-normal`}>Previous Events</h2>
              <div>
                <Select
                  variant="outlined"
                  sx={{
                    width: "168px",
                    height: "46px",
                    border: "2px solid var(--grey-dropdown-bg)",
                    borderRadius: "50px",
                    color: "var(--dark-grey)",
                    background: "var(--grey-dropdown-bg)",
                    paddingRight: "20px",
                    marginRight: "5px",
                  }}
                  value={selectedPrevious.weekday}
                  IconComponent={CustomArrowIcon}
                  onChange={handlePreviousChange}
                  name="weekday"
                >
                  {weekdays.map((weekday, idx) => (
                    <MenuItem key={weekday} value={idx}>
                      {weekday}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={`${classes.cards_container} flex flex-row flex-wrap justify-center w-full gap-5`}>
              <Grid container spacing={3}>
                {!isFilter
                  ? searchPreviousEvents
                      .slice(0, previousVisibleCount)
                      .map((event, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                          <EventCard eventInfo={event} />
                        </Grid>
                      ))
                  : filterPreviousEvents
                      .slice(0, previousVisibleCount)
                      .map((event, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                          <EventCard eventInfo={event} />
                        </Grid>
                      ))}
              </Grid>
              {!isFilter &&
                previousVisibleCount < searchPreviousEvents.length && (
                  <button className={`text-lg font-bold text-center`} onClick={loadMorePreviousEvents}>Load More</button>
                )}
              {isFilter &&
                previousVisibleCount < filterPreviousEvents.length && (
                  <button className={`text-lg font-bold text-center`} onClick={loadMoreUpcomingEvents}>Load More</button>
                )}
            </div>
          </section>
        ) : null}

        {!ifSearch && (
          <section
            className={`${classes.upcoming_events_box} relative flex flex-col justify-center items-center`}
          >
            <div className={`${classes.filter_section} flex flex-row justify-between items-center`}>
              <h2 className={`${classes.event_title} text-4xl font-normal`}>Upcoming Events</h2>
              <div>
                <Select
                  variant="outlined"
                  sx={{
                    width: "168px",
                    height: "46px",
                    border: "2px solid var(--grey-dropdown-bg)",
                    borderRadius: "50px",
                    color: "var(--dark-grey)",
                    background: "var(--grey-dropdown-bg)",
                    paddingRight: "20px",
                    marginRight: "5px",
                  }}
                  value={selectedUpcoming.weekday}
                  IconComponent={CustomArrowIcon}
                  onChange={handleUpcomingChange}
                  name="weekday"
                >
                  {weekdays.map((weekday, idx) => (
                    <MenuItem key={weekday} value={idx}>
                      {weekday}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className={`${classes.cards_container} flex flex-row flex-wrap justify-center w-full gap-5`}>
              <Grid container spacing={3}>
                {filterUpcomingEvents
                  .slice(0, upcomingVisibleCount)
                  .map((event, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                      <EventCard eventInfo={event} />
                    </Grid>
                  ))}
                
              </Grid>

              {upcomingVisibleCount < filterUpcomingEvents.length && (
                <button className={`text-lg font-bold text-center`} onClick={loadMoreUpcomingEvents}>Load More</button>
              )}

              {filterUpcomingEvents.length === 0 && (
                  <h2 className={`m-8`}>
                    No events to show yet. Stay tuned for updates!
                  </h2>
              )}
            </div>
          </section>
        )}

        <div className="flex flex-col-reverse sm:flex-col">
          <section className={`${classes.create_banner} z-0 w-full relative bg-sky-100 
            ${ifSearch || matchesMax500px ? "mb-0" : "mb-12"}
            ${ifSearch &&
              searchUpcomingEvents.length === 0 &&
              searchPreviousEvents.length === 0 ? "mt-32" : ""}`}>
            <div
              className={`${classes.img_text} relative flex sm:flex-col-reverse justify-center items-center z-10`}
            >
              <img
                className={`relative z-10 h-auto`}
                src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/berner-sennenhund-puppy-yellow+1+1.png"
                alt="A cute bernard dog"
              />
              <div
                className={`${classes.text_box} relative flex flex-col align-start h-auto gap-5 text-left break-words`}
              >
                <h2 className={`text-4xl font-normal`}>Make your own Event</h2>
                <p className={`text-lg font-normal text-zinc-800`}>
                  Get in touch for more information on how you can create your
                  own event with Sponsor a Pet.
                </p>
                <button
                  className={`h-auto w-full text-center p-3.5 font-bold text-base`}
                  onClick={handleContactButton}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </section>

          {!ifSearch && (
            <section
              className={`${classes.previous_events_box} flex flex-col justify-center items-center relative h-auto`}
            >
              <div className={`${classes.filter_section} flex flex-row justify-between items-center`}>
                <h2 className={`${classes.event_title} text-4xl font-normal`}>Previous Events</h2>
                <div>
                  <Select
                    variant="outlined"
                    sx={{
                      width: "168px",
                      height: "46px",
                      border: "2px solid var(--grey-dropdown-bg)",
                      borderRadius: "50px",
                      color: "var(--dark-grey)",
                      background: "var(--grey-dropdown-bg)",
                      paddingRight: "20px",
                      marginRight: "5px",
                    }}
                    value={selectedPrevious.weekday}
                    IconComponent={CustomArrowIcon}
                    onChange={handlePreviousChange}
                    name="weekday"
                  >
                    {weekdays.map((weekday, idx) => (
                      <MenuItem key={weekday} value={idx}>
                        {weekday}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={`${classes.cards_container} flex flex-row flex-wrap justify-center w-full gap-5`}>
                <Grid container spacing={3}>
                  {filterPreviousEvents
                    .slice(0, previousVisibleCount)
                    .map((event, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                        <EventCard eventInfo={event} />
                      </Grid>
                    ))}
                </Grid>
                {previousVisibleCount < filterPreviousEvents.length && (
                  <button className={`text-lg font-bold text-center`} onClick={loadMorePreviousEvents}>Load More</button>
                )}
                {filterPreviousEvents.length === 0 && (
                  <h2 className={`m-8`}>
                    No events to show yet. Stay tuned for updates!
                  </h2>
                )}
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
}

export default EventSearch;
