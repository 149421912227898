import axios from "axios";
import { getCampaign } from "./Campaigns";

const API_URL = process.env.REACT_APP_API_URL;

export const getCampaignUpdate = async (update_id) => {
	try {
    const response = await axios.get(`${API_URL}/nonprofits/campaign-update/${update_id}`);
    return response.data
	} catch (error) {
		 if (error.response && error.response.status === 500) {
       console.error("500 Internal Server Error");
       throw error;
     } else {
       console.error("get campaign update error:", error);
       throw error;
     }
	}
};

export const getCampaignUpdates = async (nonprofit_name, animal_name, campaign_id) => {
	try {
    const campaign = await getCampaign(nonprofit_name, animal_name, campaign_id);
    let retval = []
    const requests = campaign.updates.map((update_id) => axios.get(`${API_URL}/nonprofits/campaign-update/${update_id}`));
    axios.all(requests).then(responses => {
      responses.forEach(response => {
        retval.unshift(response.data);
      })
    })
    return retval;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign updates error:", error);
      throw error;
    }
	}
};