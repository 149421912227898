import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { updateProfile } from "../../../api/UpdateProfile";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import classes from "./Profile.module.css";
import dashboardClasses from "../Dashboard.module.css";
import { useSelector } from "react-redux";

function Profile() {
  const [profileFormEnabled, setProfileFormEnabled] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone: "",
  });
  const [localUserData, setLocalUserData] = useState(null);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const userData = useSelector((state) => state.userData.userData);

  // Retrieve user data from localStorage
  useEffect(() => {
    try {
      if (Object.keys(userData).length !== 0) {
        const parsedUserData = JSON.parse(userData);
        setLocalUserData(parsedUserData);
        setFormData({
          first_name: parsedUserData.first_name,
          last_name: parsedUserData.last_name,
          username: parsedUserData.email,
          email: parsedUserData.email,
          phone: parsedUserData.phone,
        });
      } else {
        throw new Error("Error parsing data");
      }
    } catch (error) {
      console.error("Failed to parse user data: ", error);
      // Optional: Clear the localStorage items if they are corrupted
      localStorage.removeItem("userData");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      // Optional: Redirect to login or perform other cleanup actions
      navigate("/");
    }
  }, [navigate, userData]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");

    try {
      if (localUserData) {
        const response = await updateProfile(localUserData.id, formData);
        setUpdateSuccess("Updated successfully!");
        setProfileFormEnabled(false);
        setTimeout(() => setUpdateSuccess(""), 3000); // Clear success message after 3 seconds
      } else {
        console.error("No local user data!");
      }
    } catch (error) {
      setUpdateSuccess("");
      console.error("Threw Error with: ", formData, localUserData.id);
      const errorMessage = errorHandler(error);
      setErrorMsg(errorMessage);
      setTimeout(() => setErrorMsg(""), 3000); // Clear error message after 3 seconds
    }
  };

  // Handle entering edit mode
  const enableEdits = () => {
    setProfileFormEnabled(!profileFormEnabled);
  };

  const openPasswordModal = () => {
    setPasswordModal(true);
    document.body.style.overflow = "hidden";
  };
  const closePasswordModal = () => {
    setPasswordModal(false);
    document.body.style.overflow = "";
  };

  return (
    <div className={`${dashboardClasses.dashboard_content} ${classes.dashboard_content} pt-12 ml-14`}>
      <div
        className={`flex flex-col`}
      >
        <h1 className="text-orange !text-3xl mb-6">
          Welcome back, {localUserData ? localUserData.first_name : `User`}{" "}
          {localUserData ? localUserData.last_name : ``}!
        </h1>
        <form className={`w-full text-right`} onSubmit={handleSubmit}>
          <div className={`${classes.profile_form_row} w-full flex flex-row text-left gap-5`}>
            <div className={`${classes.profile_form_column} flex flex-col gap-6`}>
              <label className="text-sm font-medium text-stone-500">
                First Name
                <br />
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  disabled={!profileFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                  required
                />
              </label>
              <label className="text-sm font-medium text-stone-500">
                Email/Username
                <br />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={!profileFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                  required
                />
              </label>
            </div>
            <div className={`${classes.profile_form_column} flex flex-col gap-6`}>
              <label className="text-sm font-medium text-stone-500">
                Last Name
                <br />
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  disabled={!profileFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                  required
                />
              </label>
              <label className="text-sm font-medium text-stone-500">
                Phone
                <br />
                <input
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  disabled={!profileFormEnabled}
                  className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50"
                  required
                />
              </label>
            </div>
          </div>

          <div className={`${classes.buttons} flex flex-row justify-end gap-6 mt-12 mb-4`}>
            <GeneralButton 
              colour="blue" 
              type="button" 
              onClick={enableEdits}
              className={`!p-0 w-44 h-9 text-xl text-white font-medium uppercase !rounded-lg`}
            >
              Edit
            </GeneralButton>
            <GeneralButton
              colour="orange"
              type="submit"
              disabled={!profileFormEnabled}
              className={`!p-0 disabled:bg-neutral-500 bg-orange w-44 h-9 text-xl text-white font-medium uppercase !rounded-lg`}
            >
              Save
            </GeneralButton>
          </div>
          <div className={`flex items-center justify-center`}>
            {updateSuccess && <p className="text-green-500">{updateSuccess}</p>}
            {errorMsg && (
              <p className="text-[var(--default-red)]">{errorMsg}</p>
            )}
          </div>
        </form>
      </div>
      <div
        className={`mt-16`}
      >
        <GeneralButton
          colour="blue"
          className={`w-1/2 !p-0 h-9 text-lg text-white font-semibold cursor-pointer !rounded-3xl`}
          onClick={openPasswordModal}
        >
          Change Password
        </GeneralButton>
        {passwordModal === true ? (
          <ChangePasswordModal close={closePasswordModal} />
        ) : (
          ""
        )}
      </div>
      {localUserData &&
        localUserData?.user_type === "nonprofit_member" &&
        !localUserData.nonprofit && (
          <div
            className={`flex flex-col`}
          >
            <p>Watch this guide on how to setup your Nonprofit!</p>
            <div className="ml-16 my-8">
              <iframe
                width="720"
                height="400"
                src="https://www.youtube.com/embed/WNJb-iSFC3M?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
                title="How to Connect Your Stripe Account"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
    </div>
  );
}

export default Profile;
