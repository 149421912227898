import React, { useEffect, useState } from "react";
import { errorHandler } from "../Utils/ErrorHandler";
import { forgotPassword } from "../../../api/ForgotPassword";
import GeneralButton from "../Buttons/GeneralButton";
import classes from "./ForgotPassword.module.css";

function ForgotPassword() {
  const [formData, setFormData] = useState({
    username: "",
  });
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await forgotPassword(formData);
      setErrorMsg(data.message);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setErrorMsg(errorMessage);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.fp_page}>
      <form className={classes.fp_card} onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold text-orange mb-4">Forgot Password</h2>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          placeholder="Email"
        />
        <GeneralButton
          colour="blue"
          className="mt-0 w-28 rounded-[4px] text-[1rem] font-normal"
          type="submit"
        >
          Submit
        </GeneralButton>
        {errorMsg && <p className="text-red-500">{errorMsg}</p>}
      </form>
    </div>
  );
}

export default ForgotPassword;
