import { useParams } from "react-router-dom";
import CampaignDonorsBlock from "../CampaignDonorsBlock/CampaignDonorsBlock";
import CampaignUpdatesBlock from "../CampaignUpdatesBlock/CampaignUpdatesBlock";
import { useEffect, useState } from "react";
import { getCampaignUpdates } from "../../../api/CampaignUpdates";
import classes from "./CampaignTabbedInfoBlock.module.css";

export default function CampaignTabbedInfoBlock() {
  const [tab, setTab] = useState("updates");
  const { nonprofit_name, animal_name, campaign_id } = useParams(); // Access the id parameter using useParams hook
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    const fetchCampaignUpdates = async () => {
      let response = await getCampaignUpdates(
        nonprofit_name,
        animal_name,
        campaign_id
      );
      return response;
    };
    const fetchAll = async () => {
      const tempUpdates = await fetchCampaignUpdates();
      setUpdates(tempUpdates);
    };
    try {
      fetchAll();
    } catch (error) {
      console.error(error);
    }
  }, [nonprofit_name, animal_name, campaign_id]);

  return (
    <>
      <div className={classes.campaign_tabbed_info}>
        <div className={classes.campaign_tabbed_tab_container}>
          <div
            className={
              tab === "updates"
                ? `${classes.campaign_tabbed_tab} ${classes.campaign_tabbed_tab_selected}`
                : classes.campaign_tabbed_tab
            }
            onClick={() => {
              setTab("updates");
            }}
          >
            Updates
          </div>
          <div
            className={
              tab === "donors"
                ? `${classes.campaign_tabbed_tab} ${classes.campaign_tabbed_tab_selected}`
                : classes.campaign_tabbed_tab
            }
            onClick={() => {
              setTab("donors");
            }}
          >
            Donors
          </div>
        </div>
        {tab === "updates" ? <CampaignUpdatesBlock data={updates} /> : ""}
        {tab === "donors" ? <CampaignDonorsBlock /> : ""}
      </div>
    </>
  );
}
