import React from "react";

const CustomImage = ({ src, alt }) => {
  return (
    <div className="w-[30rem] h-[30rem] relative">
      <img
        className="w-full h-auto block object-cover pt-16"
        src={src}
        alt={alt}
      />
    </div>
  );
};

export default CustomImage;
