import React, { useEffect, useState } from "react";
import { login } from "../../api/Login";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralButton from "../shared/Buttons/GeneralButton";
import LinkButton from "../shared/Buttons/LinkButton";
import classes from "./LoginPage.module.css";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../store";
import { Button, useMediaQuery } from "@mui/material";

function LoginPage() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await login(formData);
      // Store user data and tokens in localStorage
      localStorage.setItem("userData", JSON.stringify(data.user));
      localStorage.setItem("refreshToken", data.refresh);
      localStorage.setItem("accessToken", data.access);

      dispatch(userDataActions.updateUserData(JSON.stringify(data.user)));

      // Determine redirection path based on user type
      let redirectTo = "/";
      if (
        data.user.user_type === "nonprofit_member" ||
        data.user.user_type === "nonprofit_admin"
      ) {
        redirectTo = "/profile";
      } else {
        redirectTo = state?.back || "/";
      }

      navigate(redirectTo);
    } catch (error) {
      const errorMessage = errorHandler(error);
      setLoginError(errorMessage);
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const matches540px = useMediaQuery('(max-width: 540px)');

  return (
    <>
    {matches540px ? (
      <div className="flex justify-center items-center h-screen">
        <form className={classes.login_card} onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-orange mb-4">
            Login
          </h2>

          <label className="text-left text-gray-800 mb-2">Email:</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Email"
          />

          <label className="text-left text-gray-800 mb-2">Password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
          />

          <button
            className={`underline !bg-transparent !p-0 text-right mb-10`}
            to="/forgot-password"
          >
            Forgot Password?
          </button>

          {loginError && (
            <div className="text-red-500 mb-4 text-center">{loginError}</div>
          )}

          <div className="flex justify-center">
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "173px",
                height: "48px",
                padding: "12px 60px",
                borderRadius: "31px",
                fontFamily: "Inter",
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.2",
                color: "var(--default-white) !important",
                cursor: "pointer",
                gap: "23px",
                marginBottom: "2rem",
                textTransform: "none",
                backgroundColor: "var(--default-orange)",
                "&:hover": {
                  backgroundColor: "var(--default-orange)",
                },
                "&:active, &:focus": {
                  backgroundColor: "var(--default-orange)",
                  outline: "none",
                },
              }}
            >
              Login
            </Button>
          </div>
          
          <button 
            to="/signup" 
            className={`underline !bg-transparent !p-0`}
          >Don’t have an account? Sign Up</button>
        </form>
      </div>
    ) : (
      <div className="flex justify-center items-center h-screen gray-100">
        <form className={classes.login_card} onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-orange mb-4">
            Login to Your Account
          </h2>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Email"
          />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
          />
          {loginError && (
            <div className="text-red-500 mb-4 text-center">{loginError}</div>
          )}
          <GeneralButton
            colour="blue"
            type="submit"
            className="w-4/12 rounded-md !text-lg !p-1.5"
          >
            Login
          </GeneralButton>
          <LinkButton
            colour="blue"
            className={`underline !bg-transparent !text-blue !p-0`}
            to="/forgot-password"
          >
            Forgot Password?
          </LinkButton>
        </form>
      </div>
    )}
    </>
  );
}

export default LoginPage;
